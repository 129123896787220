import { render, staticRenderFns } from "./Faq.vue?vue&type=template&id=6ee2dbc6&scoped=true&"
import script from "./Faq.vue?vue&type=script&lang=js&"
export * from "./Faq.vue?vue&type=script&lang=js&"
import style0 from "./Faq.vue?vue&type=style&index=0&id=6ee2dbc6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee2dbc6",
  null
  
)

export default component.exports