<template>
	<div>
		<div>
			<div class="property-cards-wrap">
				<v-card
					v-for="(attraction, i) in booklet.attractions"
					v-bind:key="i"
					class="property-card"
					@click="EditAttraction(attraction)"
					style="min-height: 100px !important"
				>
					<v-list-item-title class="card-title">{{
						attraction.name
					}}</v-list-item-title>
					<div
						style="margin: 4px 0px; font-size: 14px; color: #a7a7a7"
					>
						{{ attraction.distance }} miles
					</div>
					<img
						v-for="(icon, j) in attraction.icons"
						v-bind:key="j"
						:src="
							require(`../../assets/icons/attractions/${icon}.png`)
						"
						class="attraction-icon"
					/>
					<div
						style="
							font-size: 14px;
							color: #a7a7a7;
							white-space: pre-wrap;
						"
					>
						{{ attraction.description }}
					</div>
				</v-card>
				<v-card
					class="property-card"
					style="
						background-color: #f4f4f4;
						min-height: 100px !important;
					"
					@click="NewAttraction()"
				>
					<div class="add-property-card">
						<v-icon color="gray">mdi-plus</v-icon> New Attraction
					</div>
				</v-card>
			</div>
		</div>

		<!-- Attraction Dialog -->
		<v-dialog
			v-model="attractionDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card class="dialog-card">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="attractionDialog = false"
				>
					X
				</div>
				<div class="purple-title2" style="margin-top: 0px">
					New Attraction
				</div>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						outlined
						color="#1D085A"
						label="Attraction Name"
						v-model="currentAttraction.name"
					></v-text-field>
					<v-text-field
						outlined
						color="#1D085A"
						placeholder="Include https://"
						label="Website URL"
						:error-messages="urlErrors"
						v-model="currentAttraction.link"
					></v-text-field>
					<v-text-field
						color="#1D085A"
						label="Distance"
						v-model="currentAttraction.distance"
						type="number"
						style="width: 150px; display: inline"
						suffix="miles"
					></v-text-field>
				</v-form>

				<p style="color: #1d085a">Icons Displayed</p>
				<div class="attraction-items-wrap">
					<div
						v-for="(icon, index) in icons"
						v-bind:key="index"
						class="attraction-items"
					>
						<v-checkbox
							color="#5E17EB"
							v-model="icon.selected"
							:label="icon.name"
						></v-checkbox>
					</div>
				</div>

				<v-textarea
					color="#1D085A"
					outlined
					label="Short Description"
					v-model="currentAttraction.description"
					style="margin-top: 32px"
				></v-textarea>
				<v-row align="center" style="padding: 0px 16px">
					<v-btn
						v-if="!editingAttraction"
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="AddAttraction()"
						><v-icon color="white" style="margin-right: 16px"
							>mdi-plus</v-icon
						>Add</v-btn
					>
					<v-btn
						v-else
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="DoneEditingAttraction()"
						>Done</v-btn
					>
					<v-btn
						light
						style="width: fit"
						@click="attractionDialog = false"
						>Cancel</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						v-if="editingAttraction"
						color="error"
						dark
						@click="DeleteAttraction()"
						>Delete</v-btn
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase } from "../../firebase";

export default {
	props: {
		booklet: Object,
		bookletId: String,
		welcomePrev: String,
		icons: Array,
	},
	data() {
		return {
			attractionDialog: false,
			currentAttraction: "",
			editingAttraction: false,
			currentAttractionIndex: "",
			valid: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		InitializeIcons() {
			this.$emit("InitializeIcons");
		},
		// ATTRACTION FUNCTIONS
		NewAttraction: function () {
			this.attractionDialog = true;
			this.editingAttraction = false;
			for (var i = 0; i < this.icons.length; i++) {
				this.icons[i].selected = false;
			}
			this.currentAttraction = {
				name: "",
				distance: "",
				icons: [],
				description: "",
			};
		},
		AddAttraction: function () {
			var iconsToAdd = [];
			if (this.isBadUrl(this.currentAttraction.link)) {
				console.log("bad url");
				return false;
			}
			for (var i = 0; i < this.icons.length; i++) {
				if (this.icons[i].selected) {
					iconsToAdd.push(this.icons[i].icon);
				}
			}
			this.currentAttraction.icons = iconsToAdd;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					attractions: firebase.firestore.FieldValue.arrayUnion(
						this.currentAttraction
					),
				})
				.then(() => {
					console.log("Document updated");
					this.attractionDialog = false;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		EditAttraction: function (attraction, index) {
			this.attractionDialog = true;
			this.editingAttraction = true;
			this.currentAttraction = attraction;
			this.currentAttractionIndex = index;
			this.InitializeIcons();
			// Filter Checkboxes
			for (var i = 0; i < this.currentAttraction.icons.length; i++) {
				for (var j = 0; j < this.icons.length; j++) {
					if (this.icons[j].icon == this.currentAttraction.icons[i]) {
						this.icons[j].selected = true;
					}
				}
			}
		},
		DoneEditingAttraction: function () {
			var iconsToAdd = [];
			if (this.isBadUrl(this.currentAttraction.link)) {
				console.log("bad url");
				return false;
			}
			for (var i = 0; i < this.icons.length; i++) {
				if (this.icons[i].selected) {
					iconsToAdd.push(this.icons[i].icon);
				}
			}
			this.currentAttraction.icons = iconsToAdd;
			this.booklet.attractions[this.currentAttractionIndex] =
				this.currentAttraction;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					attractions: this.booklet.attractions,
				})
				.then(() => {
					console.log("Document updated");
					this.attractionDialog = false;
					this.currentAttractionIndex = "";
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		DeleteAttraction: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					attractions: firebase.firestore.FieldValue.arrayRemove(
						this.currentAttraction
					),
				})
				.then(() => {
					console.log("Document deleted");
					this.attractionDialog = false;
					this.currentAttractionIndex = "";
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
	},
};
</script>

<style scoped>
.attraction-icon {
	padding: 6px 16px;
	padding-left: 0px;
}
.attraction-items-wrap {
	display: grid;
	grid-template-columns: 31% 31% 31%;
	row-gap: 8px;
	justify-content: space-between;
	margin-bottom: 32px;
}
.attraction-items {
	height: 32px;
}
@media screen and (max-width: 850px) {
	.attraction-items-wrap,
	.property-cards-wrap {
		grid-template-columns: 100%;
		padding: 0px;
		padding-left: 16px;
	}
}
</style>
