<template>
	<v-card class="property-card" @click="propertyClick(property)">
		<v-img :src="property.main_image" height="20vh" dark></v-img>
		<v-list two-line>
			<v-list-item style="min-height: 16px">
				<v-list-item-content
					style="padding-top: 0px; padding-bottom: 0px; margin: 4px 0"
				>
					<v-row style="padding: 0px; height: 24px; margin-top: 0px">
						<v-col style="padding: 0px 0px; height: auto">
							<v-list-item-title
								style="display: inline; line-height: 24px"
							>
								{{ property.name }}
							</v-list-item-title>
						</v-col>
						<v-col
							class="text-right"
							style="padding: 0px; height: auto"
						>
							<v-btn
								color="#1D085A"
								icon
								style="display: inline; margin-top: -6px"
							>
								<v-icon
									color="#5e17eb"
									size="22px"
									@click.stop="editClick(property)"
								>
									mdi-pencil
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<div style="margin-top: 6px">
						<p class="properties-sub">{{ property.address }}</p>
					</div>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
export default {
	props: {
		property: Object,
	},
	data() {
		return {};
	},
	methods: {
		editClick(property) {
			this.$emit("editClick", property);
		},
		propertyClick(property) {
			this.$emit("propertyClick", property);
		},
	},
};
</script>

<style>
.property-card {
	border-radius: 15px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
	cursor: pointer;
	min-height: 200px;
	background-color: white !important;
}
.property-card:hover {
	transform: scale(1.05);
}
.property-card-select {
	margin: 6px !important;
}
.properties-sub {
	font-size: 14px;
	color: rgb(76, 41, 151, 0.6);
	display: inline;
}
.v-list-item__title {
	font-family: "cabin_bold";
}
.v-list-item__content div {
	margin: 4px 0px;
}
</style>
