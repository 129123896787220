<template>
	<v-app>
		<v-dialog
			v-model="addPropertyDialog"
			scrollable
			fullscreen
			transition="dialog-bottom-transition"
			persistent
			style="overflow-x: auto"
		>
			<v-card class="dialog-card">
				<v-toolbar dark color="#5e17eb" style="flex: none">
					<v-btn icon dark @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Add New Property</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							:disabled="!valid"
							dark
							text
							@click="SaveProperty()"
							>Save</v-btn
						>
					</v-toolbar-items>
				</v-toolbar>
				<v-container>
					<v-form ref="form" v-model="valid" lazy-validation>
						<v-row>
							<!-- Property Name -->
							<v-col cols="12" sm="12">
								<v-text-field
									color="#5e17eb"
									label="Property Name*"
									:rules="nameRules"
									required
									:counter="20"
									placeholder="Ex) Vistas Del Sol Condo..."
									class="property-name-field"
									v-model="newPropertyName"
								></v-text-field>
							</v-col>
							<!-- Add new image -->
							<v-col cols="12" sm="12">
								<v-file-input
									color="#5e17eb"
									label="Upload New Property Image (Recommended)"
									prepend-inner-icon="mdi-image"
									:prepend-icon="null"
									class="property-name-field"
									outlined
									v-model="newMainImage"
									@change="AddedNewImage()"
								></v-file-input>
							</v-col>
							<!-- Display new image -->
							<v-col cols="12" sm="12" style="text-align: center">
								<img
									v-if="newImgUrl"
									:src="newImgUrl"
									style="height: 120px; width: auto"
								/>
							</v-col>
							<!-- Bedrooms -->
							<v-col cols="12" sm="6">
								<v-select
									:items="[
										'1',
										'2',
										'3',
										'4',
										'5',
										'6',
										'7+',
									]"
									color="#5e17eb"
									label="Number of bedrooms*"
									required
									v-model="newBeds"
								></v-select>
							</v-col>
							<!-- Bathrooms -->
							<v-col cols="12" sm="6">
								<v-select
									:items="[
										'1',
										'2',
										'3',
										'4',
										'5',
										'6',
										'7+',
									]"
									color="#5e17eb"
									label="Number of bathrooms*"
									required
									v-model="newBaths"
								></v-select>
							</v-col>
							<!-- Address Line 1 -->
							<v-col cols="12" sm="12">
								<v-text-field
									color="#5e17eb"
									label="Address Line 1*"
									required
									:rules="[
										(v) => !!v || 'Address is required',
									]"
									placeholder="Ex) 555 E Santa Monica Dr"
									class="property-name-field"
									v-model="newAddress"
								></v-text-field>
							</v-col>
							<!-- City -->
							<v-col cols="12" sm="4">
								<v-text-field
									color="#5e17eb"
									label="City*"
									required
									:rules="[(v) => !!v || 'City is required']"
									placeholder="Ex) St. George"
									class="property-name-field"
									v-model="newCity"
								></v-text-field>
							</v-col>
							<!-- State -->
							<v-col cols="12" sm="4">
								<v-select
									color="#5e17eb"
									label="State*"
									:items="states"
									required
									:rules="[(v) => !!v || 'State is required']"
									placeholder="Ex) UT"
									class="property-name-field"
									v-model="newState"
								></v-select>
							</v-col>
							<!-- Zip -->
							<v-col cols="12" sm="4">
								<v-text-field
									color="#5e17eb"
									label="Postal/Zip Code*"
									:rules="[
										(v) =>
											!!v ||
											'Postal/Zip Code is required',
									]"
									required
									placeholder="Ex) 84770"
									class="property-name-field"
									v-model="newZip"
								></v-text-field>
							</v-col>
							<!-- Cleaners -->
							<!-- <v-col cols="12" sm="12" style="padding: 0px !important; padding-top: 0px !important">
            <v-container>
              <v-text-field v-model="newVendor" label="Default Cleaner Email (optional)"></v-text-field>
            </v-container>
          </v-col> -->
						</v-row>
					</v-form>
				</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer />
					<v-btn color="#5e17eb" text @click="closeDialog"
						>Close</v-btn
					>
					<v-btn
						color="#5e17eb"
						text
						@click="SaveProperty()"
						:disabled="!valid"
						>Save</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import { storage } from "../firebase";
import { mapActions } from "vuex";
let uuid = require("uuid");
export default {
	props: {
		addPropertyDialog: Boolean,
	},
	data() {
		return {
			newPropertyName: "",
			newMainImage: null,
			newImgUrl: "",
			newBeds: 0,
			newBaths: 0,
			newAddress: "",
			newCity: "",
			newState: "",
			newZip: "",
			// newVendor: "",

			valid: false,
			nameRules: [
				(v) => !!v || "Property Name is required",
				(v) =>
					(v && v.length <= 20) ||
					"Name must be less than 20 characters",
			],
			states: [
				"Alabama",
				"Alaska",
				"American Samoa",
				"Arizona",
				"Arkansas",
				"California",
				"Colorado",
				"Connecticut",
				"Delaware",
				"District of Columbia",
				"Federated States of Micronesia",
				"Florida",
				"Georgia",
				"Guam",
				"Hawaii",
				"Idaho",
				"Illinois",
				"Indiana",
				"Iowa",
				"Kansas",
				"Kentucky",
				"Louisiana",
				"Maine",
				"Marshall Islands",
				"Maryland",
				"Massachusetts",
				"Michigan",
				"Minnesota",
				"Mississippi",
				"Missouri",
				"Montana",
				"Nebraska",
				"Nevada",
				"New Hampshire",
				"New Jersey",
				"New Mexico",
				"New York",
				"North Carolina",
				"North Dakota",
				"Northern Mariana Islands",
				"Ohio",
				"Oklahoma",
				"Oregon",
				"Palau",
				"Pennsylvania",
				"Puerto Rico",
				"Rhode Island",
				"South Carolina",
				"South Dakota",
				"Tennessee",
				"Texas",
				"Utah",
				"Vermont",
				"Virgin Island",
				"Virginia",
				"Washington",
				"West Virginia",
				"Wisconsin",
				"Wyoming",
			],
		};
	},
	methods: {
		...mapActions("adminUser", ["createNewProperty"]),
		closeDialog() {
			this.$emit("addpropertydialogclosed");
		},
		StoreImage: async function () {
			var imageName = uuid.v1();
			var metadata = {
				contentType: "image/png",
			};
			var storageRef = storage.ref();
			var imageRef = storageRef.child(`dormi-public/${imageName}.png`);
			await imageRef.put(this.newMainImage, metadata);
			var downloadURL = await imageRef.getDownloadURL();
			return downloadURL;
		},
		validate() {
			this.valid = this.$refs.form.validate();
			console.log(this.valid);
		},
		SaveProperty: async function () {
			await this.validate();
			if (!this.valid) {
				return;
			}
			var url = await this.StoreImage();
			let propertyInfo = {
				name: this.newPropertyName,
				address: this.newAddress,
				city: this.newCity,
				state: this.newState,
				zip: this.newZip,
				baths: this.newBaths,
				beds: this.newBeds,
				main_image: url,
			};
			if (propertyInfo.main_image === "") {
				propertyInfo.main_image =
					"https://firebasestorage.googleapis.com/v0/b/dormi-web-app.appspot.com/o/dormi-images%2FdefaultImage.png?alt=media&token=058757db-7bf7-4db7-a67f-013d44eff373";
			}
			console.log("New property:", propertyInfo);
			await this.createNewProperty(propertyInfo);
			this.closeDialog();
		},
		AddedNewImage: function () {
			console.log(this.newMainImage);
			this.newImgUrl = URL.createObjectURL(this.newMainImage);
		},
	},
};
</script>

<style scoped>
.image {
	max-width: 150px;
	margin: 15px;
	cursor: pointer;
}
.selectedImage {
	border: 3px solid #1d085a;
}
#customdropzone {
	width: 80%;
	border: solid 2px #1d085a;
	border-style: dashed;
	position: relative;
	margin: 0 auto;
}
.delete-image-icon:hover {
	transform: scale(1.2);
}
.delete-image-icon {
	width: 12px;
	height: auto;
	cursor: pointer;
}
.dialog-card {
	height: fit-content;
	overflow-x: hidden;
}
.property-name-field {
	width: 100%;
}
.property-name-field:focus {
	outline: none !important;
	border: 1px solid #1d085a;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.image-div {
	display: flex;
	margin: 25px;
	overflow-x: scroll;
	position: relative;
}
</style>
