<template>
	<v-app>
		<div class="app-div">
			<!-- <Header></Header> -->
			<div class="login-wrapper">
				<h2>Dormi</h2>
				<div class="login-box" v-if="loginType == ''">
					<h3>Create a new account</h3>
					<div class="loginTypeWrap">
						<div class="login-card" @click="loginType = 'owner'">
							<img
								class="login-logo"
								src="../assets/icons/Dormi-crop.png"
							/>
							<p class="login-txt">Sign up as Admin</p>
						</div>
						<div class="login-card" @click="loginType = 'vendor'">
							<img
								class="login-logo"
								src="../assets/icons/cleaning_icon.svg"
							/>
							<p class="login-txt">Sign up as Cleaner</p>
						</div>
					</div>
					<p class="login-box-text center-text">
						Already a member?<router-link to="/login">
							Login Here</router-link
						>
					</p>
				</div>
				<!-- Owner Sign up -->
				<div class="login-box" v-else>
					<h3>Sign up as {{ loginType }}</h3>

					<v-form ref="form" v-model="valid" lazy-validation>
						<v-text-field
							id="signup-full-name"
							ref="signup-full-name"
							@keydown.enter="FormKeyDown"
							v-if="loginType == 'owner'"
							color="#11023e"
							:rules="nameRules"
							outlined
							v-model="fullname"
							type="name"
							label="Full Name"
						></v-text-field>
						<v-text-field
							id="signup-company-name"
							ref="signup-company-name"
							@keydown.enter="FormKeyDown"
							v-else
							color="#11023e"
							:rules="nameRules"
							outlined
							v-model="fullname"
							type="name"
							label="Full Name / Company"
						></v-text-field>
						<v-text-field
							id="signup-email"
							ref="signup-email"
							@keydown.enter="FormKeyDown"
							color="#11023e"
							:rules="emailRules"
							outlined
							v-model="email"
							type="email"
							label="Email"
						></v-text-field>
						<v-text-field
							id="signup-password"
							ref="signup-password"
							@keydown.enter="FormKeyDown"
							color="#11023e"
							:rules="passwordRules"
							outlined
							type="password"
							min="6"
							v-model="password"
							label="Password"
						></v-text-field>
						<v-text-field
							id="signup-password-confirm"
							ref="signup-password-confirm"
							@keydown.enter="FormKeyDown"
							color="#11023e"
							:rules="passwordConfRules"
							outlined
							type="password"
							min="6"
							v-model="passwordConfirmation"
							label="Password Confirmation"
						></v-text-field>
						<!-- <p class="login-box-text center-text">Forgot <a>password?</a></p> -->
						<v-btn
							@click="GeneralSignup"
							color="#5e17eb"
							dark
							width="100%"
							>Continue</v-btn
						>
					</v-form>

					<ul v-for="(error, index) in formErrors" v-bind:key="index">
						<li style="color: red; list-style-type: disc">
							{{ error }}
						</li>
					</ul>

					<div style="display: flex; margin-top: 32px">
						<v-divider style="margin-top: 10px" />
						<p
							style="
								width: 48px;
								text-align: center;
								margin-bottom: 0px;
							"
						>
							OR
						</p>
						<v-divider style="margin-top: 10px" />
					</div>
					<div style="text-align: center; height: 48px">
						<img
							class="login-btn google-btn"
							src="../assets/icons/googleLogin.png"
							@click="DormiGoogleSignup()"
						/>
					</div>
					<p class="login-box-text center-text">
						Already a member?<router-link to="/login">
							Login Here</router-link
						>
					</p>
				</div>
			</div>
		</div>
	</v-app>
</template>

<script>
// import { mapState } from 'vuex'
import { auth } from "../firebase";
import { provider } from "../firebase";
// import { db } from "../firebase";
import { mapActions } from "vuex";

export default {
	name: "Register",
	metaInfo: {
		title: "Register",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Login with Dormi. Get ready to experience simplicity at its finest in your vacation rental management.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		// Header
	},
	data() {
		return {
			loginType: "",

			fullname: "",
			email: "",
			password: "",
			passwordConfirmation: "",
			formErrors: [],
			valid: true,

			nameRules: [
				(v) => !!v || "Full Name is required",
				(v) =>
					(v && v.length <= 64) ||
					"Full Name must be less than 64 characters",
			],
			emailRules: [
				(v) => !!v || "E-mail is required",
				(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
			],
			passwordRules: [(v) => !!v || "Password is required", this.min6],
			passwordConfRules: [
				(v) => !!v || "Password Confirmation is required",
				this.min6,
				this.matchingPasswords,
			],

			firstNameError: false,
			lastNameError: false,
			emailError: false,
			userError: false,
		};
	},
	methods: {
		...mapActions("adminUser", ["newUser"]),
		...mapActions("vendorUser", ["newVendorUser"]),
		min6: function (value) {
			if (value.length >= 6) {
				return true;
			} else {
				return "Password should have more than 6 characters.";
			}
		},
		matchingPasswords: function () {
			if (this.password === this.passwordConfirmation) {
				return true;
			} else {
				return "Passwords do not match.";
			}
		},
		ContinueToPricing: function () {
			this.formErrors = [];
			if (this.email == "") {
				this.formErrors.push("Must provide a valid email");
				return;
			}
			if (this.password == "") {
				this.formErrors.push("Must provide a valid password");
				return;
			}
			if (this.fullname == "") {
				this.formErrors.push("Must provide your full name.");
				return;
			}
			this.loginType = "pricing";
		},
		DormiGoogleSignup() {
			this.formErrors = [];
			auth.signInWithPopup(provider)
				.then((result) => {
					/** @type {firebase.auth.OAuthCredential} */
					// var credential = result.credential;
					var user = result.user;
					var isNewUser = result.additionalUserInfo.isNewUser;
					if (isNewUser) {
						//delete the created user
						this.userError = true;
						console.log("User has not been created yet.");

						if (this.loginType == "owner") {
							let payload = {
								uid: user.uid,
								name: user.displayName,
								email: user.email,
							};
							this.newUser(payload);
						} else {
							let payload = {
								uid: user.uid,
								name: user.displayName,
								email: user.email,
							};
							this.newVendorUser(payload);
						}
					} else {
						// your sign in flow
						console.log("user", user, user.uid);
						console.log("user " + user.email + " does exist!");
						alert(
							"The login information for this user already exists. Please login."
						);
						this.$router.push({ name: "Login" });
					}
				})
				.catch((error) => {
					console.log(error.code, error.message);
				});
		},
		FormKeyDown(e) {
			if (e.target.id === "signup-full-name") {
				this.$refs["signup-email"].focus();
			} else if (e.target.id === "signup-company-name") {
				this.$refs["signup-email"].focus();
			} else if (e.target.id === "signup-email") {
				this.$refs["signup-password"].focus();
			} else if (e.target.id === "signup-password") {
				this.$refs["signup-password-confirm"].focus();
			} else if (e.target.id === "signup-password-confirm") {
				if (this.valid) {
					this.GeneralLogin();
				}
			}
		},
		GeneralSignup() {
			if (this.loginType === "owner") {
				this.DormiSignup();
			} else {
				this.VendorSignup();
			}
		},
		DormiSignup() {
			this.formErrors = [];
			if (this.email == "") {
				this.formErrors.push("Must provide a valid email");
				return;
			}
			if (this.password == "") {
				this.formErrors.push("Must provide a valid password");
				return;
			}
			if (this.fullname == "") {
				this.formErrors.push("Must provide your full name.");
				return;
			}
			auth.createUserWithEmailAndPassword(this.email, this.password)
				.then((userRecord) => {
					var user = userRecord.user;
					var payload = {
						uid: user.uid,
						name: this.fullname,
						email: this.email,
					};
					console.log("sending payload", payload);
					this.newUser(payload);
				})
				.catch((error) => {
					this.formErrors = [];
					var errorCode = error.code;
					var errorMessage = error.message;
					console.log("Error on sign up:", errorCode, errorMessage);
					this.formErrors.push(errorMessage);
					// ..
				});
		},
		VendorSignup() {
			this.formErrors = [];
			if (this.email == "") {
				this.formErrors.push("Must provide a valid email");
				return;
			}
			if (this.password == "") {
				this.formErrors.push("Must provide a valid password");
				return;
			}
			if (this.fullname == "") {
				this.formErrors.push("Must provide your full name.");
				return;
			}
			auth.createUserWithEmailAndPassword(this.email, this.password)
				.then((userCredential) => {
					var user = userCredential.user;
					var payload = {
						uid: user.uid,
						name: this.fullname,
						email: this.email,
					};
					this.newVendorUser(payload);
				})
				.catch((error) => {
					var errorCode = error.code;
					var errorMessage = error.message;
					console.log("Error on sign up:", errorCode, errorMessage);
					this.formErrors.push(errorMessage);
					// ..
				});
		},
	},
};
</script>

<style scoped>
.app-div {
	min-height: 100vh;
	padding: 0px 16px;
}
.visible {
	visibility: visible;
}
.invisible {
	visibility: hidden;
}
.red {
	color: rgb(243, 115, 115) !important;
	margin-top: 2px !important;
}

::-webkit-scrollbar {
	display: none;
}
.login-background {
	background-color: whitesmoke;
	height: 100%;
	width: 100%;
	position: relative;
}
.login-container {
	max-width: 460px;
	height: auto;
	max-height: 660px;
	padding: 0px 60px;
	position: absolute;
	top: 100px;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: auto;
}
.login-wrapper {
	max-width: 500px;
	margin: 0 auto;
	margin-bottom: 32px;
}
.dormi-logo {
	width: 160px;
	height: auto;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
}
.small-text-login {
	font-family: "cabin_regular";
	font-size: 14px;
	margin: 4px 0px;
}
h2,
h3 {
	text-align: left;
	font-size: 24px;
	margin: 32px 0px;
	font-family: "cabin_bold";
	color: #11023e;
}
h3 {
	font-family: "cabin_regular";
	margin: 16px 0px 32px 0px;
}
.login-card {
	width: 40%;
	height: auto;
	background-color: #5e17eb21;
	border: 1px solid #11023e67;
	border-radius: 4px;
	text-align: center;
	padding: 16px;
}
.login-card:hover {
	background-color: #5e17eb4d;
	transform: scale(1.05);
	cursor: pointer;
}
.login-btn {
	height: 48px;
	margin-top: 16px;
}
.dormi-login {
	width: 254px;
	background-color: #f9f9f9;
	padding: 8px;
	padding-top: 4px !important;
	margin-top: 16px;
	margin-bottom: 8px;
}
.login-btn:hover {
	cursor: pointer;
	transform: scale(1.02);
}
.login-logo {
	height: 32px;
	vertical-align: middle;
	width: auto;
	padding-left: 12px;
	padding-right: 16px;
}
.login-txt {
	display: block;
	font-size: 16px;
	color: #11023e;
	font-family: "cabin_bold";
	margin-top: 16px;
	margin-bottom: 8px;
}
.login-box {
	padding: 20px 40px;
	background-color: white;
	border-radius: 4px;
	text-align: left;
	position: relative;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	max-width: 600px;
}
.log-input {
	color: black;
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	background: white;
}
.login-box-text {
	font-size: 16px;
	color: #11023e;
	margin-top: 32px;
}
.login-box-label {
	font-size: 16px;
	color: #11023e;
	margin-top: 16px;
	margin-bottom: 0px;
}
.center-text {
	text-align: center;
}
.login-box-text a {
	color: #5e17eb;
	cursor: pointer;
	font-size: 16px;
	text-decoration: none;
}
.login-box-text a:hover {
	color: #380e8d;
}
.back-home {
	width: 160px;
	background-color: #f9f9f9;
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 8px;
	text-align: center;
	line-height: 32px;
	vertical-align: middle;
	cursor: pointer;
	transition: background-color 0.2s;
	border-radius: 2px;
}
.back-home:hover {
	background-color: rgba(160, 160, 160, 0.603);
}
.arrow {
	width: 24px;
	height: auto;
	transform: rotate(90deg);
	filter: invert(20%) sepia(99%) saturate(2056%) hue-rotate(241deg)
		brightness(82%) contrast(91%);
	vertical-align: middle;
	margin-left: -24px;
	margin-right: 16px;
	margin-bottom: 3px;
}
.continue-btn {
	color: #1d085a !important;
	margin: 0 auto;
}
.loginTypeWrap {
	display: flex;
	justify-content: space-around;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
	background-color: rgb(206, 206, 206) !important;
}
@media screen and (max-width: 850px) {
	.dormi-logo {
		width: 120px;
	}
	.login-box {
		padding: 16px;
	}
}
@media screen and (max-width: 400px) {
	.loginTypeWrap {
		display: block;
	}
	.login-card {
		width: 100%;
		margin-bottom: 16px;
	}
}
</style>
