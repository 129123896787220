import { db } from "@/firebase";
// import { firestoreAction } from 'vuexfire';
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import router from "@/router";

function initialState() {
	return {
		properties: [],
		assignedPropertyEvents: [],
		selectedVendorProperty: "",
		vendorId: "",
		user: null,
		error: null,
	};
}

const vendorUser = {
	namespaced: true,
	state: initialState,

	getters: {
		getVendorUser(state) {
			return state.vendorId;
		},
		getError(state) {
			return state.error;
		},
	},

	mutations: {
		initializeStore(state) {
			const vendorId = localStorage.getItem("vendorId");
			if (vendorId) {
				state.vendorId = vendorId;
			}
			const storedCurrentProperty = localStorage.getItem(
				"storedCurrentProperty"
			);
			if (storedCurrentProperty) {
				state.storedCurrentProperty = storedCurrentProperty;
			}
		},
		setVendorUser(state, payload) {
			localStorage.setItem("vendorId", payload.uid);
			state.vendorId = payload.uid;
		},
		logoutVendorUser(state) {
			state.adminId = null;
			localStorage.setItem("vendorId", null);
			localStorage.setItem("selectedVendorProperty", null);
		},
		setCurrentProperty(state, property) {
			localStorage.setItem("selectedVendorProperty", property.name);
			state.selectedVendorProperty = property.name;
		},
		setError(state, payload) {
			state.error = payload;
		},
	},

	actions: {
		newVendorUser({ commit }, payload) {
			var usersRef = db.collection("users_vendor").doc(payload.uid);
			usersRef.get().then((docSnapshot) => {
				if (docSnapshot.exists) {
					usersRef.onSnapshot((doc) => {
						console.log("doc exists", doc);
					});
				} else {
					usersRef.set({
						email: payload.email,
						name: payload.name,
					}); // create the document
				}
				commit("setVendorUser", payload.uid);
				router.push({ name: "Vendor_Properties" });
			});
		},
		loginVendorUser({ commit }, payload) {
			firebase
				.auth()
				.signInWithEmailAndPassword(payload.email, payload.password)
				.then((response) => {
					commit("setVendorUser", response.user);
					router.push({ name: "Vendor_Properties" });
				})
				.catch((error) => {
					commit("setError", error.message);
					router.push({ name: "Login" });
				});
		},
		loginGoogleVendorUser({ commit }, payload) {
			commit("setVendorUser", payload);
		},
		async savePropertySort(context, properties) {
			return db
				.collection("users_vendor")
				.doc(firebase.auth().currentUser.uid)
				.update({
					properties: properties,
				});
		},
	},
};

export default vendorUser;
