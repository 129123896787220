<template>
	<v-app>
		<div class="center app-div">
			<Header></Header>
			<PropertyNav></PropertyNav>

			<div class="main-container">
				<!-- bulk add button -->
				<bulk-add
					v-if="booklet"
					:booklet="booklet"
					:properties="properties"
					:icons="icons"
					@initializeIcons="InitializeIcons"
					@isBadUrl="isBadUrl"
					@findProperty="findProperty"
				/>

				<!-- Create booklet button -->
				<div v-if="!booklet" style="text-align: center">
					<v-btn style="margin-top: 32px" @click="CreateBooklet()"
						><v-icon style="padding-right: 6px">mdi-plus</v-icon>
						Create Booklet</v-btn
					>
				</div>

				<div
					v-else
					style="display: flex; margin-top: 32px; width: 100%"
				>
					<!-- Sidenav -->
					<sidenav
						:sidenav="activeSidenav"
						@SideNavClicked="SideNavClicked"
					/>
					<v-divider vertical></v-divider>

					<!-- Guide -->
					<guide
						v-if="activeSidenav == 'guide'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
					/>

					<!-- Amenities -->
					<amenities
						v-if="activeSidenav == 'amenities'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
					/>

					<!-- Attractions -->
					<attractions
						v-if="activeSidenav == 'attractions'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						:icons="icons"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
						@InitializeIcons="InitializeIcons"
					/>

					<!-- Excursions -->
					<excursions
						v-if="activeSidenav == 'excursions'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
					/>
					<!-- History -->
					<history
						v-if="activeSidenav == 'history'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
					/>
					<!-- Restaurants -->
					<restaurants
						v-if="activeSidenav == 'restaurants'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
					/>

					<!-- FAQs -->
					<faq
						v-if="activeSidenav == 'faqs'"
						style="flex: 1"
						:booklet="booklet"
						:bookletId="bookletId"
						@findProperty="findProperty"
						@isBadUrl="isBadUrl"
					/>
					<!-- QR CODE -->
					<qr-code
						v-if="activeSidenav == 'qr'"
						style="flex: 1; text-align: center"
						:booklet="booklet"
						:bookletId="bookletId"
						:currentPropertyId="currentPropertyId"
						@findProperty="findProperty"
					/>

					<!-- Contact -->
				</div>
			</div>
		</div>
	</v-app>
</template>

<script>
import Header from "../../layout/Admin/Header.vue";
import PropertyNav from "../../layout/Admin/PropertyNav.vue";
import { db } from "../../firebase";
import { mapGetters, mapActions } from "vuex";
import BulkAdd from "../../components/booklet/BulkAdd.vue";
import Sidenav from "../../components/booklet/Sidenav.vue";
import Guide from "../../components/booklet/Guide.vue";
import Amenities from "../../components/booklet/Amenities.vue";
import Attractions from "../../components/booklet/Attractions.vue";
import Excursions from "../../components/booklet/Excursions.vue";
import History from "../../components/booklet/History.vue";
import Restaurants from "../../components/booklet/Restaurants.vue";
import Faq from "../../components/booklet/Faq";
import QrCode from "../../components/booklet/QrCode.vue";

// import { db } from '../../firebase'
// import firebase from 'firebase/app'

export default {
	name: "Booklet",
	metaInfo: {
		title: "Booklet",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Create your own digital guest booklet to create the best guest experience for your vacation rentals. Tell your guest about the best experiences, restaurants, and sites next to your property.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
		PropertyNav,
		BulkAdd,
		Sidenav,
		Guide,
		Amenities,
		Attractions,
		Excursions,
		History,
		Restaurants,
		Faq,
		QrCode,
	},
	data: () => ({
		currentPropertyId: "",
		currentProperty: "",
		adminId: "",
		booklet: "",
		bookletId: "",
		showBulkAdd: false,
		activeSidenav: "guide",
		originalBooklet: "",
		valid: false,
		urlErrors: [],
		qrCodeUrl: "",
	}),
	computed: {
		properties() {
			return this.getProperties();
		},
	},
	created() {
		this.adminId = this.$store.state.adminId;
		this.bindProperties();
		this.activeSidenav = this.GetSideNav();
		this.findProperty();
		this.InitializeIcons();
	},
	methods: {
		...mapGetters("adminUser", ["getProperties"]),
		...mapActions("adminUser", ["bindProperties"]),

		findProperty: function () {
			this.currentPropertyId = this.$route.params.property_id;
			db.collection("booklets")
				.where("property_id", "==", this.currentPropertyId)
				.get()
				.then((query) => {
					if (query.empty) {
						console.log("Booklet doesn't exist yet");
						return;
					}
					const bookletRef = query.docs[0];
					this.bookletId = bookletRef.id;
					this.booklet = bookletRef.data();

					// Check if all booklet sections have been created. If not, create them.
					var updateNeeded = false;
					if (!("qrcode" in this.booklet)) {
						this.booklet.qrcode = "";
						updateNeeded = true;
					}
					if (!("amenities" in this.booklet)) {
						this.booklet.amenities = [];
						updateNeeded = true;
					}
					if (!("attractions" in this.booklet)) {
						this.booklet.attractions = [];
						updateNeeded = true;
					}
					if (!("excursions" in this.booklet)) {
						this.booklet.excursions = [];
						updateNeeded = true;
					}
					if (!("sites" in this.booklet)) {
						this.booklet.sites = [];
						updateNeeded = true;
					}
					if (!("faqs" in this.booklet)) {
						this.booklet.faqs = [];
						updateNeeded = true;
					}
					if (!("guide" in this.booklet)) {
						this.booklet.guide = [];
						updateNeeded = true;
					}
					if (!("welcome" in this.booklet)) {
						this.booklet.welcome = "";
						updateNeeded = true;
					}
					if (!("restaurants" in this.booklet)) {
						this.booklet.restaurants = [];
						updateNeeded = true;
					}
					if (!("contactActive" in this.booklet)) {
						this.booklet.contactActive = false;
						updateNeeded = true;
					}
					if (!("contactPhone" in this.booklet)) {
						this.booklet.contactPhone = "";
						updateNeeded = true;
					}
					if (!("contactEmail" in this.booklet)) {
						this.booklet.contactEmail = "";
						updateNeeded = true;
					}
					if (updateNeeded) {
						bookletRef.ref.update(this.booklet, { merge: true });
					}
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});
		},
		GetSideNav() {
			let parsed;
			try {
				parsed = JSON.parse(localStorage.getItem("property-sidenav"));
			} catch (e) {
				parsed = "amenities";
				localStorage.setItem(
					"property-sidenav",
					JSON.stringify(parsed)
				);
			}
			return parsed;
		},
		SideNavClicked: function (nav) {
			this.activeSidenav = nav;
			localStorage.setItem("property-sidenav", JSON.stringify(nav));
		},
		CreateBooklet: function () {
			var docRef = db
				.collection("properties")
				.doc(this.currentPropertyId);
			docRef
				.get()
				.then((doc) => {
					if (doc.exists) {
						var name = doc.data().name;
						db.collection("booklets")
							.add({
								property_id: this.currentPropertyId,
								property_name: name,
								qrcode: "",
								welcome: "",
								amenities: [],
								attractions: [],
								excursions: [],
								sites: [],
								faqs: [],
								guide: [],
								restaurants: [],
							})
							.then(() => {
								console.log("Document updated");
								this.findProperty();
							})
							.catch((error) => {
								console.error("Error adding document: ", error);
							});
					} else {
						console.log("Property doesn't exist");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		InitializeIcons: function () {
			this.icons = [
				{ name: "Walking Distance", icon: "walk", selected: false },
				{ name: "Car Distance", icon: "car", selected: false },
				{ name: "Biking Distance", icon: "bike", selected: false },
				{ name: "Bus Distance", icon: "bus", selected: false },
				{ name: "Wifi", icon: "wifi", selected: false },
				{ name: "Expensive", icon: "expensive", selected: false },
				{ name: "Moderate Pricing", icon: "moderate", selected: false },
				{ name: "Affordable", icon: "affordable", selected: false },
				{ name: "Recreation", icon: "recreation", selected: false },
				{ name: "Indoors", icon: "indoors", selected: false },
				{ name: "Family Friendly", icon: "family", selected: false },
				{ name: "Pet Friendly", icon: "pet", selected: false },
			];
		},
		isBadUrl: function (str) {
			if (str == "" || str == undefined) {
				return true;
			}
			let url;
			this.urlErrors = [];
			try {
				url = new URL(str);
			} catch (_) {
				this.urlErrors.push(
					"Invalid url. Make sure it begins with 'https://'"
				);
				return false;
			}

			return url.protocol === "http:" || url.protocol === "https:";
		},
		setQrUrl(url) {
			this.qrCodeUrl = url;
		},
	},
};
</script>

<style>
.property-cards-wrap {
	display: grid;
	grid-template-columns: 48% 48%;
	row-gap: 24px;
	justify-content: space-between;
	padding-left: 32px;
}
.add-property-card {
	color: gray;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.add-amenity-card {
	height: 80px;
}
.property-card {
	border-radius: 15px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
	cursor: pointer;
	min-height: 200px;
	padding: 16px;
}
.v-list-item__title {
	color: #1d085a;
}
.amenity-card {
	border-radius: 15px !important;
	box-shadow: none !important;
	min-height: 80px;
	padding: 0px;
	background-color: transparent !important;
	display: flex;
	width: 100%;
}
.amenity-edit-icon {
	float: right;
	margin-right: 8px;
	border-radius: 50px;
}
.property-card:hover,
.add-amenity-card:hover {
	transform: scale(1.05);
}
.property-card-select {
	margin: 6px !important;
}
.dialog-card {
	overflow-x: auto;
	padding: 32px;
	position: relative;
}
.card-image {
	border-radius: 6px !important;
}
.purple-title2 {
	color: #1d085a;
	font-size: 18px;
	margin-bottom: 16px;
}
@media screen and (max-width: 850px) {
	.attraction-items-wrap,
	.property-cards-wrap {
		grid-template-columns: 100%;
		padding: 0px;
		padding-left: 16px;
	}
	.faq-bar {
		margin-left: 0px;
	}
	.welcome-note {
		padding-left: 16px !important;
	}
	.main-container {
		padding: 0px 16px;
	}
}
</style>

<style scoped>
.center.app-div {
	min-height: 100vh;
}
.v-application--wrap {
	display: block !important;
	min-height: 100vh !important;
}
.page-title {
	color: #1d085a;
	font-size: 32px;
	font-weight: 500;
}
.card-title {
	color: #1d085a;
}
.booklets-container {
	margin-top: 52px;
	display: flex;
}
.booklet-box {
	max-height: 200px;
	max-width: 300px;
	padding: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.booklet-box:hover {
	background-color: #5e17eb10;
	cursor: pointer;
}
.booklet-title {
	color: #1d085a;
	font-size: 24px;
	padding-bottom: 12px;
}
.booklet-intro {
	color: #a7a7a7;
}
.template-container {
	text-align: center;
}
.v-card--link:focus:before {
	opacity: 0;
}
.property-wrapper {
	width: 100%;
	height: 180px;
	background: rgb(196, 196, 196, 0.15);
	position: relative;
	border-bottom: 0.5px solid gray;
	overflow-x: scroll;
	overflow-y: hidden;
	cursor: pointer;
}
.property-cards-wrap {
	display: grid;
	grid-template-columns: 48% 48%;
	row-gap: 24px;
	justify-content: space-between;
	padding-left: 32px;
}
.add-property-card {
	color: gray;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.add-amenity-card {
	height: 80px;
}
.property-card {
	border-radius: 15px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
	cursor: pointer;
	min-height: 200px;
	padding: 16px;
}
.amenity-card {
	border-radius: 15px !important;
	box-shadow: none !important;
	min-height: 80px;
	padding: 0px;
	background-color: transparent !important;
	display: flex;
	width: 100%;
}
.amenity-edit-icon {
	float: right;
	margin-right: 8px;
	border-radius: 50px;
}
.property-card:hover,
.add-amenity-card:hover {
	transform: scale(1.05);
}
.property-card-select {
	margin: 6px !important;
}
.dialog-card {
	overflow-x: auto;
	padding: 32px;
	position: relative;
}
.card-image {
	border-radius: 6px !important;
}
@media screen and (max-width: 850px) {
	.attraction-items-wrap,
	.property-cards-wrap {
		grid-template-columns: 100%;
		padding: 0px;
		padding-left: 16px;
	}
	.faq-bar {
		margin-left: 0px;
	}
	.welcome-note {
		padding-left: 16px !important;
	}
	.main-container {
		padding: 0px 16px;
	}
}
</style>
