<template>
	<div>
		<Header />
		<center>
			<h1>404 not found</h1>
			<h2>Oops... It looks like this page does not exist.</h2>
		</center>
	</div>
</template>

<script>
import Header from "../layout/Admin/Header.vue";
export default {
	name: "Error",
	components: {
		Header,
	},
};
</script>

<style scoped>
h1 {
	color: #1d085a;
	font-size: 32px;
	font-weight: bold;
}
h2 {
	margin-top: 16px;
}
center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
