<template>
	<v-app>
		<div class="app-div">
			<Header></Header>
			<div class="main-container">
				<h3 class="purple-title">
					My Properties
					<div style="float: right; padding-top: 15px">
						<v-btn
							icon
							title="Grid View"
							@click.stop="ToggleViewLayout('card')"
						>
							<v-icon>
								mdi-view-module{{
									viewLayout === "card" ? "" : "-outline"
								}}
							</v-icon>
						</v-btn>
						<v-btn
							icon
							title="List View"
							@click.stop="ToggleViewLayout('list')"
						>
							<v-icon>
								mdi-view-list{{
									viewLayout === "list" ? "" : "-outline"
								}}
							</v-icon>
						</v-btn>
					</div>
				</h3>
				<!-- <v-btn class="cleanerBtn" text color="#1D085A" @click="cleanersDialog = !cleanersDialog">My Cleaners</v-btn> -->
				<!-- <div class="mobileCleanerBtn"><v-icon color="#1D085A" @click="cleanersDialog = !cleanersDialog">mdi-broom</v-icon></div> -->
				<v-divider
					color="#1D085A"
					style="margin-bottom: 32px"
				></v-divider>
				<div v-if="viewLayout === 'list'">
					<draggable
						v-model="propertiesSorted"
						group="properties"
						class="property-list-wrap"
						draggable=".list-drag"
						drag-class="dragged"
						v-bind="getSortOptions()"
						:disabled="dragDisabled"
						@start="dragStart"
						@end="dragEnd"
					>
						<property-view-list
							class="list-drag"
							v-for="(property, i) in propertiesSorted"
							v-bind:property="property"
							v-bind:key="i"
							@propertyClick="PropertySelected(property)"
							@editClick="editPropertyClicked"
						></property-view-list>
					</draggable>

					<br />
					<br />

					<v-btn block @click="NewProperty()">
						<v-icon color="gray">mdi-plus</v-icon>
						New Property
					</v-btn>
				</div>
				<div v-else>
					<draggable
						v-model="propertiesSorted"
						group="properties"
						class="property-cards-wrap"
						draggable=".card-drag"
						drag-class="dragged"
						v-bind="getSortOptions()"
						:disabled="dragDisabled"
						@start="dragStart"
						@end="dragEnd"
					>
						<property-view-card
							class="card-drag"
							v-for="(property, i) in propertiesSorted"
							v-bind:property="property"
							v-bind:key="i"
							@propertyClick="PropertySelected(property)"
							@editClick="editPropertyClicked"
						></property-view-card>
						<v-card
							class="property-card"
							style="background-color: #f4f4f4"
							@click="NewProperty()"
						>
							<div class="add-property-card">
								<v-icon color="gray">mdi-plus</v-icon>
								New Property
							</div>
						</v-card>
					</draggable>
				</div>
				<cleaners-dialog
					v-if="cleanersDialog"
					v-bind:cleanersDialog="cleanersDialog"
					@cleanersdialogclosed="cleanersDialog = !cleanersDialog"
				></cleaners-dialog>
				<add-property-dialog
					v-if="propertyDialog"
					v-bind:addPropertyDialog="propertyDialog"
					@addpropertydialogclosed="propertyDialog = !propertyDialog"
				></add-property-dialog>
				<edit-property-dialog
					v-if="editDialog"
					:selectedProperty="selectedEditProperty"
					:editPropertyDialog="editDialog"
					@editpropertydialogclosed="editDialog = !editDialog"
				></edit-property-dialog>
			</div>
		</div>
	</v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "../../layout/Admin/Header.vue";
import AddPropertyDialog from "../../components/AddPropertyDialog";
import EditPropertyDialog from "../../components/EditPropertyDialog";
import CleanersDialog from "../../components/CleanersDialog.vue";
import PropertyViewCard from "../../components/PropertyViewCard.vue";
import PropertyViewList from "../../components/PropertyViewList.vue";
import draggable from "vuedraggable";

export default {
	name: "Properties",
	metaInfo: {
		title: "Properties",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Manage all of your vacation rentals in one place. Dormi makes it extremely easy to keep track of cleanings, maintenance, and reporting for each of your properties.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
		AddPropertyDialog,
		EditPropertyDialog,
		CleanersDialog,
		PropertyViewCard,
		PropertyViewList,
		draggable,
	},
	data() {
		return {
			editDialog: false,
			propertyDialog: false,
			cleanersDialog: false,
			selectedEditProperty: {},
			dragDisabled: false,
			isDragging: false,
		};
	},
	computed: {
		properties() {
			return this.getProperties();
		},
		propertiesSorted: {
			get() {
				if (!this.properties.length) {
					return [];
				}

				let properties = JSON.parse(JSON.stringify(this.properties));
				return properties.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
			},
			async set(newVal) {
				await this.SavePropertySort(newVal);
			},
		},
		viewLayout() {
			return this.getPropertyListView();
		},
	},
	created() {
		this.adminId = this.getUser();
		this.bindProperties()
			.then(() => {
				this.bindUser();
			})
			.catch(() => {
				alert(
					"It appears you have been logged out. Please try logging in again."
				);
				this.$router.push("/login");
			});
	},
	methods: {
		...mapGetters("adminUser", [
			"getProperties",
			"getUser",
			"getPropertyListView",
			"getUserData",
		]),
		...mapActions("adminUser", [
			"bindProperties",
			"savePropertyListView",
			"bindUser",
			"savePropertySort",
		]),
		editPropertyClicked(property) {
			this.selectedEditProperty = property;
			this.editDialog = !this.editDialog;
		},
		PropertySelected(property) {
			if (this.isDragging) {
				return;
			}
			console.log("Property selected");
			this.$store.state.storedCurrentProperty = property;
			console.log("property selected", property);
			this.$router.push("booklet/" + property.id);
		},
		NewProperty() {
			console.log("new property");
			this.propertyDialog = !this.propertyDialog;
		},
		ToggleViewLayout(view) {
			if (view != "list") {
				view = "card";
			}
			this.savePropertyListView(view);
		},
		getSortOptions() {
			return {
				forceFallback: true, // work around vuetify 'ripple' effect
			};
		},
		dragStart() {
			this.isDragging = true;
		},
		dragEnd() {
			setTimeout(() => {
				this.isDragging = false;
			}, 150);
		},
		SavePropertySort: async function (propertyListSorted) {
			try {
				this.dragDisabled = true;
				// trim down to only required updates
				let updateList = [];
				for (let i = 0; i < propertyListSorted.length; i++) {
					if (
						// eslint-disable-next-line no-prototype-builtins
						!propertyListSorted[i].hasOwnProperty("sort") ||
						propertyListSorted[i].sort != i
					) {
						updateList.push({
							id: propertyListSorted[i].id,
							sort: i,
						});
					}
				}

				if (updateList.length == 0) {
					return;
				}

				await this.savePropertySort(updateList);
			} catch (e) {
				console.error("Error updating property sort: ", e);
			} finally {
				this.dragDisabled = false;
			}
		},
	},
};
</script>

<style scoped>
.app-div {
	min-height: 100vh;
}
::-webkit-scrollbar {
	display: none;
}
.main-container {
	padding: 16px;
}
.purple-title,
.purple-title2 {
	color: #1d085a;
	font-size: 36px;
	font-family: "cabin_title";
	margin-bottom: 8px;
	margin-top: 36px;
	text-decoration: none;
	/*display: inline-block;*/
}
.purple-title2 {
	font-size: 30px;
	text-decoration: none;
}
.property-cards-wrap {
	display: grid;
	grid-template-columns: 31% 31% 31%;
	row-gap: 24px;
	justify-content: space-between;
	padding-top: 10px;
	padding-left: 0px;
}
.property-card {
	padding: 0px;
}
.add-property-card {
	color: gray;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.cleanerBtn {
	float: right;
	margin-top: 46px;
}
.mobileCleanerBtn {
	display: none;
}
@media (min-width: 1264px) {
	.container {
		max-width: 100%;
	}
}
@media screen and (max-width: 1000px) {
	.property-cards-wrap {
		grid-template-columns: 45% 45%;
	}
}
@media screen and (max-width: 850px) {
	.purple-title {
		font-size: 20px !important;
	}
	.cleanerBtn {
		display: none;
	}
	.mobileCleanerBtn {
		float: right;
		display: block;
		margin-top: 38px;
	}
}
@media screen and (max-width: 500px) {
	.property-cards-wrap {
		grid-template-columns: 100%;
	}
}
</style>
