<template>
	<div>
		<div
			style="display: flex; align-items: flex-start"
			@dragover="catDragOver"
			@dragenter="catDragEnter"
			@dragleave="catDragLeave"
		>
			<div @click="isExpanded = !isExpanded" class="checklist-box-title">
				<v-btn icon>
					<v-icon>{{
						isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
					}}</v-icon>
				</v-btn>
				{{ category.name }}
			</div>
			<div
				style="
					margin-left: auto;
					flex-wrap: nowrap;
					display: inline-flex;
				"
			>
				<v-icon
					style="margin-right: 14px"
					size="22px"
					@click="
						$emit('AddTaskClicked', category);
						isExpanded = true;
					"
					>mdi-plus</v-icon
				>
				<!-- <v-icon style="margin-right: 14px" size="22px">mdi-pencil</v-icon> -->
				<v-icon
					style="margin-right: 0px"
					size="22px"
					@click="$emit('DeleteTasksClicked', category)"
					>mdi-trash-can</v-icon
				>
			</div>
		</div>
		<v-expand-transition>
			<div v-show="isExpanded">
				<draggable
					v-model="category.tasks"
					group="tasks"
					@start="$emit('setSorting', true)"
					@end="$emit('setSorting', false)"
					@change="$emit('SortCategoryItems', $event, category)"
				>
					<checklist-items
						v-for="task in category.tasks"
						:key="task.id"
						:task="task"
						:category="category"
						class="checklist-box-task"
						@AppendSelectedTask="
							$emit('AppendSelectedTask', task, category)
						"
						@TaskSelected="$emit('TaskSelected', task, category)"
					></checklist-items>
				</draggable>
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
import ChecklistItems from "./ChecklistItems";
import draggable from "vuedraggable";

export default {
	props: {
		category: Object,
		sorting: Boolean,
	},
	data() {
		return {
			isExpanded: true,
			hoverTimeout: null,
		};
	},
	components: {
		ChecklistItems,
		draggable,
	},
	methods: {
		catDragOver: function (e) {
			e.preventDefault();
		},
		catDragEnter: function (e) {
			if (this.sorting && !e.currentTarget.contains(e.relatedTarget)) {
				if (this.isExpanded) {
					return;
				}

				this.hoverTimeout = setTimeout(() => {
					this.isExpanded = true;
				}, 1200);
			}
		},
		catDragLeave: function (e) {
			if (this.sorting && !e.currentTarget.contains(e.relatedTarget)) {
				if (this.hoverTimeout) {
					clearTimeout(this.hoverTimeout);
					this.hoverTimeout = null;
				}
			}
		},
	},
};
</script>

<style scoped>
.checklist-box-title {
	display: inline-block;
	color: #1d085a;
	font-size: 18px;
	flex-wrap: wrap;
	margin-right: 10px;
	margin-bottom: 12px;
}
.checklist-box-task {
	padding-left: 0px;
	display: flex;
	align-items: flex-start;
	margin: 12px 0px;
}
.checklist-box-task:last-child {
	border: none;
}
</style>
