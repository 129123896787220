<template>
	<v-app>
		<div class="app-div">
			<Header></Header>
			<div class="main-container">
				<h3 class="purple-title">Subscriptions</h3>
				<!-- Pricing select -->
				<div class="pricing-container" v-show="type == 'pricing'">
					<div class="pricing-box">
						<h4 class="price-title">Basic</h4>
						<p>For those who manage 1-3 properties.</p>
						<p class="plan-price">$15 <span>/mo</span></p>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Create up to 3 properties
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Digital guest booklet
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Custom checklists (BETA)
						</div>
						<div
							class="pricing-btn"
							@click="PriceSelected('basic')"
						>
							Start 14 Day Trial
						</div>
					</div>
					<div class="pricing-box">
						<h4 class="price-title">Pro</h4>
						<p>For those who manage 4-10 properties.</p>
						<p class="plan-price">$30 <span>/mo</span></p>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Create up to 10 properties
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Digital guest booklet
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Custom checklists (BETA)
						</div>
						<div class="pricing-btn" @click="PriceSelected('pro')">
							Start 14 Day Trial
						</div>
					</div>
					<div class="pricing-box">
						<h4 class="price-title">Property Management</h4>
						<p>For those who manage 11-50 properties.</p>
						<p class="plan-price">$45 <span>/mo</span></p>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Create up to 50 properties
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Digital guest booklet
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Custom checklists (BETA)
						</div>
						<div
							class="pricing-btn"
							@click="PriceSelected('management')"
						>
							Start 14 Day Trial
						</div>
					</div>
					<div class="pricing-box">
						<h4 class="price-title">Enterprise</h4>
						<p>For those who manage 50+ properties.</p>
						<p class="plan-price">$75 <span>/mo</span></p>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Create 50+ properties
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Digital guest booklet
						</div>
						<div>
							<v-icon color="rgba(108, 60, 210, .85)"
								>mdi-check-circle</v-icon
							>Custom checklists (BETA)
						</div>
						<div
							class="pricing-btn"
							@click="PriceSelected('enterprise')"
						>
							Start 14 Day Trial
						</div>
					</div>
				</div>

				<form id="payment-form" v-show="type == 'payment'">
					<h5 class="payment-title">Enter payment information</h5>
					<v-progress-circular
						v-if="loader"
						indeterminate
						color="#1d085a"
					></v-progress-circular>
					<div id="payment-element">
						<!-- Elements will create input elements here -->
					</div>

					<!-- We'll put the error messages in this element -->
					<button id="submit">Start Trial</button>
					<div id="error-message"></div>
					<!-- <div id="message"></div> -->
				</form>
			</div>
		</div>
	</v-app>
</template>

<script>
// See your keys here: https://dashboard.stripe.com/apikeys
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC);

import Header from "../../layout/Admin/Header.vue";
import { functions, auth, db } from "../../firebase";
import { mapState } from "vuex";

export default {
	name: "Subscriptions",
	metaInfo: {
		title: "Subscriptions",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Subscribe to Dormi for the best vacation rental operation software. Save months of extra hassling work.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		// Footer,
		Header,
	},
	data() {
		return {
			type: "pricing",
			subscription: "",
			loader: false,

			fullname: "",
			email: "",
			customerId: "",
		};
	},
	computed: {
		...mapState("adminUser", ["adminId"]),
	},
	created() {
		const user = auth.currentUser;
		if (user) {
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User
			db.collection("users_admin")
				.doc(user.uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.fullname = doc.data().name;
						this.email = user.email;
						this.customerId = doc.data().customer_id;
					}
					if (!this.customerId) {
						this.CreateCustomer();
					}
				})
				.catch((err) => console.log("Could not find", err));
		} else {
			// No user is signed in.
			console.log("No user signed in on Subscriptions page.");
			this.$router.push({ name: "Login" });
		}
	},
	methods: {
		PriceSelected: function (type) {
			this.subscription = type;
			this.type = "payment";
			this.loader = true;
			this.CreateSubscription(this.customerId);
		},
		CreateCustomer: function () {
			const data = { email: this.email, name: this.fullname };
			var createCustomer = functions.httpsCallable("createCustomer");
			createCustomer(data)
				.then((result) => {
					this.customerId = result.data.id;
				})
				.catch((error) => {
					console.log("Error creating customer:", error);
				});
		},
		CreateSubscription: function (customerId) {
			var createSubscription =
				functions.httpsCallable("createSubscription");
			var price_id = null;
			switch (this.subscription) {
				case "basic":
					price_id = process.env.VUE_APP_STRIPE_BASIC;
					break;
				case "pro":
					price_id = process.env.VUE_APP_STRIPE_PRO;
					break;
				case "management":
					price_id = process.env.VUE_APP_STRIPE_MANAGEMENT;
					break;
				case "enterprise":
					price_id = process.env.VUE_APP_STRIPE_ENTERPRISE;
					break;
			}
			createSubscription({ priceId: price_id, customerId: customerId })
				.then((result) => {
					let client_secret = result.data.clientSecret;
					let subscription_id = result.data.subscriptionId;
					let product_id = result.data.productId;
					let customer_id = result.data.customerId;
					let subscription_end = result.data.periodEnd;

					this.LoadStripe(client_secret);

					this.addSubscriptionToUser({
						customer_id: customer_id,
						subscription_id: subscription_id,
						product_id: product_id,
						subscription_status: "unpaid",
						subscription_end: subscription_end,
					});
				})
				.catch((error) => {
					console.log("Error creating subscription:", error);
				});
		},

		LoadStripe: function (clientSecret) {
			const options = {
				clientSecret: clientSecret,
				appearance: {
					theme: "stripe",
					variables: {
						colorPrimary: "#5e17eb",
						colorBackground: "#ffffff",
					},
				},
				loader: "always",
			};
			const elements = stripe.elements(options);
			const paymentElement = elements.create("payment");
			paymentElement.mount("#payment-element");
			this.loader = false;
			this.CompletePayment(elements);
		},
		CompletePayment: function (elements) {
			const form = document.getElementById("payment-form");

			form.addEventListener("submit", async (event) => {
				event.preventDefault();

				const { error } = await stripe.confirmPayment({
					//`Elements` instance that was used to create the Payment Element
					elements,
					confirmParams: {
						return_url:
							"https://dashboard.dormi.io/successful-subscription",
					},
				});

				if (error) {
					const messageContainer =
						document.querySelector("#error-message");
					messageContainer.textContent = error.message;
				}
			});
		},

		addSubscriptionToUser: function (customer) {
			console.log("Starting successfulSubscription", customer);

			db.collection("users_admin")
				.doc(this.adminId)
				.update({
					customer_id: customer.customer_id,
					subscription_id: customer.subscription_id,
					product_id: customer.product_id,
					subscription_status: customer.subscription_status,
					subscription_end: customer.subscription_end,
				})
				.catch((err) =>
					console.log(
						"Error adding user subscription to database",
						err
					)
				);
		},
	},
};
</script>

<style scoped>
.main-container {
	max-width: 1400px !important;
}
.purple-title,
.purple-title2 {
	color: #1d085a;
	font-size: 36px;
	font-family: "cabin_title";
	margin-bottom: 8px;
	margin-top: 36px;
	text-decoration: none;
	display: inline-block;
	margin-left: 32px;
}
.purple-title2 {
	font-size: 30px;
	text-decoration: none;
}

/* Pricing */
.pricing-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.pricing-box {
	height: auto;
	margin: 6px;
	width: 280px;
	min-width: 280px;
	background-color: white;
	border-radius: 16px;
	padding: 16px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.pricing-box > div > .v-icon {
	margin-right: 6px;
}
.pricing-btn {
	border: 1px solid rgba(108, 60, 210, 0.85);
	color: rgba(108, 60, 210, 0.85);
	height: 32px;
	padding: 0px 16px;
	line-height: 32px;
	text-align: center;
	margin-top: 32px;
	border-radius: 40px;
	cursor: pointer;
}
.pricing-btn:hover {
	background-color: rgba(108, 60, 210, 0.1);
}
.price-title {
	font-weight: bold;
	font-size: 20px;
	text-align: center;
	margin-bottom: 32px;
}
.plan-price {
	font-weight: bold;
	font-size: 20px;
	margin: 32px 0px;
}
/* Stripe Variables */
* {
	box-sizing: border-box;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	display: flex;
	justify-content: center;
	align-content: center;
	height: 100vh;
	width: 100vw;
}

form {
	width: 30vw;
	min-width: 500px;
	align-self: center;
	box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
		0px 2px 5px 0px rgba(50, 50, 93, 0.1),
		0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 40px;
	position: relative;
	margin-top: 32px;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
h5 {
	margin-bottom: 24px;
	color: #1d085a;
	font-size: 24px;
	font-family: "cabin_title";
}
#submit {
	height: 36px;
	min-width: 64px;
	padding: 0 16px;
	background-color: #5e17eb;
	margin-top: 24px;
	border-radius: 16px;
	color: white;
}
#submit:hover {
	filter: contrast(115%);
}
#submit:disabled {
	opacity: 0.5;
	cursor: default;
}
#error-message {
	margin-top: 20px;
	color: rgb(230, 75, 75);
}

.hidden {
	display: none;
}

@media only screen and (max-width: 600px) {
	form {
		width: 80vw;
		min-width: 300px;
	}
	.purple-title {
		font-size: 20px !important;
	}
}

#payment-form {
	margin: 0 auto;
}
</style>
