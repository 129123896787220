<template>
	<div>
		<div>
			<div class="property-cards-wrap">
				<v-card
					v-for="(amenity, i) in booklet.amenities"
					v-bind:key="i"
					class="property-card"
					@click="EditAmenity(amenity)"
					style="min-height: 100px !important"
				>
					<v-list-item-title class="card-title">{{
						amenity.name
					}}</v-list-item-title>
					<div
						style="
							font-size: 14px;
							color: #a7a7a7;
							white-space: pre-wrap;
						"
					>
						{{ amenity.description }}
					</div>
				</v-card>
				<v-card
					class="property-card"
					style="
						background-color: #f4f4f4;
						min-height: 100px !important;
					"
					@click="NewAmenity()"
				>
					<div class="add-property-card">
						<v-icon color="gray">mdi-plus</v-icon> New Amenity
					</div>
				</v-card>
			</div>
		</div>

		<!-- Amenity Dialog -->
		<v-dialog
			v-model="amenityDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card class="dialog-card">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="amenityDialog = false"
				>
					X
				</div>
				<div
					v-if="editingAmenity"
					class="purple-title2"
					style="margin-top: 0px"
				>
					{{ currentAmenity.name }}
				</div>
				<div v-else class="purple-title2" style="margin-top: 0px">
					New Amenity
				</div>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						outlined
						color="#1D085A"
						label="Name"
						v-model="currentAmenity.name"
					></v-text-field>
					<v-text-field
						outlined
						color="#1D085A"
						placeholder="Include https://"
						label="Website URL"
						:error-messages="urlErrors"
						v-model="currentAmenity.link"
					></v-text-field>
					<v-textarea
						outlined
						color="#1D085A"
						label="Description"
						v-model="currentAmenity.description"
					></v-textarea>
				</v-form>

				<v-row align="center" style="padding: 0px 16px">
					<v-btn
						v-if="!editingAmenity"
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="AddAmenity()"
						><v-icon color="white" style="margin-right: 16px"
							>mdi-plus</v-icon
						>Add</v-btn
					>
					<v-btn
						v-else
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="DoneEditingAmenity()"
						>Done</v-btn
					>
					<v-btn light style="width: fit" @click="CancelAmenity()"
						>Cancel</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						v-if="editingAmenity"
						color="error"
						dark
						@click="DeleteAmenity()"
						>Delete</v-btn
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase } from "../../firebase";

export default {
	props: {
		booklet: Object,
		bookletId: String,
		welcomePrev: String,
	},
	data() {
		return {
			amenityDialog: false,
			editingAmenity: false,
			currentAmenity: "",
			valid: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		// AMENITIES FUNCTIONS
		NewAmenity: function () {
			this.amenityDialog = true;
			this.editingAmenity = false;
			this.currentAmenity = { name: "", description: "" };
		},
		AddAmenity: function () {
			if (this.isBadUrl(this.currentAmenity.link)) {
				console.log("bad url");
				return false;
			}
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					amenities: firebase.firestore.FieldValue.arrayUnion(
						this.currentAmenity
					),
				})
				.then(() => {
					console.log("Document updated");
					this.amenityDialog = false;
					this.amenityIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		EditAmenity: function (amenity, index) {
			this.amenityDialog = true;
			this.editingAmenity = true;
			this.currentAmenity = amenity;
			this.originalBooklet = this.booklet;
			this.amenityIndex = index;
		},
		CancelAmenity: function () {
			this.amenityDialog = false;
		},
		DoneEditingAmenity: function () {
			if (this.isBadUrl(this.currentAmenity.link)) {
				console.log("bad url");
				return false;
			}
			this.booklet.amenities[this.amenityIndex] = this.currentAmenity;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					amenities: this.booklet.amenities,
				})
				.then(() => {
					console.log("Document updated");
					this.amenityDialog = false;
					this.amenityIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		DeleteAmenity: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					amenities: firebase.firestore.FieldValue.arrayRemove(
						this.currentAmenity
					),
				})
				.then(() => {
					console.log("Document deleted");
					this.amenityDialog = false;
					this.amenityIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
	},
};
</script>

<style scoped></style>
