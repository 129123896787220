<template>
	<v-app>
		<div class="app-div">
			<Header></Header>
			<!-- <div class="back-home" @click="BackToProperties()"><img class="arrow" src="../../assets/icons/arrow.png">Back to My Properties</div> -->
			<div class="purple-title-wrapper">
				<h3 class="purple-title" style="display: inline">
					Properties to Clean
				</h3>
			</div>
			<div class="main-container">
				<p
					style="margin-top: 20px; margin-left: -8px"
					v-if="!myProperties.length"
				>
					You are not currently assigned to any properties. Please
					talk to a property owner to have them assign you to one of
					their properties.
				</p>
				<draggable
					class="property-cards-wrap"
					group="properties"
					draggable=".card-drag"
					drag-class="dragged"
					v-bind="getSortOptions()"
					:disabled="dragDisabled"
					v-model="myProperties"
					@change="SavePropertySort"
					@start="dragStart"
					@end="dragEnd"
				>
					<v-card
						class="property-card card-drag"
						v-for="(property, i) in myProperties"
						v-bind:key="i"
						@click="PropertySelected(property)"
					>
						<v-img :src="property.main_image" height="20vh" dark>
							<v-row>
								<v-card-title>
									<!-- <v-btn
                                dark
                                icon
                                class="mr-4"
                                >
                                <v-icon>mdi-phone</v-icon>
                                </v-btn> -->

									<!-- <v-btn
                                dark
                                icon
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn> -->
								</v-card-title>
							</v-row>
						</v-img>

						<v-list two-line>
							<v-list-item style="min-height: 16px; padin">
								<!-- <v-list-item-icon>
                                <v-icon color="indigo">
                                mdi-phone
                                </v-icon>
                            </v-list-item-icon> -->

								<v-list-item-content
									style="
										padding-top: 0px;
										padding-bottom: 0px;
									"
								>
									<v-list-item-title
										style="display: inline"
										>{{ property.name }}</v-list-item-title
									>
									<v-list-item-subtitle
										>{{ property.bedrooms }} bds
										{{ property.bathrooms }} ba
									</v-list-item-subtitle>
									<v-list-item-subtitle
										>Clean by {{ property.clean_by }} Apr.
										24th @11AM</v-list-item-subtitle
									>
								</v-list-item-content>

								<v-icon
									color="rgb(76, 41, 151)"
									class="mapMarker"
									@click.stop="GetDirections(property)"
									>mdi-map-marker</v-icon
								>
							</v-list-item>
						</v-list>
					</v-card>
				</draggable>
			</div>
		</div>
	</v-app>
</template>

<script>
import Header from "../../layout/Vendor/Header.vue";
import { db } from "../../firebase";
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
// import $ from 'jquery'
function GetInfo(vendorId) {
	const myPromise = new Promise((resolve, reject) => {
		var docRef = db.collection("users_vendor").doc(vendorId);
		docRef
			.get()
			.then((doc) => {
				if (doc.exists) {
					let vendor = doc.data();
					resolve(vendor);
				} else {
					reject("No properties assigned yet.");
				}
			})
			.catch((error) => {
				console.log("Error getting document:", error);
			});
	});
	return myPromise;
}
export default {
	name: "Vendor Properties",
	metaInfo: {
		title: "Cleaner Properties",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Manage all of your cleaning properties in one place. Access each property checklist and images.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
		draggable,
	},
	data() {
		return {
			myProperties: [],
			dragDisabled: false,
			isDragging: false,
		};
	},
	computed: {
		...mapState("vendorUser", ["vendorId"]),
	},
	created() {
		this.GetVendorInfo();
	},
	methods: {
		...mapActions("vendorUser", ["savePropertySort"]),
		GetVendorInfo: function () {
			GetInfo(this.vendorId).then((vendor) => {
				this.GetProperties(vendor);
			});
		},
		GetProperties: function (vendor) {
			// eslint-disable-next-line no-prototype-builtins
			if (vendor.hasOwnProperty("properties")) {
				if (vendor.properties.length > 0) {
					for (var i = 0; i < vendor.properties.length; i++) {
						db.collection("properties")
							.doc(vendor.properties[i])
							.get()
							.then((doc) => {
								if (doc.exists) {
									this.myProperties.push(doc.data());
								} else {
									console.log(
										"Property must have been deleted"
									);
								}
							})
							.catch((err) => {
								console.log("error", err);
							});
					}
				}
			}
		},
		PropertySelected(property) {
			if (this.isDragging) {
				return;
			}
			this.$store.state.selectedVendorProperty = property;
			console.log(this.$store.state.selectedVendorProperty);
			this.$router.push("checklist/" + property.id);
		},
		GetDirections(property) {
			var url =
				"https://www.google.com/maps/search/?api=1&query=" +
				property.address +
				" " +
				property.city +
				" " +
				property.state +
				", " +
				property.zip;
			window.open(url, "_blank");
		},
		getSortOptions() {
			return {
				forceFallback: true, // work around vuetify 'ripple' effect
			};
		},
		dragStart() {
			this.isDragging = true;
		},
		dragEnd() {
			setTimeout(() => {
				this.isDragging = false;
			}, 150);
		},
		async SavePropertySort() {
			try {
				this.dragDisabled = true;

				let propertiesUpdate = this.myProperties.map(
					(property) => property.id
				);

				await this.savePropertySort(propertiesUpdate);
			} catch (e) {
				console.error("Error updating property sort: ", e);
			} finally {
				this.dragDisabled = false;
			}
		},
	},
};
</script>

<style>
@media screen and (min-width: 850px) {
	/* .v-application--wrap{
            flex-direction: row !important;
            flex-wrap: wrap;
            max-width: 1200px;
            margin: 0 auto;
        } */
}
</style>

<style scoped>
.property-cards-wrap {
	display: grid;
	grid-template-columns: 31% 31% 31%;
	row-gap: 24px;
	justify-content: space-between;
	padding-top: 10px;
}
.property-card {
	border-radius: 15px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
	cursor: pointer;
	min-height: 200px;
	background-color: white !important;
}
.property-card:hover {
	transform: scale(1.05);
}
.property-card-select {
	margin: 6px !important;
}
.row {
	float: right;
}
.purple-title-wrapper {
	margin-top: 16px !important;
}
.purple-title {
	text-decoration: none;
	padding-left: 8px;
	font-size: 24px;
}
.v-application a {
	color: transparent;
}
.header-space {
	height: 72px;
	width: 100%;
}
.main-container {
	padding: 0px 16px;
}
.card-wrap {
	padding-top: 10px;
	justify-content: space-between;
}
.mapMarker {
	border-radius: 50%;
	padding: 6px;
}
.mapMarker:hover {
	background-color: rgb(76, 41, 151, 0.2);
}
@media screen and (max-width: 1000px) {
	.property-cards-wrap {
		grid-template-columns: 45% 45%;
	}
}
@media screen and (max-width: 850px) {
	.property-cards-wrap {
		grid-template-columns: 100%;
	}
}
@media screen and (min-width: 850px) {
	.header-space {
		height: 110px;
		width: 100%;
	}
	.purple-title-wrapper {
		max-width: 1200px;
		margin: 0 auto;
	}
	.mx-auto {
		width: 32%;
		margin: 16px 0px;
	}
	.card-wrap {
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		padding-top: 0px;
	}
}
</style>
