<template>
	<v-app style="max-height: 60px">
		<v-app-bar dark prominent class="toolbar" height="60px" color="white">
			<v-toolbar-title
				class="toolbar-title"
				@click="navSelected('Properties')"
				>Dormi</v-toolbar-title
			>

			<v-spacer></v-spacer>

			<div class="nav-details">
				<a
					to="/login"
					style="
						padding: 0px !important;
						color: #1d085a !important;
						text-decoration: none;
					"
					class="home-login"
					>Logout</a
				>
			</div>

			<v-btn icon>
				<v-icon @click.stop="drawer = !drawer" color="#1D085A"
					>mdi-menu</v-icon
				>
			</v-btn>
		</v-app-bar>

		<v-navigation-drawer
			v-model="drawer"
			absolute
			right
			temporary
			height="100vh"
			style="z-index: 500"
		>
			<v-divider></v-divider>

			<v-list dense>
				<v-list-item
					v-for="item in items"
					:key="item.title"
					link
					@click="navSelected(item.title)"
				>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
	name: "StaticHeader",
	components: {},
	data() {
		return {
			group: null,
			drawer: null,
			items: [
				{ title: "Properties", icon: "mdi-cards" },
				// { title: "Calendar", icon: "mdi-calendar" },
				// { title: "Account", icon: "mdi-account" },
			],
		};
	},
	methods: {
		...mapActions("vendorUser", ["logoutVendorUserAction"]),
		navSelected: function (name) {
			this.$router.push({ name: name });
		},
	},
	watch: {
		group() {
			this.drawer = false;
		},
	},
};
</script>

<style scoped>
.visible {
	visibility: visible;
}
.invisible {
	visibility: hidden;
}
.red {
	color: rgb(243, 115, 115) !important;
	margin-top: 2px !important;
}

::-webkit-scrollbar {
	display: none;
}
#navbar {
	padding: 0px 8px;
}
/* HOME PAGE STYLES */
.main-background {
	/* The image used */
	background: url("../../assets/images/MainBackground.jpg") no-repeat center
		center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: 100% auto;

	/* Full height */
	height: 100%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.main-container {
	max-width: 1200px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0px 24px;
}
header {
	width: 100%;
	position: relative !important;
	color: #f9f9f9;
}
.toolbar-title {
	color: #5e17eb !important;
	font-size: 26px !important;
	font-weight: bold;
	letter-spacing: 0.1ch;
}
.header-active {
	background-color: #f9f9f9;
	-webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
	-moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
	box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
	color: black;
}
.header-active .nav-demo {
	color: #f9f9f9;
}
.header-active li:hover {
	color: rgba(168, 168, 168, 0.952);
}
.header-active .nav-demo:hover {
	color: #f9f9f9;
}
.nav-bar-container {
	width: 100%;
	vertical-align: middle;
	padding: 0px;
	display: inline-block;
}
.nav-logo,
.nav-logo-set {
	position: relative;
	height: 60px;
	width: auto;
	vertical-align: middle;
	margin-top: -4px;
	cursor: pointer;
}
.nav-ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	float: right;
}
.nav-li {
	float: left;
	display: block;
	padding: 16px 48px;
	text-transform: uppercase;
	transition: all 0.5s ease;
	overflow: hidden;
	cursor: pointer;
}
.nav-details {
	float: left;
	display: block;
	padding: 14px 48px;
	text-transform: uppercase;
	transition: all 0.5s ease;
	overflow: hidden;
	cursor: pointer;
	color: #5e17eb;
}
.nav-demo {
	background-color: #5e17eb;
	border-radius: 40px;
	color: white;
}
.nav-demo-white {
	background-color: white;
	border-radius: 40px;
	color: #5e17eb;
}
/* .nav-li:hover{
        filter: brightness(75%);
        cursor: pointer;
    }
    .nav-demo:hover{
        background-color: rgb(76, 41, 151);
        filter: none;
        cursor: pointer;
        color: #F9F9F9;
    }
    .nav-details:hover > .subnav-content{
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 250ms;
    }
    .nav-details:hover > .triangle-up{
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 250ms;
    }
    .nav-details:hover{
        cursor: pointer;
        color:rgba(168, 168, 168, 0.952);
    }
    .bold-nav{
        font-family: "cabin_bold";
    } */
/* Style the dropdown content (hidden by default) */
</style>
