<template>
	<div class="app-div">
		<h1>Reports</h1>
	</div>
</template>

<script>
export default {
	name: "Reports",
	metaInfo: {
		title: "Reports",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Manage all of your vacation rentals in one place. Dormi makes it extremely easy to keep track of cleanings, maintenance, and reporting for each of your properties.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {},
};
</script>

<style scoped></style>
