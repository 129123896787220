import { db } from "@/firebase";

const eventsApi = {
	// DELETE
	deleteEvent: function (deleteEventId) {
		let res = {
			status: 0,
			message: "",
		};
		let eventDoc = db.collection("events").doc(deleteEventId);
		return eventDoc
			.delete()
			.then(() => {
				res.status = 202;
				res.message = "Event Successfully Deleted";
				return res;
			})
			.catch((error) => {
				console.error("Error deleting event: ", error);
				res.status = 400;
				res.message = "Could not delete event. Does it exist?";
				return res;
			});
	},
};

export default eventsApi;
