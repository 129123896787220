<template>
	<v-row class="property-row" @click="propertyClick(property)">
		<v-col style="">
			<v-img
				:src="property.main_image"
				height="30px"
				width="60px"
				dark
			></v-img>
		</v-col>
		<v-col style="">{{ property.name }}</v-col>
		<v-col style="">{{ property.address }}</v-col>
		<v-col class="text-right" style="">
			<v-btn color="#1D085A" icon style="margin-top: -6px">
				<v-icon
					color="#5e17eb"
					size="22px"
					@click.stop="editClick(property)"
				>
					mdi-pencil
				</v-icon>
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		property: Object,
	},
	data() {
		return {};
	},
	methods: {
		editClick(property) {
			this.$emit("editClick", property);
		},
		propertyClick(property) {
			this.$emit("propertyClick", property);
		},
	},
};
</script>

<style>
.property-row {
	cursor: pointer;
	border-bottom: 1px solid rgba(128, 128, 128, 0.322);
}
.property-row:hover {
	background-color: #eee;
}
</style>
