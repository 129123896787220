<template>
	<div>
		<div style="display: flex; align-items: flex-start">
			<div class="checklist-box-title">{{ category.name }}</div>
			<div
				style="
					margin-left: auto;
					flex-wrap: nowrap;
					display: inline-flex;
				"
			>
				<v-icon
					style="margin-right: 14px"
					size="22px"
					@click="$emit('AddTaskClicked', category)"
					>mdi-plus</v-icon
				>
				<!-- <v-icon style="margin-right: 14px" size="22px">mdi-pencil</v-icon> -->
				<v-icon
					style="margin-right: 0px"
					size="22px"
					@click="$emit('DeleteTasksClicked', category)"
					>mdi-trash-can</v-icon
				>
			</div>
		</div>
		<draggable
			v-model="category.tasks"
			group="tasks"
			@start="drag = true"
			@end="drag = false"
			@change="$emit('SortCategoryItems', $event, category)"
		>
			<checklist-items
				v-for="task in category.tasks"
				:key="task.id"
				:task="task"
				:category="category"
				class="checklist-box-task"
				@AppendSelectedTask="
					$emit('AppendSelectedTask', task, category)
				"
				@TaskSelected="$emit('TaskSelected', task, category)"
			></checklist-items>
		</draggable>
	</div>
</template>

<script>
import ChecklistItems from "./ChecklistItems";
import draggable from "vuedraggable";

export default {
	props: {
		category: Object,
	},
	data() {
		return {};
	},
	components: {
		ChecklistItems,
		draggable,
	},
};
</script>

<style scoped>
.checklist-box-title {
	display: inline-block;
	color: #1d085a;
	font-size: 18px;
	flex-wrap: wrap;
	margin-right: 10px;
	margin-bottom: 12px;
}
.checklist-box-task {
	padding-left: 0px;
	display: flex;
	align-items: flex-start;
	margin: 12px 0px;
}
.checklist-box-task:last-child {
	border: none;
}
</style>
