import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";

// import firebase from "firebase/app";
// Logged Out Imports
import Login from "../views/Login";
import Register from "../views/Register";
import GuestBooklet from "../views/GuestBooklet.vue";

// Logged In Imports
import Properties from "../views/Admin/Properties";
import Property from "../views/Admin/Property";
import PropertyChecklist from "../views/Admin/PropertyChecklist";
import Calendar from "../views/Admin/Calendar";
import Reports from "../views/Admin/Reports";
import Subscriptions from "../views/Admin/Subscriptions";
import SuccessfulSubscription from "../views/Admin/SuccessfulSubscription";
import Account from "../views/Admin/Account";
import Booklet from "../views/Admin/Booklet.vue";

// Vendor Imports
import Checklist from "../views/Vendor/Checklist";
import Vendor_Properties from "../views/Vendor/Vendor_Properties.vue";
// import { auth } from 'firebase-admin';
// import { onAuthStateInit } from '../firebase'

import ErrorPage from "../views/Error.vue";

import Meta from "vue-meta";

Vue.use(Meta);
Vue.use(VueRouter);

const routes = [
	// LOGGED OUT COMPONENTS
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/guestbooklet/:property_id",
		name: "GuestBooklet",
		component: GuestBooklet,
	},

	// LOGGED IN COMPONENTS
	{
		path: "/account",
		name: "Account",
		component: Account,
	},
	{
		path: "/booklet/:property_id",
		name: "Booklet",
		component: Booklet,
	},
	{
		path: "/calendar",
		name: "Calendar",
		component: Calendar,
	},
	{
		path: "/properties",
		name: "Properties",
		component: Properties,
	},
	{
		path: "/property/:property_id",
		name: "Property",
		component: Property,
	},
	{
		path: "/propertychecklist/:property_id",
		name: "PropertyChecklist",
		component: PropertyChecklist,
	},
	{
		path: "/reports",
		name: "Reports",
		component: Reports,
	},
	{
		path: "/subscriptions",
		name: "Subscriptions",
		component: Subscriptions,
	},
	{
		path: "/successful-subscription",
		name: "SuccessfulSubscriptions",
		component: SuccessfulSubscription,
	},

	// VENDOR COMPONENTS
	{
		path: "/checklist/:property_id",
		name: "Checklist",
		component: Checklist,
	},
	{
		path: "/vendor_properties",
		name: "Vendor_Properties",
		component: Vendor_Properties,
	},
	{
		path: "*",
		component: ErrorPage,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

router.beforeEach(async (to, from, next) => {
	// await onAuthStateInit();
	if (to.matched.some((route) => route.meta.authRequired)) {
		if (store.getters.isAuthenticated && store.state.adminId) {
			next();
		} else {
			next({ name: "Login" });
		}
	} else {
		next();
	}
});

export default router;
