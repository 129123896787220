<template>
	<v-app>
		<div class="app-div">
			<Header></Header>
			<v-row class="top-row">
				<v-spacer></v-spacer>
				<v-col sm="12">
					<v-sheet>
						<v-row>
							<v-col class="inherit">
								<v-btn
									fab
									text
									small
									color="#1D085A"
									@click="prev"
								>
									<v-icon small> mdi-chevron-left </v-icon>
								</v-btn>
								<!-- <v-btn outlined class="rounded-btn" color="#80808090" @click="setToday">
                  Today
                </v-btn> -->
								<v-toolbar-title
									v-if="$refs.calendar"
									style="padding-top: 4px; color: #1d085a"
								>
									{{ $refs.calendar.title }}
								</v-toolbar-title>
								<v-btn
									fab
									text
									small
									color="#1D085A"
									@click="next"
								>
									<v-icon small> mdi-chevron-right </v-icon>
								</v-btn>

								<v-spacer />

								<v-spacer></v-spacer>
								<v-btn class="add-btn" @click="addClicked()"
									><v-icon color="#80808090">mdi-plus</v-icon
									>Add Event</v-btn
								>
								<v-menu bottom right>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											outlined
											color="#80808090"
											v-bind="attrs"
											v-on="on"
											class="rounded-btn"
											style="margin-right: 16px"
										>
											<span>{{ typeToLabel[type] }}</span>
											<v-icon right>
												mdi-menu-down
											</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item @click="type = 'day'">
											<v-list-item-title
												>Day</v-list-item-title
											>
										</v-list-item>
										<v-list-item @click="type = 'week'">
											<v-list-item-title
												>Week</v-list-item-title
											>
										</v-list-item>
										<v-list-item @click="type = 'month'">
											<v-list-item-title
												>Month</v-list-item-title
											>
										</v-list-item>
										<v-list-item @click="type = '4day'">
											<v-list-item-title
												>4 days</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-col>
						</v-row>
					</v-sheet>
					<v-sheet height="600" max-width="1400">
						<v-calendar
							ref="calendar"
							v-model="focus"
							:events="events"
							:type="type"
							@click:event="showEvent"
							@click:more="viewDay"
							@click:date="viewDay"
							@change="loadEvents()"
							@click:day="addClickedWithDate"
							@click:time="addClickedWithTime"
						>
							<template
								v-slot:event="{ event, timed, eventSummary }"
							>
								<div
									v-if="event.border"
									class="v-event-draggable"
									:style="{
										border:
											'1px solid ' +
											event.textColor +
											' !important',
										backgroundColor: event.color,
										color: event.textColor,
										display: 'flex',
										'justify-content': 'space-between',
									}"
									v-html="eventSummary()"
								></div>

								<div
									v-else
									class="v-event-draggable"
									v-bind:style="{
										border:
											'1px ' +
											' solid ' +
											event.textColor,
										backgroundColor: event.color,
										color: event.textColor,
										display: 'flex',
										'justify-content': 'space-between',
									}"
									v-html="eventSummary()"
								></div>

								<div
									v-if="timed"
									class="v-event-drag-bottom"
									@mousedown.stop="extendBottom(event)"
								></div>
							</template>
						</v-calendar>
						<v-menu
							v-if="selectedElement"
							v-model="selectedOpen"
							:close-on-content-click="false"
							:activator="selectedElement"
							max-width="500px"
							min-width="300px"
							offset-y
						>
							<v-card
								min-width="375px"
								min-height="100px"
								flat
								class="event-card"
							>
								<div
									v-bind:style="{
										'border-bottom':
											'1px ' +
											' solid ' +
											selectedEvent.textColor,
										backgroundColor: selectedEvent.color,
										color: selectedEvent.textColor,
										display: 'flex',
										'justify-content': 'space-between',
									}"
									style="
										position: absolute;
										top: 0px;
										left: 0px;
										right: 0px;
									"
								>
									<div
										:style="{
											'padding-left': '10px',
											'font-size': '14px',
											height: '25px',
											display: 'flex',
											'align-items': 'center',
										}"
									>
										{{ selectedEvent.name }}
									</div>
									<div
										:style="{
											display: 'flex',
											'align-items': 'center',
										}"
									>
										<v-icon
											@click="editEventClicked()"
											:style="{
												color: selectedEvent.textColor,
												'font-size': '16px',
												'padding-right': '12px',
												'padding-bottom': '1px',
											}"
										>
											mdi-file-document-edit-outline</v-icon
										>
										<v-icon
											@click="deleteCalendarEvent()"
											:style="{
												color: selectedEvent.textColor,
												'font-size': '16px',
												'padding-right': '8px',
											}"
											>mdi-trash-can-outline</v-icon
										>
										<v-icon
											@click="selectedOpen = false"
											:style="{
												color: selectedEvent.textColor,
												'font-size': '16px',
												'padding-right': '8px',
											}"
											>mdi-close</v-icon
										>
									</div>
								</div>
								<div class="propertyInfoContainer">
									<div class="propertyInfo">
										<div
											style="
												color: #1d085a;
												font-size: 18px;
											"
										>
											{{ selectedEvent.property.name }}
										</div>
										<div class="serviceAndTime">
											{{ selectedEvent.service_name
											}}<br />
											{{ selectedEvent.formattedStart }}
											<span
												class="serviceAndTime"
												v-if="selectedEvent.end"
											>
												-
												{{
													selectedEvent.formattedEnd
												}}</span
											>
										</div>
										<div
											v-bind:style="[
												!selectedEvent.border
													? {
															color: selectedEvent.color,
													  }
													: {
															color: selectedEvent.textColor,
													  },
											]"
											style="font-size: 14px"
										>
											{{ selectedEvent.status }}
										</div>
									</div>
									<div class="propertyImageContainer">
										<img
											:src="selectedEvent.property.image"
											style="
												max-height: 140px;
												width: 100%;
												border-radius: 8px;
												align-self: center;
											"
										/>
									</div>
								</div>
							</v-card>
						</v-menu>
					</v-sheet>
				</v-col>
			</v-row>
			<add-event-dialog
				v-if="addDialog"
				:propStart="start"
				:propTime="time"
				v-bind:addDialog="addDialog"
				@addEventDialogClosed="addDialog = !addDialog"
			></add-event-dialog>
			<edit-event-dialog
				v-if="editDialog"
				:selectedEvent="selectedEvent"
				:editEventDialog="editDialog"
				@editEventDialogClosed="editDialog = !editDialog"
			></edit-event-dialog>
		</div>
	</v-app>
</template>

<script>
// TODO:
//  - properties are not being loaded and so they can't
//  be added to an event
import Header from "../../layout/Admin/Header.vue";
import AddEventDialog from "../../components/AddEventDialog.vue";
import moment from "moment";
import { mapGetters, mapActions, mapState } from "vuex";
import EditEventDialog from "../../components/EditEventDialog.vue";

export default {
	name: "Calendar",
	metaInfo: {
		title: "Calendar",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"The best way to stay organized with your vacation rentals, is by using the Dormi calendar. Our software directly integrates with Track, VRBO, and others to automatically schedule your next clean and maintenance check.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
		AddEventDialog,
		EditEventDialog,
	},
	data: () => ({
		focus: "",
		type: "month",
		timeValue: "",
		typeToLabel: {
			month: "Month",
			week: "Week",
			day: "Day",
			"4day": "4 Days",
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		addDialog: false,
		editDialog: false,
		events: [],
		cleaners: [],
		statusi: [
			{
				name: "pending",
				color: "#FFFFFF",
				textColor: "#40C800",
				border: true,
			},
			{
				name: "rejected",
				color: "#D72525",
				textColor: "#FFFFFF",
			},
			{
				name: "accepted",
				color: "#20B6F6",
				textColor: "#FFFFFF",
			},
			{
				name: "in-progress",
				color: "#5E17EB",
				textColor: "#FFFFFF",
			},
			{
				name: "complete",
				color: "#40C800",
				textColor: "#FFFFFF",
			},
			{
				name: "booking",
				color: "#20B6F6",
				textColor: "#FFFFFF",
			},
		],
		start: "",
		end: "",
		time: "",
	}),
	mounted() {
		this.$refs.calendar.move(0);
	},
	created() {
		this.loadEvents();
	},
	computed: {
		...mapState("adminUser", ["adminId", "properties"]),
		statusNameColor: function () {
			let statusName = {
				color: "",
				"font-size": "13px",
				"margin-top": "10px",
			};
			if (this.selectedEvent.textColor == "#20B6F6") {
				statusName.color = this.selectedEvent.textColor;
				return statusName;
			}
			statusName.color = this.selectedEvent.color;
			return statusName;
		},
		stateEvents() {
			return this.getEventsForUser();
		},
	},
	watch: {
		// watch for changes in stateEvents and update the events array
		stateEvents: {
			handler(newVal) {
				this.events = JSON.parse(JSON.stringify(newVal));
				for (let i = 0; i < newVal.length; i++) {
					this.events[i].eventId = newVal[i].id;
				}
				this.checkDates();
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		...mapActions("adminUser", ["bindEvents", "deleteEvent"]),
		...mapGetters("adminUser", ["getEventsForUser"]),
		// getEventsForUser(){
		//   db.collection("events").where("admin_id", "==", this.adminId).get().then((snapshot)=>{
		//     snapshot.forEach((doc)=>{
		//       var data = doc.data()
		//       data.start = doc.data().toDate()
		//       data.end = doc.data().toDate()
		//       this.events.push(data)
		//     });
		//     console.log(this.events)
		//   })
		// },
		loadEvents() {
			console.log("loading events");
			this.bindEvents().then((events) => {
				console.log(events);
				// this.checkDates();
			});
		},
		checkDates() {
			for (let i = 0; i < this.events.length; i++) {
				this.events[i].start = new Date(
					this.events[i].start.seconds * 1000
				);
				this.events[i].formattedStart = moment(
					this.events[i].start
				).format("lll");
				if (this.events[i].end !== null) {
					this.events[i].end = new Date(
						this.events[i].end.seconds * 1000
					);
					this.events[i].formattedEnd = moment(
						this.events[i].end
					).format("lll");
				}
				for (let j = 0; j < this.statusi.length; j++) {
					if (this.statusi[j].name == this.events[i].status) {
						this.events[i].color = this.statusi[j].color;
						this.events[i].textColor = this.statusi[j].textColor;
						this.events[i].border = this.statusi[j].border;
						break;
					}
				}
			}
		},
		async deleteCalendarEvent() {
			this.selectedOpen = false;
			if (confirm("Are you sure you want to delete this event?")) {
				await this.deleteEvent(this.selectedEvent);
			}
		},
		editEventClicked() {
			this.editDialog = !this.editDialog;
		},
		addClicked() {
			this.start = "";
			this.addDialog = !this.addDialog;
		},
		addClickedWithDate({ date }) {
			this.start = date;
			this.addDialog = !this.addDialog;
		},
		addClickedWithTime(event) {
			var pass = event.hour + ":" + event.minute;
			console.log("time", pass);
			this.start = event.date;
			this.addDialog = !this.addDialog;
		},
		editEventClosed(editedEvent) {
			this.selectedEvent = editedEvent;
			this.editDialog = !this.editDialog;
		},
		viewDay({ date }) {
			this.focus = date;
			this.type = "day";
		},
		getEventColor(event) {
			return event.color;
		},
		setToday() {
			this.focus = "";
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			this.selectedEvent = event;
			const open = () => {
				this.selectedElement = nativeEvent.target;
				setTimeout(() => {
					this.selectedOpen = true;
				}, 10);
			};
			if (this.selectedOpen) {
				this.selectedOpen = false;
				setTimeout(open, 10);
			} else {
				open();
			}
			nativeEvent.stopPropagation();
		},
	},
};
</script>

<style scoped>
.app-div {
	min-height: 100vh;
}
.top-row {
	margin: 0 auto;
	padding: 2em;
	max-width: 1400px;
}
.top-sheet {
	margin-bottom: 3rem;
}
.sheet-off {
	padding-top: 0;
	z-index: 0;
}
.inherit {
	display: inherit !important;
	margin-bottom: 6px !important;
}
.stuff {
	position: absolute;
	bottom: 10px;
	left: 10px;
}
.rounded-btn {
	border-radius: 40px;
	font-size: 14px;
	text-transform: capitalize;
}
.add-btn {
	background-color: transparent !important;
	color: #80808090 !important;
	border: 0px;
	box-shadow: none !important;
	text-transform: capitalize;
	text-align: right;
	margin-right: 32px;
}
.event-card {
	padding: 8px;
	border-radius: 14px;
}
.propertyInfoContainer {
	display: flex;
	padding: 18px;
	padding-top: 32px;
	padding-bottom: 12px !important;
	align-items: center;
}
.propertyInfo {
	margin-right: 16px;
	width: 100%;
}
.propertyImageContainer {
	width: 100%;
}
.serviceAndTime {
	color: #a7a7a7;
	font-size: 12px;
}
.v-event-draggable {
	font-size: 12px;
	padding: 0px 6px;
	border-radius: 0px !important;
	line-height: 18px;
	box-sizing: border-box; /* add this */
	-moz-box-sizing: border-box; /* Firefox */
	-webkit-box-sizing: border-box; /* Older Webkit browsers */
}
</style>
