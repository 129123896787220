<template>
	<div>
		<div
			v-if="qrCodeUrl == ''"
			style="text-align: center"
			@click="CreateQR()"
		>
			<v-btn color="#5E17EB" dark>Generate QR Code</v-btn>
		</div>
		<img v-else :src="qrCodeUrl" id="qrcode" />
		<v-spacer />
		<!-- <a :href="qrDownload" target="_blank" download="" ><v-btn color="#5E17EB" dark>Download</v-btn></a> -->
		<p v-if="qrCodeUrl != ''">
			To save this qr code, right click on the code and click 'Save Image
			As'<br />or<br />on mobile, press and hold to save.
		</p>
		<canvas id="canvas"></canvas>
	</div>
</template>

<script>
import { db, storage } from "../../firebase";
var QRCode = require("qrcode");

export default {
	props: {
		booklet: Object,
		bookletId: String,
		currentPropertyId: String,
	},
	data() {
		return {
			qrCodeUrl: this.booklet.qrcode,
			qrDownload: "",
			valid: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		// QR FUNCTIONS
		CreateQR: function () {
			var canvas = document.getElementById("canvas");

			QRCode.toCanvas(
				canvas,
				"https://dashboard.dormi.io/guestbooklet/" +
					this.currentPropertyId,
				(error) => {
					if (error) {
						console.error(error);
					} else {
						canvas.toBlob((blob) => {
							canvas.style.display = "none";
							var image = new Image();
							image.src = blob;
							var storageRef = storage.ref();
							var qrRef = storageRef.child(
								"qrcodes/" + this.currentPropertyId
							);
							qrRef.put(blob).then(() => {
								console.log("Uploaded a blob or file!");
								qrRef.getDownloadURL().then((url) => {
									this.AddQrToFirestore(url);
								});
							});
						});
					}
				}
			);
		},
		AddQrToFirestore: function (url) {
			this.booklet.qrcode = url;
			this.qrCodeUrl = url;
			// Set the "capital" field of the city 'DC'
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					qrcode: this.booklet.qrcode,
				})
				.then(() => {
					console.log("Document successfully updated!");
				})
				.catch((error) => {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error);
				});
		},
	},
};
</script>

<style scoped>
#qrcode {
	width: 200px;
	height: 200px;
	margin-top: 32px;
	border: 1px solid black;
	border-radius: 12px;
	margin-bottom: 12px;
}
#canvas {
	display: block;
}
</style>
