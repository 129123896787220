import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
// import createPersistedState from 'vuex-persistedstate';

import adminUser from "@/store/adminUser/adminUser.store.js";
import adminPropertyChecklist from "@/store/adminUser/propertyChecklist.store.js";
import vendorUser from "@/store/vendorUser/vendorUser.store.js";

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
	mutations: {
		...vuexfireMutations,
	},
	modules: {
		adminUser,
		adminPropertyChecklist,
		vendorUser,
	},
});
