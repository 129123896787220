<template>
	<v-app>
		<v-app-bar dark prominent class="toolbar" height="60px" color="white">
			<v-spacer></v-spacer>
			<v-toolbar-title class="toolbar-title" style="color: #000000de"
				>{{ booklet.property_name }} Guest Booklet</v-toolbar-title
			>

			<v-spacer />
		</v-app-bar>
		<v-card>
			<v-tabs
				background-color="#5E17EB"
				center-active
				dark
				v-model="activeTab"
			>
				<v-tab
					v-for="item in items"
					:key="item.title"
					link
					@click.stop="SideNavClicked(item)"
					>{{ item.title }}</v-tab
				>
			</v-tabs>
		</v-card>

		<!-- <v-navigation-drawer
            v-model="drawer"
            absolute
            right
            height="100vh"
            style="z-index: 500"
            >
            <v-divider></v-divider>

            <v-list dense>
                <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                @click.stop="SideNavClicked(item)"
                >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-navigation-drawer> -->
		<img
			v-if="activeSidenav == 'guide'"
			:src="propertyImg"
			style="max-height: 400px; width: 100%"
			@click="
				expand = !expand;
				expandedImage = propertyImg;
			"
		/>
		<div class="center app-div">
			<div class="main-container">
				<!-- <div class="top-nav">
                    <li v-if="booklet.guide.length" :class="{'active-sidenav': activeSidenav=='guide'}" @click="SideNavClicked('guide')">Home Guide</li>
                    <li v-if="booklet.amenities.length" :class="{'active-sidenav': activeSidenav=='amenities'}" @click="SideNavClicked('amenities')">Amenities</li>
                    <li v-if="booklet.attractions.length" :class="{'active-sidenav': activeSidenav=='attractions'}" @click="SideNavClicked('attractions')">Attractions</li>
                    <li v-if="booklet.excursions.length" :class="{'active-sidenav': activeSidenav=='excursions'}" @click="SideNavClicked('excursions')">Excursions</li>
                    <li v-if="booklet.sites.length" :class="{'active-sidenav': activeSidenav=='sites'}" @click="SideNavClicked('sites')">Sites</li>
                    <li v-if="booklet.restaurants.length" :class="{'active-sidenav': activeSidenav=='restaurants'}" @click="SideNavClicked('restaurants')">Restaurants</li>
                    <li v-if="booklet.faqs.length" :class="{'active-sidenav': activeSidenav=='faqs'}" @click="SideNavClicked('faqs')">FAQs</li>
                </div> -->
				<!-- <h3 v-if="activeSidenav == 'guide'" class="purple-title" style="text-decoration: none; margin-top: 8px; font-size: 24px">{{booklet.property_name}}</h3> -->
				<!-- <h3 v-else class="purple-title" style="text-decoration: none; margin-top: 8px; font-size: 24px">{{activeTitle}}</h3> -->
				<v-divider style="margin-bottom: 16px"></v-divider>

				<!-- Guide -->
				<div v-if="activeSidenav == 'guide'" style="flex: 1">
					<p style="white-space: pre-wrap">{{ welcomeNote }}</p>
					<div class="property-cards-wrap">
						<v-card
							v-for="(item, i) in booklet.guide"
							v-bind:key="i"
							class="property-card"
							style="min-height: 100px !important"
						>
							<v-list-item-title style="display: inline">{{
								item.name
							}}</v-list-item-title>
							<a
								v-if="item.link"
								:href="item.link"
								style="text-decoration: none"
								><v-icon color="#5e17eb" class="url-icon"
									>mdi-open-in-new</v-icon
								></a
							>
							<div
								style="
									font-size: 14px;
									color: #a7a7a7;
									white-space: pre-wrap;
								"
							>
								{{ item.description }}
							</div>
						</v-card>
					</div>
				</div>

				<!-- Attractions -->
				<div v-if="activeSidenav == 'attractions'" style="flex: 1">
					<div class="property-cards-wrap">
						<v-card
							v-for="(attraction, i) in booklet.attractions"
							v-bind:key="i"
							class="property-card"
							style="min-height: 100px !important"
						>
							<v-list-item-title style="display: inline">{{
								attraction.name
							}}</v-list-item-title>
							<a
								v-if="attraction.link"
								:href="attraction.link"
								style="text-decoration: none"
								><v-icon color="#5e17eb" class="url-icon"
									>mdi-open-in-new</v-icon
								></a
							>
							<div
								style="
									margin: 4px 0px;
									font-size: 14px;
									color: #a7a7a7;
								"
							>
								{{ attraction.distance }} miles
							</div>
							<img
								v-for="(icon, j) in attraction.icons"
								v-bind:key="j"
								:src="
									require(`../assets/icons/attractions/${icon}.png`)
								"
								class="attraction-icon"
							/>
							<div
								style="
									font-size: 14px;
									color: #a7a7a7;
									white-space: pre-wrap;
								"
							>
								{{ attraction.description }}
							</div>
						</v-card>
					</div>
				</div>

				<!-- Amenities -->
				<div v-if="activeSidenav == 'amenities'" style="flex: 1">
					<div class="property-cards-wrap">
						<v-card
							v-for="(amenity, i) in booklet.amenities"
							v-bind:key="i"
							class="property-card"
							style="min-height: 100px !important"
						>
							<v-list-item-title style="display: inline">{{
								amenity.name
							}}</v-list-item-title>
							<a
								v-if="amenity.link"
								:href="amenity.link"
								style="text-decoration: none"
								><v-icon color="#5e17eb" class="url-icon"
									>mdi-open-in-new</v-icon
								></a
							>
							<div
								style="
									font-size: 14px;
									color: #a7a7a7;
									white-space: pre-wrap;
								"
							>
								{{ amenity.description }}
							</div>
						</v-card>
					</div>
				</div>

				<!-- Excursions -->
				<div v-if="activeSidenav == 'excursions'" style="flex: 1">
					<div class="property-cards-wrap">
						<v-card
							v-for="(excursion, i) in booklet.excursions"
							v-bind:key="i"
							class="property-card"
							style="min-height: 100px !important"
						>
							<v-list-item-title style="display: inline">{{
								excursion.name
							}}</v-list-item-title>
							<a
								v-if="excursion.link"
								:href="excursion.link"
								style="text-decoration: none"
								><v-icon color="#5e17eb" class="url-icon"
									>mdi-open-in-new</v-icon
								></a
							>
							<!-- <div style="margin: 8px 0px"><a :href="excursion.link" style="text-decoration: none; color: rgb(94, 23, 235)">Visit Website</a></div> -->
							<div
								style="
									font-size: 14px;
									color: #a7a7a7;
									white-space: pre-wrap;
								"
							>
								{{ excursion.description }}
							</div>
						</v-card>
					</div>
				</div>

				<!-- Sites -->
				<div v-if="activeSidenav == 'sites'" style="flex: 1">
					<div class="property-cards-wrap">
						<v-card
							v-for="(site, i) in booklet.sites"
							v-bind:key="i"
							class="property-card"
							style="
								min-height: 100px !important;
								display: flex;
								gap: 16px;
							"
						>
							<img
								v-if="site.image"
								:src="site.image"
								height="100px"
								width="100px"
								class="card-image"
								@click="
									expand = !expand;
									expandedImage = site.image;
								"
							/>
							<div style="position: relative; flex: 1">
								<v-list-item-title style="display: inline">{{
									site.name
								}}</v-list-item-title>
								<div
									style="
										font-size: 14px;
										color: #a7a7a7;
										white-space: pre-wrap;
									"
								>
									{{ site.description }}
								</div>
								<a
									v-if="site.link"
									:href="site.link"
									style="
										text-decoration: none;
										position: absolute;
										right: 0px;
										top: 0px;
									"
									><v-icon color="#5e17eb" class="url-icon"
										>mdi-open-in-new</v-icon
									></a
								>
							</div>
						</v-card>
					</div>
				</div>

				<!-- Restaurants -->
				<div v-if="activeSidenav == 'restaurants'" style="flex: 1">
					<div class="property-cards-wrap">
						<v-card
							v-for="(restaurant, i) in booklet.restaurants"
							v-bind:key="i"
							class="property-card"
							style="min-height: 100px !important"
						>
							<v-list-item-title style="display: inline">{{
								restaurant.name
							}}</v-list-item-title>
							<a
								v-if="restaurant.link"
								:href="restaurant.link"
								style="text-decoration: none"
								><v-icon color="#5e17eb" class="url-icon"
									>mdi-open-in-new</v-icon
								></a
							>
							<div
								style="
									font-size: 14px;
									color: #a7a7a7;
									white-space: pre-wrap;
								"
							>
								{{ restaurant.description }}
							</div>
						</v-card>
					</div>
				</div>

				<!-- FAQs -->
				<div v-if="activeSidenav == 'faqs'" style="flex: 1">
					<div
						v-for="(question, i) in booklet.faqs"
						v-bind:key="i"
						@click="ShowFaqAnswer(i)"
						class="faq-bar"
						@mouseenter="FaqMouseover(i)"
						@mouseleave="faqHover = false"
					>
						<p class="faq-question">{{ question.question }}</p>
						<!-- <div v-if="faqIndex == i && faqHover" class="faq-icon-wrap"><v-icon color="#5E17EB" style="height: 14px; margin-right: 8px; line-height: 72px">mdi-image-multiple</v-icon>Photos</div> -->
						<v-icon
							color="#1D085A"
							style="
								float: right;
								margin-right: 16px;
								line-height: 72px;
							"
							v-if="selectedFaq == i"
							>mdi-chevron-up</v-icon
						>
						<v-icon
							color="#1D085A"
							style="
								float: right;
								margin-right: 16px;
								line-height: 72px;
							"
							v-else
							>mdi-chevron-down</v-icon
						>
						<p v-if="selectedFaq == i" class="faq-answer">
							{{ question.answer }}
						</p>
					</div>
				</div>

				<!-- Contact -->
				<div v-if="activeSidenav == 'contact'" style="flex: 1">
					<v-form ref="form" v-model="valid" lazy-validation>
						<h3 class="purple-title">Contact Us</h3>
						<p style="font-size: 14px">
							Have any questions or suggestions for us? We would
							love to hear from you.
						</p>
						<v-row>
							<v-col cols="12" sm="6">
								<vue-tel-input
									v-model="phone"
									color="#1D085A"
								></vue-tel-input>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field
									v-model="email"
									:rules="emailRules"
									@blur="checkEmail()"
									type="email"
									dense
									outlined
									color="#1D085A"
									label="Enter an email (optional)"
								></v-text-field>
							</v-col>
						</v-row>
						<v-textarea
							outlined
							label="Message"
							v-model="message"
							color="#1D085A"
							counter="true"
							:rules="[
								(v) =>
									(v || '').length <= 1000 ||
									'Message must be 1000 characters or less',
							]"
						></v-textarea>
						<ul v-if="contactErrors">
							<li
								v-for="(index, error) in contactErrors"
								:key="error"
								style="color: red"
							>
								{{ error }}
							</li>
						</ul>
						<v-btn
							dark
							color="#5e17eb"
							@click="SendContactRequest()"
							:disabled="!valid"
							>Send</v-btn
						>
					</v-form>
				</div>

				<!-- Overlay -->
				<v-overlay :value="expand">
					<img
						:src="expandedImage"
						class="card-image"
						@click="expand = !expand"
					/>
					<v-icon
						@click="expand = !expand"
						color="white"
						size="32px"
						style="
							position: absolute;
							top: 8px;
							right: 8px;
							border-radius: 50%;
						"
						class="close-icon"
						>mdi-close</v-icon
					>
				</v-overlay>
			</div>
		</div>
		<!-- </v-sheet> -->
	</v-app>
</template>

<script>
import { db, functions } from "../firebase";

export default {
	name: "GuestBooklet",
	metaInfo: {
		title: "Guest Booklet",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Welcome to the Dormi digital guest booklet. Find out nearby attractions, restaurants, etc. all recommended by your host.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {},
	data: () => ({
		currentPropertyId: "",
		currentProperty: "",
		adminId: "",
		booklet: "",
		items: [],
		drawer: false,
		activeTitle: "",
		activeTab: 0,

		amenities: [],
		attractions: [],
		questions: [],
		answers: [],
		icons: [],

		activeSidenav: "guide",
		attractionDialog: false,
		currentAttraction: "",
		editingAttraction: false,
		currentAttractionIndex: "",

		amenityDialog: false,
		editingAmenity: false,
		currentAmenity: "",

		faqIndex: null,
		selectedFaq: null,
		faqDialog: false,
		editingQuestion: false,
		faqHover: false,
		currentQuestion: "",

		qrcodeUrl: "",
		qrDownload: "",
		welcomeNote: "",
		propertyImg: "",

		phone: "",
		email: "",
		emailRules: [
			(v) =>
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
				"E-mail must be valid",
		],
		message: "",
		valid: false,
		contactErrors: [],
		expand: false,
		expandedImage: "",
	}),
	created() {
		this.adminId = this.$store.state.adminId;
		this.FindProperty();
		this.FindPropertyImage();
		this.InitializeIcons();
	},
	methods: {
		FindProperty: function () {
			this.currentPropertyId = this.$route.params.property_id;
			db.collection("booklets")
				.where("property_id", "==", this.currentPropertyId)
				.limit(1)
				.get()
				.then((query) => {
					if (query.empty) {
						console.log("Booklet doesn't exist yet");
						return;
					}
					var propRef = query.docs[0];
					this.booklet = propRef.data();
					this.welcomeNote = this.booklet.welcome;
					this.LoadSideNav();
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});
		},
		FindPropertyImage: function () {
			db.collection("properties")
				.doc(this.currentPropertyId)
				.get()
				.then((doc) => {
					this.propertyImg = doc.data().main_image;
				});
		},
		LoadSideNav: function () {
			if (this.booklet.guide.length) {
				this.items.push({
					title: "Guide",
					page: "guide",
					icon: "mdi-book-open-variant",
				});
			}
			if (this.booklet.amenities.length) {
				this.items.push({
					title: "Amenities",
					page: "amenities",
					icon: "mdi-home-heart",
				});
			}
			if (this.booklet.attractions.length) {
				this.items.push({
					title: "Attractions",
					page: "attractions",
					icon: "mdi-ferris-wheel",
				});
			}
			if (this.booklet.excursions.length) {
				this.items.push({
					title: "Excursions",
					page: "excursions",
					icon: "mdi-sail-boat",
				});
			}
			if (this.booklet.sites.length) {
				this.items.push({
					title: "History",
					page: "sites",
					icon: "mdi-magnify",
				});
			}
			if (this.booklet.restaurants.length) {
				this.items.push({
					title: "Restaurants",
					page: "restaurants",
					icon: "mdi-food",
				});
			}
			if (this.booklet.faqs.length) {
				this.items.push({
					title: "FAQ's",
					page: "faqs",
					icon: "mdi-help-circle",
				});
			}
			// if(this.booklet.contactActive){this.items.push({title: "Contact", page: "contact", icon: 'mdi-comment-text-outline'})}
			this.activeSidenav =
				JSON.parse(localStorage.getItem("property-sidenav")) || "guide";
			console.log(this.activeSidenav);
			for (var i = 0; i < this.items.length; i++) {
				if (this.activeSidenav == this.items[i].page) {
					this.activeTitle = this.items[i].title;
					this.activeTab = i;
				}
			}
		},
		InitializeIcons: function () {
			this.icons = [
				{ name: "Walking Distance", icon: "walk", selected: false },
				{ name: "Car Distance", icon: "car", selected: false },
				{ name: "Biking Distance", icon: "bike", selected: false },
				{ name: "Bus Distance", icon: "bus", selected: false },
				{ name: "Wifi", icon: "wifi", selected: false },
				{ name: "Expensive", icon: "expensive", selected: false },
				{ name: "Moderate Pricing", icon: "moderate", selected: false },
				{ name: "Affordable", icon: "affordable", selected: false },
				{ name: "Recreation", icon: "recreation", selected: false },
				{ name: "Indoors", icon: "indoors", selected: false },
				{ name: "Family Friendly", icon: "family", selected: false },
				{ name: "Pet Friendly", icon: "pet", selected: false },
			];
		},
		SideNavClicked: function (nav) {
			this.activeSidenav = nav.page;
			this.drawer = false;
			this.activeTitle = nav.title;
			localStorage.setItem("property-sidenav", JSON.stringify(nav.page));
		},

		// FAQ FUNCTIONS
		ShowFaqAnswer: function (i) {
			if (this.selectedFaq != i) {
				this.selectedFaq = i;
			} else {
				this.selectedFaq = null;
			}
		},
		FaqMouseover: function (i) {
			this.faqHover = true;
			this.faqIndex = i;
		},

		// CONTACT
		SendContactRequest() {
			if (this.phone == "") {
				this.contactErrors.push("Phone number is required");
				return;
			}
			if (this.message == "") {
				this.contactErrors.push("Email is required");
				return;
			}
			if (!this.email == "") {
				if (
					// eslint-disable-next-line no-useless-escape
					!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
						this.email
					)
				) {
					this.contactErrors.push("Email address is invalid");
					return;
				}
			}
			var sendBookletRequest =
				functions.httpsCallable("sendBookletRequest");
			sendBookletRequest({
				email: this.email,
				phone: this.phone,
				message: this.message,
			})
				.then(() => {
					alert(
						"Your request has been sent. Please await a response from your host by text."
					);
				})
				.catch((error) => {
					console.log("Error creating customer:", error);
				});
		},
	},
};
</script>

<style scoped>
/* .v-navigation-drawer--close.v-navigation-drawer--temporary {
    transform: translateX(0vw) !important;
} */
.page-title {
	color: #1d085a;
	font-size: 32px;
	font-weight: 500;
}
.booklets-container {
	margin-top: 52px;
	display: flex;
}
.booklet-box {
	max-height: 200px;
	max-width: 300px;
	padding: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.booklet-box:hover {
	background-color: #5e17eb10;
	cursor: pointer;
}
.booklet-title {
	color: #1d085a;
	font-size: 24px;
	padding-bottom: 12px;
}
.booklet-intro {
	color: #a7a7a7;
}
.template-container {
	text-align: center;
}
.top-nav {
	width: 100%;
}
.top-nav li {
	color: #ababab;
	list-style-type: none;
	padding: 16px;
	cursor: pointer;
	display: inline-block;
}
.active-sidenav {
	color: #1d085a !important;
}

.property-wrapper {
	width: 100%;
	height: 180px;
	background: rgb(196, 196, 196, 0.15);
	position: relative;
	border-bottom: 0.5px solid gray;
	overflow-x: scroll;
	overflow-y: hidden;
	cursor: pointer;
}
.property-cards-wrap {
	display: grid;
	grid-template-columns: 48% 48%;
	row-gap: 24px;
	justify-content: space-between;
	padding-left: 0px;
}
.add-property-card {
	color: gray;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.add-amenity-card {
	height: 80px;
}
.property-card {
	border-radius: 15px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
	cursor: pointer;
	min-height: 200px;
	padding: 16px;
}
.amenity-card {
	border-radius: 15px !important;
	box-shadow: none !important;
	min-height: 80px;
	padding: 0px;
	background-color: transparent !important;
	display: flex;
	width: 100%;
}
.amenity-edit-icon {
	float: right;
	margin-right: 8px;
	border-radius: 50px;
}
/* .property-card:hover, .add-amenity-card:hover{
        transform: scale(1.05);
    } */
.property-card-select {
	margin: 6px !important;
}
.v-card--link:before {
	background: white !important;
}
.attraction-icon {
	padding: 6px 16px;
	padding-left: 0px;
}
.attraction-items-wrap {
	display: grid;
	grid-template-columns: 31% 31% 31%;
	row-gap: 8px;
	justify-content: space-between;
	margin-bottom: 16px;
}
.attraction-items {
	height: 32px;
}
.faq-bar {
	border-bottom: 1px solid#1D085A;
	margin-left: 32px;
	cursor: pointer;
}
.faq-bar:hover {
	background-color: rgba(128, 128, 128, 0.103);
}
.faq-question {
	line-height: 72px;
	font-size: 18px;
	color: #1d085a;
	padding-left: 16px;
	display: inline-block;
	margin-right: 32px;
	margin-bottom: 0px;
}
.faq-answer {
	line-height: 18px;
	padding-left: 16px;
	color: gray;
	margin-bottom: 16px;
}
.faq-icon-wrap {
	display: inline-block;
	color: #5e17eb;
	margin-left: 32px;
	padding: 8px;
}
.faq-icon-wrap:hover {
	background-color: #5e17eb10;
}
.add-question {
	margin: 0 auto;
	text-align: center;
	margin-top: 32px;
	color: gray;
	width: 200px;
	padding: 16px;
}
.add-question:hover {
	background-color: rgba(128, 128, 128, 0.103);
	cursor: pointer;
}
#qrcode {
	width: 200px;
	height: 200px;
	margin-top: 32px;
	border: 1px solid black;
	border-radius: 12px;
	margin-bottom: 12px;
}
#canvas {
	display: block;
}
.url-icon {
	float: right;
}
.url-icon:hover {
	background-color: #5e17eb10;
}
.purple-title,
.purple-title2 {
	color: #1d085a;
	font-size: 20px;
	font-family: "cabin_title";
}
.card-image {
	border-radius: 6px !important;
}
@media screen and (max-width: 850px) {
	.attraction-items-wrap,
	.property-cards-wrap {
		grid-template-columns: 100%;
		padding: 0px;
	}
	.faq-bar {
		margin-left: 0px;
	}
	.main-container {
		padding: 0px 16px 16px 16px;
	}
}
</style>
