<template>
	<v-app>
		<v-dialog
			v-model="cleanersDialog"
			scrollable
			fullscreen
			transition="dialog-bottom-transition"
			persistent
			style="overflow-x: auto; background-color: #f9f9f9 !important"
		>
			<v-card class="dialog-card">
				<v-toolbar dark color="#8e24aa" style="flex: none">
					<v-btn icon dark @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>My Cleaners</v-toolbar-title>
					<!-- <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="SaveClicked()">Save</v-btn>
          </v-toolbar-items> -->
				</v-toolbar>
				<v-container>
					<p style="font-size: 24px; display: inline-block">
						Current Cleaners
					</p>
					<v-btn
						style="float: right; margin-top: 6px"
						@click="newCleanerClicked()"
						><v-icon color="purple">mdi-plus</v-icon>New
						Cleaner</v-btn
					>
					<v-simple-table v-if="myCleaners.length">
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Name</th>
									<th class="text-left">Email</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="cleaner in myCleaners"
									:key="cleaner.name"
									:class="{
										activeCleaner:
											cleaner == selectedCleaner,
									}"
									@click="CleanerSelected(cleaner)"
								>
									<td>{{ cleaner.name }}</td>
									<td>{{ cleaner.email }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<p v-else>
						You have no assigned cleaners. Please click 'NEW
						CLEANER' to add one.
					</p>
					<div v-if="newCleaner">
						<v-divider />
						<p style="font-size: 24px; margin-top: 32px">
							Add New Cleaners
						</p>
						<v-row>
							<!-- Cleaner Email -->
							<v-col cols="12" sm="10">
								<v-text-field
									dense
									label="New Cleaner Email"
									outlined
									placeholder="Ex) cleaner@gmail.com"
									class="property-name-field"
									v-model="cleanerEmail"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="2">
								<v-btn @click="FindCleaner()">Search</v-btn>
							</v-col>
						</v-row>
						<v-simple-table v-if="possibleVendors.length">
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">Name</th>
										<th class="text-left">Email</th>
										<th />
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="cleaner in possibleVendors"
										:key="cleaner.name"
										@click="NewCleaner(cleaner)"
									>
										<td>{{ cleaner.name }}</td>
										<td>{{ cleaner.email }}</td>
										<td>
											<v-icon
												color="purple"
												style="
													float: right;
													cursor: pointer;
												"
												>mdi-plus-circle</v-icon
											>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
					<div v-if="selectedCleaner">
						<v-divider />
						<p style="font-size: 24px; margin-top: 32px">
							What properties will
							{{ selectedCleaner.name }} clean?
						</p>
						<v-data-table
							v-model="selectedProperties"
							:headers="propertyHeaders"
							:items="properties"
							item-key="id"
							show-select
							class="elevation-1"
						>
							<template v-slot:[`footer.page-text`]>
								<v-btn
									color="purple"
									dark
									class="ma-2"
									style="color: white"
									@click="SaveCleanerProperties()"
								>
									Save
								</v-btn>
							</template>
						</v-data-table>
					</div>
				</v-container>
				<v-divider></v-divider>
				<v-snackbar
					v-model="snackbar"
					color="success"
					:timeout="timeout"
					>{{ snackText }}</v-snackbar
				>
				<v-card-actions>
					<v-spacer />
					<v-btn color="purple darken-1" text @click="closeDialog"
						>Close</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import { db } from "../firebase";
import { mapState } from "vuex";

export default {
	props: {
		cleanersDialog: Boolean,
	},
	data() {
		return {
			valid: false,
			cleanerEmail: "",
			newCleaner: false,
			selectedCleaner: null,
			myCleaners: [],
			cleanerIds: [],
			newVendors: [],
			possibleVendors: [],
			selectedProperties: [],
			propertyHeaders: [
				{
					text: "Property Name",
					align: "start",
					sortable: true,
					value: "name",
				},
				{ text: "Address", value: "address" },
				{ text: "City", value: "city" },
				{ text: "State", value: "state" },
			],
			snackbar: false,
			snackText: "Properties saved to cleaner.",
			timeout: 5000,
		};
	},
	created() {
		this.loadCleaners();
	},
	computed: {
		...mapState("adminUser", ["adminId", "properties"]),
	},
	methods: {
		closeDialog() {
			this.$emit("cleanersdialogclosed");
		},
		loadCleaners: function () {
			// users_admin.vendors = []
			// search db for each vendor doc
			db.collection("users_admin")
				.doc(this.adminId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						if (doc.data().cleaners.length) {
							this.cleanerIds = doc.data().cleaners;
						} else {
							this.cleanerIds = [];
						}
						this.getCleanerInfo();
					} else {
						console.log("No such document!");
					}
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});
			// this.myCleaners = [{id: "dG9RYg2cotd1EX7cJfyAxUjz4sH3", name: "Cleaner 1", email: "cleaner1@gmail.com"}, {id: "f6EMqGy9hmUSohiGbS3fzuGtKAV2", name: "Cleaner 2", email: "cleaner2@gmail.com"}]
		},
		getCleanerInfo: function () {
			console.log("ids", this.cleanerIds);

			for (var i = 0; i < this.cleanerIds.length; i++) {
				db.collection("users_vendor")
					.doc(this.cleanerIds[i])
					.get()
					.then((doc) => {
						if (doc.exists) {
							var cleaner = doc.data();
							cleaner.id = doc.id;
							this.myCleaners.push(cleaner);
						} else {
							console.log("No such document!");
						}
					})
					.catch((error) => {
						console.log("Error getting document:", error);
					});
			}
		},
		FindCleaner: async function () {
			db.collection("users_vendor")
				.where("email", "==", this.cleanerEmail)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots
						console.log(doc.id, " => ", doc.data());
						var cleaner = doc.data();
						cleaner.id = doc.id;
						this.possibleVendors.push(cleaner);
					});
				})
				.catch((error) => {
					console.log("Error getting documents: ", error);
				});
		},
		newCleanerClicked: function () {
			console.log(this.myCleaners);
			this.newCleaner = true;
			this.selectedCleaner = false;
		},
		NewCleaner: function (cleaner) {
			for (var i = 0; i < this.myCleaners.length; i++) {
				if (this.myCleaners[i].id == cleaner.id) {
					alert("You already have this cleaner");
					return;
				}
			}
			this.cleanerIds.push(cleaner.id);
			this.myCleaners.push(cleaner);
			db.collection("users_admin")
				.doc(this.adminId)
				.set(
					{
						cleaners: this.cleanerIds,
					},
					{ merge: true }
				)
				.then(() => {
					this.selectedCleaner = cleaner;
					this.newCleaner = false;
					this.cleanerEmail = false;
				})
				.catch((err) => {
					console.log("error adding cleaner", err);
				});
		},
		CleanerSelected: function (cleaner) {
			this.newCleaner = false;
			this.selectedCleaner = cleaner;
			this.selectedProperties = [];
			// eslint-disable-next-line no-prototype-builtins
			if (this.selectedCleaner.hasOwnProperty("properties")) {
				for (
					let i = 0;
					i < this.selectedCleaner.properties.length;
					i++
				) {
					for (let j = 0; j < this.properties.length; j++) {
						if (
							this.selectedCleaner.properties[i] ==
							this.properties[j].id
						) {
							this.selectedProperties.push(this.properties[j]);
						}
					}
				}
			} else {
				this.selectedCleaner.properties = [];
			}
			console.log("selected", this.selectedProperties);
		},
		SaveCleanerProperties: function () {
			console.log(
				"saving selected properties",
				this.selectedProperties.length
			);
			var selectedIds = [];
			for (let i = 0; i < this.selectedProperties.length; i++) {
				selectedIds.push(this.selectedProperties[i].id);
				if (this.selectedCleaner.properties.length > 0) {
					if (
						!this.selectedCleaner.properties.includes(
							this.selectedProperties[i].id
						)
					) {
						console.log("Item doesn't exists in array");
						this.selectedCleaner.properties.push(
							this.selectedProperties[i].id
						);
					}
				} else {
					this.selectedCleaner.properties.push(
						this.selectedProperties[i].id
					);
				}
			}
			console.log("before", selectedIds);

			for (let k = 0; k < this.properties.length; k++) {
				if (
					this.selectedCleaner.properties.includes(
						this.properties[k].id
					) &&
					!selectedIds.includes(this.properties[k].id)
				) {
					var index = this.selectedCleaner.properties.indexOf(
						this.properties[k].id
					);
					if (index !== -1) {
						this.selectedCleaner.properties.splice(index, 1);
					}
				}
			}
			console.log("vendor", this.selectedCleaner);
			db.collection("users_vendor")
				.doc(this.selectedCleaner.id)
				.set(
					{
						properties: this.selectedCleaner.properties,
					},
					{ merge: true }
				)
				.then(() => {
					console.log("vendor updated successfully");
					this.snackbar = true;
				})
				.catch((err) => {
					console.log("error updating cleaner", err);
				});
		},
	},
};
</script>

<style scoped>
tr {
	cursor: pointer;
}
.activeCleaner {
	background-color: rgba(142, 36, 170, 0.3);
}
</style>
