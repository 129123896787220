<template>
	<div>
		<v-checkbox
			style="margin-right: 6px; align-self: flex-start; margin-top: 0px"
			color="#5e17eb"
			@change="$emit('AppendSelectedTask', task, category)"
			hide-details
		/>
		<div
			class="checklist-box-text"
			@click.stop="$emit('TaskSelected', task, category, index)"
		>
			{{ task.name }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		task: Object,
		category: Object,
		index: Number,
	},
	data() {
		return {};
	},
};
</script>

<style scoped>
.checklist-box {
	height: 68px;
	line-height: 68px;
	vertical-align: middle;
	width: 100%;
	border-bottom: 0.5px solid #8c8c8c79;
	position: relative;
	display: table;
	padding-left: 0px;
}
.checklist-box-text {
	display: inline-block;
	color: #a7a7a7;
	font-size: 16px;
	flex-wrap: wrap;
	margin-right: 10px;
	margin-top: 4px;
}
</style>
