<template>
	<div>
		<div>
			<div
				v-for="(question, i) in booklet.faqs"
				v-bind:key="i"
				@click="ShowFaqAnswer(i)"
				class="faq-bar"
				@mouseenter="FaqMouseover(i)"
				@mouseleave="faqHover = false"
			>
				<p class="faq-question">{{ question.question }}</p>
				<div
					v-if="faqIndex == i && faqHover"
					class="faq-icon-wrap"
					@click.stop="EditFaq(question, i)"
				>
					<v-icon
						color="#5E17EB"
						style="
							height: 14px;
							margin-right: 8px;
							line-height: 72px;
						"
						>mdi-pencil-box-multiple-outline</v-icon
					>Edit
				</div>
				<!-- <div v-if="faqIndex == i && faqHover" class="faq-icon-wrap"><v-icon color="#5E17EB" style="height: 14px; margin-right: 8px; line-height: 72px">mdi-image-multiple</v-icon>Photos</div> -->
				<div
					v-if="faqIndex == i && faqHover"
					class="faq-icon-wrap"
					@click.stop="
						currentQuestion = question;
						DeleteFaq();
					"
				>
					<v-icon
						color="#5E17EB"
						style="
							height: 14px;
							margin-right: 8px;
							margin-bottom: 4px;
							line-height: 72px;
						"
						>mdi-trash-can</v-icon
					>Delete
				</div>

				<v-icon
					color="#1D085A"
					style="float: right; margin-right: 16px; line-height: 72px"
					v-if="selectedFaq == i"
					>mdi-chevron-up</v-icon
				>
				<v-icon
					color="#1D085A"
					style="float: right; margin-right: 16px; line-height: 72px"
					v-else
					>mdi-chevron-down</v-icon
				>
				<p v-if="selectedFaq == i" class="faq-answer">
					{{ question.answer }}
				</p>
			</div>
			<div class="add-question" @click="NewFaq()">
				<v-icon color="gray" style="margin-right: 16px">mdi-plus</v-icon
				>Add Question
			</div>
		</div>

		<!-- Faq Dialog -->
		<v-dialog
			v-model="faqDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card class="dialog-card">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="faqDialog = false"
				>
					X
				</div>
				<div class="purple-title2" style="margin-top: 0px">New FAQ</div>
				<v-text-field
					outlined
					color="#1D085A"
					label="Question"
					v-model="currentQuestion.question"
				></v-text-field>
				<v-text-field
					outlined
					color="#1D085A"
					label="Answer"
					v-model="currentQuestion.answer"
				></v-text-field>

				<v-row align="center" style="padding: 0px 16px">
					<v-btn
						v-if="!editingQuestion"
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="AddFaq()"
						><v-icon color="white" style="margin-right: 16px"
							>mdi-plus</v-icon
						>Add</v-btn
					>
					<v-btn
						v-else
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="DoneEditingFaq()"
						>Done</v-btn
					>
					<v-btn light style="width: fit" @click="faqDialog = false"
						>Cancel</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						v-if="editingQuestion"
						color="error"
						dark
						@click="DeleteFaq()"
						>Delete</v-btn
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase } from "../../firebase";

export default {
	props: {
		booklet: Object,
		bookletId: String,
	},
	data() {
		return {
			faqIndex: null,
			selectedFaq: null,
			faqDialog: false,
			editingQuestion: false,
			faqHover: false,
			currentQuestion: "",
			valid: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		// FAQ FUNCTIONS
		ShowFaqAnswer: function (i) {
			if (this.selectedFaq != i) {
				this.selectedFaq = i;
			} else {
				this.selectedFaq = null;
			}
		},
		FaqMouseover: function (i) {
			this.faqHover = true;
			this.faqIndex = i;
		},
		NewFaq: function () {
			this.faqDialog = true;
			this.editingQuestion = false;
			this.currentQuestion = { question: "", answer: "" };
		},
		AddFaq: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					faqs: firebase.firestore.FieldValue.arrayUnion(
						this.currentQuestion
					),
				})
				.then(() => {
					console.log("Document updated");
					this.faqDialog = false;
					this.faqIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		EditFaq: function (question, index) {
			this.faqDialog = true;
			this.editingQuestion = true;
			this.currentQuestion = question;
			this.faqIndex = index;
		},
		DoneEditingFaq: function () {
			this.booklet.faqs[this.faqIndex] = this.currentQuestion;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					faqs: this.booklet.faqs,
				})
				.then(() => {
					console.log("Document updated");
					this.faqDialog = false;
					this.faqIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		DeleteFaq: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					faqs: firebase.firestore.FieldValue.arrayRemove(
						this.currentQuestion
					),
				})
				.then(() => {
					console.log("Document deleted");
					this.faqDialog = false;
					this.faqIndex = "";
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
	},
};
</script>

<style scoped>
.faq-bar {
	border-bottom: 1px solid#1D085A;
	margin-left: 32px;
	cursor: pointer;
}
.faq-bar:hover {
	background-color: rgba(128, 128, 128, 0.103);
}
.faq-question {
	line-height: 72px;
	font-size: 18px;
	color: #1d085a;
	padding-left: 16px;
	display: inline-block;
	margin-right: 32px;
	margin-bottom: 0px;
}
.faq-answer {
	line-height: 18px;
	padding-left: 16px;
	color: gray;
	margin-bottom: 16px;
}
.faq-icon-wrap {
	display: inline-block;
	color: #5e17eb;
	margin-left: 32px;
	padding: 8px;
}
.faq-icon-wrap:hover {
	background-color: #5e17eb10;
}
.add-question {
	margin: 0 auto;
	text-align: center;
	margin-top: 32px;
	color: gray;
	width: 200px;
	padding: 16px;
}
.add-question:hover {
	background-color: rgba(128, 128, 128, 0.103);
	cursor: pointer;
}
</style>
