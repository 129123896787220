<template>
	<div>
		<v-textarea
			outlined
			auto-grow
			background-color="white"
			color="#1D085A"
			label="Guest Welcome Note"
			v-model="welcomeNote"
			class="welcome-note"
			@blur="SaveWelcomeNote()"
		></v-textarea>
		<div class="property-cards-wrap">
			<v-card
				v-for="(item, i) in booklet.guide"
				v-bind:key="i"
				class="property-card"
				@click="EditGuideItem(item)"
				style="min-height: 100px !important"
			>
				<v-list-item-title style="display: inline">{{
					item.name
				}}</v-list-item-title>
				<div
					style="
						font-size: 14px;
						color: #a7a7a7;
						white-space: pre-wrap;
					"
				>
					{{ item.description }}
				</div>
			</v-card>
			<v-card
				class="property-card"
				style="background-color: #f4f4f4; min-height: 100px !important"
				@click="NewGuideItem()"
			>
				<div class="add-property-card">
					<v-icon color="gray">mdi-plus</v-icon> New Item
				</div>
			</v-card>
		</div>

		<!-- Guide Dialog -->
		<v-dialog
			v-model="guideDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card class="dialog-card">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="guideDialog = false"
				>
					X
				</div>
				<div
					v-if="editingGuide"
					class="purple-title2"
					style="margin-top: 0px"
				>
					{{ currentGuideItem.name }}
				</div>
				<div v-else class="purple-title2" style="margin-top: 0px">
					New Home Guide Instruction
				</div>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						outlined
						color="#1D085A"
						label="Name"
						v-model="currentGuideItem.name"
					></v-text-field>
					<v-text-field
						outlined
						color="#1D085A"
						placeholder="Include https://"
						label="Website URL"
						:error-messages="urlErrors"
						v-model="currentGuideItem.link"
					></v-text-field>
					<v-textarea
						outlined
						color="#1D085A"
						label="Description"
						v-model="currentGuideItem.description"
					></v-textarea>
				</v-form>

				<v-row align="center" style="padding: 0px 16px">
					<v-btn
						v-if="!editingGuide"
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="AddGuideItem()"
						><v-icon color="white" style="margin-right: 16px"
							>mdi-plus</v-icon
						>Add</v-btn
					>
					<v-btn
						v-else
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="DoneEditingGuideItem()"
						>Done</v-btn
					>
					<v-btn light style="width: fit" @click="CancelGuideItem()"
						>Cancel</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						v-if="editingGuide"
						color="error"
						dark
						@click="DeleteGuideItem()"
						>Delete</v-btn
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase } from "../../firebase";

export default {
	props: {
		booklet: Object,
		bookletId: String,
	},
	data() {
		return {
			guideDialog: false,
			editingGuide: false,
			currentGuideItem: "",
			currentGuideIndex: "",
			welcomeNote: this.booklet.welcome,
			valid: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		// HOME GUIDE FUNCTIONS
		SaveWelcomeNote: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					welcome: this.welcomeNote,
				})
				.then(() => {
					console.log("Document updated");
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		NewGuideItem: function () {
			this.guideDialog = true;
			this.editingGuide = false;
			this.currentGuideItem = { name: "", description: "" };
		},
		AddGuideItem: function () {
			console.log("test", this.currentGuideItem);
			if (this.isBadUrl(this.currentGuideItem.link)) {
				console.log("bad url");
				return false;
			}
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					guide: firebase.firestore.FieldValue.arrayUnion(
						this.currentGuideItem
					),
				})
				.then(() => {
					console.log("Document updated");
					this.guideDialog = false;
					this.guideIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		EditGuideItem: function (item, index) {
			this.guideDialog = true;
			this.editingGuide = true;
			this.currentGuideItem = item;
			this.guideIndex = index;
		},
		CancelGuideItem: function () {
			this.guideDialog = false;
		},
		DoneEditingGuideItem: function () {
			if (this.isBadUrl(this.currentGuideItem.link)) {
				console.log("bad url");
				return false;
			}
			this.booklet.guide[this.guideIndex] = this.currentGuideItem;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					guide: this.booklet.guide,
				})
				.then(() => {
					console.log("Document updated");
					this.guideDialog = false;
					this.guideIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		DeleteGuideItem: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					guide: firebase.firestore.FieldValue.arrayRemove(
						this.currentGuideItem
					),
				})
				.then(() => {
					console.log("Document deleted");
					this.guideDialog = false;
					this.guideIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
	},
};
</script>

<style scoped>
.welcome-note {
	padding-left: 32px !important;
}
</style>
