<template>
	<div>
		<div>
			<div class="property-cards-wrap">
				<v-card
					v-for="(excursion, i) in booklet.excursions"
					v-bind:key="i"
					class="property-card"
					@click="EditExcursion(excursion)"
					style="min-height: 100px !important"
				>
					<v-list-item-title style="display: inline">{{
						excursion.name
					}}</v-list-item-title>
					<!-- <div style="margin: 8px 0px"><a :href="excursion.link" style="text-decoration: none; color: rgb(94, 23, 235)">Visit Website</a></div> -->
					<div
						style="
							font-size: 14px;
							color: #a7a7a7;
							white-space: pre-wrap;
						"
					>
						{{ excursion.description }}
					</div>
				</v-card>
				<v-card
					class="property-card"
					style="
						background-color: #f4f4f4;
						min-height: 100px !important;
					"
					@click="NewExcursion()"
				>
					<div class="add-property-card">
						<v-icon color="gray">mdi-plus</v-icon> New Excursion
					</div>
				</v-card>
			</div>
		</div>

		<!-- Excursion Dialog -->
		<v-dialog
			v-model="excursionDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card class="dialog-card">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="excursionDialog = false"
				>
					X
				</div>
				<div class="purple-title2" style="margin-top: 0px">
					New Excursion
				</div>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						outlined
						color="#1D085A"
						label="Excursion Name"
						v-model="currentExcursion.name"
					></v-text-field>
					<v-text-field
						outlined
						color="#1D085A"
						placeholder="Include https://"
						label="Website URL"
						:error-messages="urlErrors"
						v-model="currentExcursion.link"
					></v-text-field>
					<v-textarea
						color="#1D085A"
						outlined
						label="Short Description"
						v-model="currentExcursion.description"
					></v-textarea>
				</v-form>
				<v-row align="center" style="padding: 0px 16px">
					<v-btn
						v-if="!editingExcursion"
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="AddExcursion()"
						><v-icon color="white" style="margin-right: 16px"
							>mdi-plus</v-icon
						>Add</v-btn
					>
					<v-btn
						v-else
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="DoneEditingExcursion()"
						>Done</v-btn
					>
					<v-btn
						light
						style="width: fit"
						@click="excursionDialog = false"
						>Cancel</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						v-if="editingExcursion"
						color="error"
						dark
						@click="DeleteExcursion()"
						>Delete</v-btn
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase } from "../../firebase";

export default {
	props: {
		booklet: Object,
		bookletId: String,
	},
	data() {
		return {
			currentExcursion: "",
			currentExcursionIndex: "",
			editingExcursion: false,
			excursionDialog: false,
			valid: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		// EXCURSION FUNCTIONS
		NewExcursion: function () {
			this.excursionDialog = true;
			this.editingExcursion = false;
			this.currentExcursion = {
				name: "",
				distance: "",
				description: "",
			};
		},
		AddExcursion: function () {
			if (this.isBadUrl(this.currentExcursion.link)) {
				console.log("bad url");
				return false;
			}
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					excursions: firebase.firestore.FieldValue.arrayUnion(
						this.currentExcursion
					),
				})
				.then(() => {
					console.log("Document updated");
					this.excursionDialog = false;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		EditExcursion: function (excursion, index) {
			this.excursionDialog = true;
			this.editingExcursion = true;
			this.currentExcursion = excursion;
			this.currentExcursionIndex = index;
		},
		DoneEditingExcursion: function () {
			if (this.isBadUrl(this.currentExcursion.link)) {
				console.log("bad url");
				return false;
			}
			this.booklet.excursions[this.currentExcursionIndex] =
				this.currentExcursion;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					excursions: this.booklet.excursions,
				})
				.then(() => {
					console.log("Document updated");
					this.excursionDialog = false;
					this.currentExcursionIndex = "";
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		DeleteExcursion: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					excursions: firebase.firestore.FieldValue.arrayRemove(
						this.currentExcursion
					),
				})
				.then(() => {
					console.log("Document deleted");
					this.excursionDialog = false;
					this.currentExcursionIndex = "";
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
	},
};
</script>

<style scoped></style>
