/* eslint-disable no-undef */
import { db, auth } from "@/firebase";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import adminUserApi from "./adminUser.api";
import eventsApi from "../events/events.api";
import router from "@/router";
import { doc, writeBatch } from "firebase/firestore";

function initialState() {
	return {
		properties: [],
		vendors: [],
		events: [],
		propertyEvents: [],
		currentPropertyId: "",
		storedCurrentPropertyName: "",
		snackbarMessage: "",
		adminId: "",
		user: {},
		error: null,
		propertyListView: "",
		propertyChecklistView: "",
	};
}

const adminUser = {
	namespaced: true,
	state: initialState,

	getters: {
		getProperties(state) {
			return state.properties;
		},
		getPropertyNames(state) {
			return state.properties.map((property) => property.name);
		},
		getCleaners(state) {
			return state.cleaners;
		},
		getEventsForUser(state) {
			return state.events;
		},
		isAuthenticated: (state) => state.adminId !== null,
		getUser(state) {
			return state.adminId;
		},
		getUserData(state) {
			return state.user;
		},
		isUserAuth(state) {
			return !!state.user;
		},
		getError(state) {
			return state.error;
		},
		getPropertyListView(state) {
			if (state.user && "propertyListView" in state.user) {
				return state.user.propertyListView;
			}
			if (state.propertyListView !== "") {
				return state.propertyListView;
			}
			return "card";
		},
		getPropertyChecklistView(state) {
			if (state.user && "propertyChecklistView" in state.user) {
				return state.user.propertyChecklistView;
			}
			if (state.propertyChecklistView !== "") {
				return state.propertyChecklistView;
			}
			return "card";
		},
	},

	mutations: {
		initializeStore(state) {
			const adminId = localStorage.getItem("adminId");
			if (adminId) {
				state.adminId = adminId;
			}
			const storedCurrentPropertyName = localStorage.getItem(
				"storedCurrentPropertyName"
			);
			if (storedCurrentPropertyName) {
				state.storedCurrentPropertyName = storedCurrentPropertyName;
			}
			const storedPropertyListView = localStorage.getItem(
				"storedPropertyListView"
			);
			if (storedPropertyListView) {
				state.propertyListView = storedPropertyListView;
			}
			const storedPropertyChecklistView = localStorage.getItem(
				"storedPropertyChecklistView"
			);
			if (storedPropertyChecklistView) {
				state.propertyChecklistView = storedPropertyChecklistView;
			}
		},
		addEventsToPropertyList(state) {
			let events = [];
			if (state.properties.length > 0) {
				state.properties.forEach((property) => {
					property.events.forEach((event) => {
						events.push(event);
					});
				});
				state.propertyEvents = events;
			}
		},
		setUser(state, payload) {
			localStorage.setItem("adminId", payload.uid);
			state.adminId = payload.uid;
			console.log("user set to", state.adminId);
		},
		logoutAdminUser(state) {
			state.adminId = null;
			localStorage.setItem("adminId", null);
			localStorage.setItem("storedCurrentPropertyName", null);
			auth.signOut()
				.then(() => {
					console.log("User signed out successfully");
				})
				.catch((error) => {
					console.log(
						"Oops, something happened while logging out.",
						error
					);
				});
		},
		setCurrentProperty(state, property) {
			localStorage.setItem("storedCurrentPropertyName", property.name);
			state.storedCurrentPropertyName = property.name;
		},
		setError(state, payload) {
			state.error = payload;
		},
		updateSnackbar(state, message) {
			state.snackbarMessage = message;
		},
		setUserLocalStorage(state) {
			if (state.user && "propertyListView" in state.user) {
				localStorage.setItem(
					"storedPropertyListView",
					state.user.propertyListView
				);
				state.propertyListView = state.user.propertyListView;
			}
			if (state.user && "propertyChecklistView" in state.user) {
				localStorage.setItem(
					"storedPropertyChecklistView",
					state.user.propertyChecklistView
				);
				state.propertyChecklistView = state.user.propertyChecklistView;
			}
		},
	},

	actions: {
		bindProperties: firestoreAction((context) => {
			return context.bindFirestoreRef(
				"properties",
				db
					.collection("properties")
					.where("admin_id", "==", context.state.adminId)
			);
		}),
		bindUser: firestoreAction((context) => {
			return context.bindFirestoreRef(
				"user",
				db.collection("users_admin").doc(context.state.adminId)
			);
		}),
		async createNewProperty({ state }, newProperty) {
			newProperty.admin_id = state.adminId;
			newProperty.sort = state.properties.length;
			await adminUserApi.createNewProperty(newProperty);
		},
		async saveUpdatedProperty({ commit }, editedProperty) {
			let res = await adminUserApi.saveEditedProperty(editedProperty);
			console.log(res.status, res.message);
			if (res.status == 201) {
				commit("updateSnackbar", res.message);
			} else {
				commit("updateSnackbar", res.message);
			}
		},
		async deleteProperty({ commit }, propertyToDelete) {
			let res = await adminUserApi.deleteProperty(propertyToDelete.id);
			console.log(res.status, res.message);
			if (res.status == 202) {
				commit("updateSnackbar", res.message);
			} else {
				commit("updateSnackbar", res.message);
			}
		},
		// Events
		bindEvents: firestoreAction((context) => {
			// db.collection('events').where("admin_id", "==", context.state.adminId).get().then((snapshot)=>{
			//   var events = []
			//   snapshot.forEach((doc)=>{
			//     var event = doc.data()
			//     event.start = event.start.toDate();
			//     if(event.end){
			//       event.end = event.end.toDate();
			//     }
			//     events.push(event)
			//   })
			//   console.log("events", events)
			//   return context.bindFirestoreRef('events', events);
			// })
			console.log("state", context.state);
			return context.bindFirestoreRef(
				"events",
				db
					.collection("events")
					.where("admin_id", "==", context.state.adminId)
			);
		}),
		async createNewEvent({ state }, event) {
			event.admin_id = state.adminId;
			await eventsApi.addNewEvent(event);
		},
		async saveUpdatedEvent({ commit }, editedEvent) {
			let res = await eventsApi.saveEditedEvent(editedEvent);
			console.log(res.status, res.message);
			if (res.status == 202) {
				commit("updateSnackbar", res.message);
			} else {
				commit("updateSnackbar", res.message);
			}
		},
		async deleteEvent({ commit }, eventToDelete) {
			let res = await eventsApi.deleteEvent(eventToDelete.eventId);
			console.log(res.status, res.message);
			if (res.status == 202) {
				commit("updateSnackbar", res.message);
			} else {
				commit("updateSnackbar", res.message);
			}
		},
		updatePropertyEvents({ commit }) {
			commit("addEventsToPropertyList");
		},
		setCurrentlySelectedProperty({ commit }, property) {
			commit("setCurrentProperty", property);
			router.push({ name: "Property" });
		},
		logoutAdminUserAction({ commit }) {
			commit("logoutAdminUser");
			router.push({ name: "Login" });
		},
		newUser({ commit }, payload) {
			return new Promise((resolve, reject) => {
				var usersRef = db.collection("users_admin").doc(payload.uid);
				usersRef
					.get()
					.then((docSnapshot) => {
						if (docSnapshot.exists) {
							usersRef.onSnapshot((doc) => {
								console.log("doc exists", doc);
							});
						} else {
							usersRef.set({
								email: payload.email,
								name: payload.name,
							}); // create the document
						}
						commit("setUser", payload);
						router.push({ name: "Properties" });
						resolve();
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		loginAdminUser({ commit }, payload) {
			return new Promise((resolve, reject) => {
				firebase
					.auth()
					.signInWithEmailAndPassword(payload.email, payload.password)
					.then((response) => {
						commit("setUser", response.user);
						router.push({ name: "Properties" });
						resolve();
					})
					.catch((error) => {
						commit("setError", error.message);
						reject(error);
					});
			});
		},
		loginGoogleAdminUser({ commit }, payload) {
			commit("setUser", payload);
		},
		savePropertyListView(context, view) {
			db.collection("users_admin")
				.doc(firebase.auth().currentUser.uid)
				.update({
					propertyListView: view === "list" ? view : "card",
				});
			context.commit("setUserLocalStorage");
		},
		savePropertyChecklistListView(context, view) {
			db.collection("users_admin")
				.doc(firebase.auth().currentUser.uid)
				.update({
					propertyChecklistView: view === "list" ? view : "card",
				});
			context.commit("setUserLocalStorage");
		},
		async savePropertySort(context, properties) {
			const batch = writeBatch(db);

			for (let i = 0; i < properties.length; i++) {
				let propertyRef = doc(db, "properties", properties[i].id);
				batch.update(propertyRef, { sort: properties[i].sort });
			}

			return await batch.commit();
		},
	},
};

export default adminUser;
