import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, {
	theme: {
		primary: "#3f51b5",
		secondary: "#b0bec5",
		accent: "#8c9eff",
		error: "#ff00ff",
	},
});

export default new Vuetify({});
