<template>
	<div class="side-nav">
		<li
			:class="{ 'active-sidenav': activeSidenav == 'guide' }"
			@click="SideNavClicked('guide')"
		>
			Guide
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'amenities' }"
			@click="SideNavClicked('amenities')"
		>
			Amenities
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'attractions' }"
			@click="SideNavClicked('attractions')"
		>
			Attractions
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'excursions' }"
			@click="SideNavClicked('excursions')"
		>
			Excursions
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'history' }"
			@click="SideNavClicked('history')"
		>
			History
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'restaurants' }"
			@click="SideNavClicked('restaurants')"
		>
			Restaurants
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'faqs' }"
			@click="SideNavClicked('faqs')"
		>
			FAQs
		</li>
		<li
			:class="{ 'active-sidenav': activeSidenav == 'qr' }"
			@click="SideNavClicked('qr')"
		>
			QR Code
		</li>
		<!-- <li :class="{'active-sidenav': activeSidenav=='contact'}" @click="SideNavClicked('contact')">Contact</li> -->
	</div>
</template>

<script>
export default {
	props: {
		sidenav: String,
	},
	data() {
		return {
			activeSidenav: "",
		};
	},
	created() {
		this.activeSidenav = this.sidenav;
		console.log("sidenav", this.activeSidenav);
	},
	methods: {
		SideNavClicked(nav) {
			this.activeSidenav = nav;
			this.$emit("SideNavClicked", this.activeSidenav);
		},
	},
};
</script>

<style scoped>
.side-nav {
	width: 120px;
}
.side-nav li {
	color: #ababab;
	list-style-type: none;
	padding-bottom: 32px;
	cursor: pointer;
}
.side-nav li:hover {
	color: #5f5f5f;
}
.active-sidenav {
	color: #1d085a !important;
}
</style>
