<template>
	<v-row justify="center">
		<v-dialog
			v-model="editEventDialog"
			persistent
			max-width="700px"
			class="big"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-icon v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
			</template>
			<v-card>
				<v-card-title>
					<span class="headline">Edit event</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<v-row>
									<v-col sm="12">
										<v-date-picker
											class="picker"
											v-model="picker"
											color="#4c2997"
											range
										></v-date-picker>
									</v-col>
									<v-col v-if="false">
										<h4>Start Time:</h4>
										<vue-time-picker
											input-width="125px"
											class="timePicker"
											v-model="startTime"
										></vue-time-picker>
									</v-col>
									<v-col v-if="false">
										<h4>End Time:</h4>
										<vue-time-picker
											input-width="125px"
											class="timePicker"
											v-model="endTime"
										></vue-time-picker>
									</v-col>
								</v-row>
							</v-col>
							<v-col>
								<v-row>
									<v-col>
										<v-text-field
											label="Start Time"
											color="#1D085A"
											class="timeBox"
											dense
											outlined
											v-model="convertedStart"
											@click="
												startTimeDialogEdit =
													!startTimeDialogEdit
											"
										></v-text-field>
										<v-dialog
											v-model="startTimeDialogEdit"
											width="unset"
										>
											<v-card
												style="
													width: 290px;
													margin: 0 auto;
												"
											>
												<v-time-picker
													format="ampm"
													scrollable
													color="#5E17EB"
													v-model="startTime"
												></v-time-picker>
												<v-card-actions>
													<v-spacer />
													<v-btn
														color="#5E17EB"
														text
														@click="
															startTimeDialogEdit =
																!startTimeDialogEdit;
															startTime = '';
														"
													>
														Clear
													</v-btn>
													<v-btn
														color="#5E17EB"
														text
														@click="saveStartTime()"
													>
														Okay
													</v-btn>
												</v-card-actions>
											</v-card>
										</v-dialog>
									</v-col>
									<v-col>
										<v-text-field
											label="End Time"
											color="#1D085A"
											class="timeBox"
											dense
											outlined
											v-model="convertedEnd"
											@click="
												endTimeDialogEdit =
													!endTimeDialogEdit
											"
										></v-text-field>
										<v-dialog
											v-model="endTimeDialogEdit"
											width="unset"
										>
											<v-card
												style="
													width: 290px;
													margin: 0 auto;
												"
											>
												<v-time-picker
													format="ampm"
													scrollable
													color="#5E17EB"
													v-model="endTime"
												></v-time-picker>
												<v-card-actions>
													<v-spacer />
													<v-btn
														color="#5E17EB"
														text
														@click="
															endTimeDialogEdit =
																!endTimeDialogEdit;
															endTime = '';
														"
													>
														Clear
													</v-btn>
													<v-btn
														color="#5E17EB"
														text
														@click="saveEndTime()"
													>
														Okay
													</v-btn>
												</v-card-actions>
											</v-card>
										</v-dialog>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-select
											:items="properties"
											v-model="selectedProperties"
											item-text="name"
											item-value="id"
											index="id"
											outlined
											multiple
											dense
											color="#1D085A"
											label="Property"
											placeholder="Select All That Apply"
											:menu-props="{
												bottom: true,
												offsetY: true,
											}"
										></v-select>

										<v-select
											:items="cleaners"
											v-model="selectedCleaner"
											item-text="name"
											item-value="id"
											index="id"
											outlined
											dense
											color="#1D085A"
											label="Cleaner"
											placeholder="Select One"
											:menu-props="{
												bottom: true,
												offsetY: true,
											}"
											style="margin-top: 0px"
										></v-select>

										<span v-if="false">
											Select a color for the event
											<v-color-picker
												:swatches="swatches"
												v-model="eventColor"
												show-swatches
												hide-canvas
												hide-inputs
												hide-sliders
											>
											</v-color-picker>
										</span>
										Select a service:
										<v-radio-group
											v-model="serviceName"
											mandatory
										>
											<v-radio
												label="Booking"
												value="Booking"
											></v-radio>
											<v-radio
												v-if="false"
												label="Maintenance"
												value="Maintenance"
											></v-radio>
											<v-radio
												label="Cleaning"
												value="Cleaning"
											></v-radio>
										</v-radio-group>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="$emit('editEventDialogClosed')"
					>
						Cancel
					</v-btn>
					<v-btn color="blue darken-1" text @click="updateEvent">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import VueTimePicker from "vue2-timepicker/src/vue-timepicker.vue";
import { db } from "../firebase";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import firebase from "firebase/compat/app";
// import firebase from "firebase/compat/app";
// import { v1 as uuidv4 } from "uuid";
// import { mapState } from "vuex";

export default {
	components: {
		VueTimePicker,
	},
	props: {
		editEventDialog: Boolean,
		selectedEvent: Object,
	},
	data: () => ({
		selectedProperties: [],
		properties: [],
		propertyName: "",
		serviceName: "",
		startTime: "",
		endTime: "",
		eventColor: "",
		cleaners: [],
		selectedCleaner: "",
		picker: [new Date().toISOString().substr(0, 10)],
		status: "",
		startTimeDialogEdit: false,
		endTimeDialogEdit: false,
		convertedStart: "",
		convertedEnd: "",
		swatches: [
			["#FF0000"],
			["#0000FF"],
			["#FFFF00"],
			["#FF7F00"],
			["#7F00FF"],
		],
		statusi: [
			{
				name: "pending",
				color: "#20B6F6",
				textColor: "#FFFFFF",
			},
			{
				name: "rejected",
				color: "#D72525",
				textColor: "#FFFFFF",
			},
			{
				name: "accepted",
				color: "#20B6F6",
				textColor: "#FFFFFF",
			},
			{
				name: "in-progress",
				color: "#5E17EB",
				textColor: "#FFFFFF",
			},
			{
				name: "complete",
				color: "#40C800",
				textColor: "#FFFFFF",
			},
		],
	}),
	created() {
		this.loadData();
	},
	methods: {
		...mapActions("adminUser", ["bindProperties"]),
		loadData() {
			console.log("selected", this.selectedEvent.start);
			this.propertyName = this.selectedEvent.property.name;
			this.serviceName = this.selectedEvent.service_name;
			let eventStart = moment(this.selectedEvent.start);
			this.picker = [eventStart.format("YYYY-MM-DD")];
			this.startTime = eventStart.format("HH:mm");
			this.convertedStart = moment(this.startTime, "HH:mm").format("LT");
			if (this.selectedEvent.end) {
				let eventEnd = moment(this.selectedEvent.end);
				this.picker[1] = eventEnd.format("YYYY-MM-DD");
				this.endTime = this.selectedEvent.end;
				this.convertedEnd = moment(this.endTime, "HH:mm").format("LT");
			} else {
				this.endTime = "";
			}
			this.selectedProperties = this.selectedEvent.properties;
			this.status = this.selectedEvent.status;
			this.selectedCleaner = this.selectedEvent.cleaners;

			this.bindProperties().then((res) => {
				this.properties = res;
			});

			this.getCleaners();
		},
		getCleaners() {
			db.collection("users_admin")
				.doc(this.adminId)
				.get()
				.then((doc) => {
					var cleaners = doc.data().cleaners;
					for (let i = 0; i < cleaners.length; i++) {
						db.collection("users_vendor")
							.doc(cleaners[i])
							.get()
							.then((vendorDoc) => {
								var vendor = vendorDoc.data();
								vendor.id = vendorDoc.id;
								this.cleaners.push(vendor);
							})
							.catch((err) => {
								console.log("Error appending cleaner", err);
							});
					}
				})
				.catch((err) => {
					console.log("Error with admin id", err);
				});
		},
		updateEvent() {
			var picker0 = firebase.firestore.Timestamp.fromDate(
				new Date(this.picker[0] + "T" + this.startTime)
			);
			var picker1;
			if (this.picker[1]) {
				picker1 = firebase.firestore.Timestamp.fromDate(
					new Date(this.picker[1] + "T" + this.endTime)
				);
			} else {
				picker1 = null;
			}
			// create a new event object with the updated values
			let firstPropertyId = this.selectedProperties[0];
			let firstProperty = this.properties.find(
				(property) => property.id === firstPropertyId
			);
			const event = {
				name: this.serviceName + " at " + firstProperty.name,
				properties: this.selectedProperties,
				property: {
					name: firstProperty.name,
					id: firstPropertyId,
					image: firstProperty.main_image,
				},
				service_name: this.serviceName,
				start: picker0,
				end: picker1,
				cleaners: this.selectedCleaner,
				//eventColor: this.eventColor,
			};

			// update the event in the database
			db.collection("events")
				.doc(this.selectedEvent.eventId)
				.update(event)
				.then(() => {
					console.log("event updated");
					this.$emit("editEventDialogClosed");
				});
		},
		saveStartTime() {
			this.startTimeDialogEdit = !this.startTimeDialogEdit;
			this.convertedStart = moment(this.startTime, "HH:mm").format("LT");
		},
		saveEndTime() {
			this.endTimeDialogEdit = !this.endTimeDialogEdit;
			this.convertedEnd = moment(this.endTime, "HH:mm").format("LT");
		},
	},
	computed: {
		/*properties() {
			return this.$store.state.properties;
		},*/
		...mapState("adminUser", ["adminId"]),
		getPropertyNames() {
			let propertyNames = [];
			if (this.properties) {
				console.log(this.properties);
				this.properties.forEach((property) => {
					propertyNames.push(property.name);
				});
			}
			return propertyNames;
		},
		getPropertyId() {
			let propertyList = this.properties.filter(
				(property) => property.name == this.propertyName
			);
			return propertyList[0].id;
		},
	},
};

// To-do:
//   - Edit and Delete Events
//   - Can't pass in dates that are backwards
//   - time dialogs being cut off
//   - Text color of events
//   - Assign a property a color
//   - Add filter by Service and Property to the Calendar
//  - fix the single date problem on the calendar
//  - add required fields on calendar
// - clear add event dialog after creation
</script>

<style scoped>
.picker {
	border: solid 1px #4c2997;
}

.TimeInput {
	border: solid red;
}

.timePicker {
	border: solid 1px #4c2997;
}

.timeBox {
	height: 40px;
	cursor: pointer;
}

.big {
	height: 1000px !important;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
	background: #4c2997;
}

.vue__time-picker-dropdown {
	z-index: 5000;
}
</style>
