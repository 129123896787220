<template>
	<div>
		<v-tooltip bottom v-if="booklet">
			<template v-slot:activator="{ on, attrs }">
				<v-icon
					class="bulkBtn"
					v-bind="attrs"
					v-on="on"
					color="grey"
					size="32px"
					dark
					@click="bulkAddClicked()"
					>mdi-plus-box-multiple-outline</v-icon
				>
			</template>
			<span>Add Item</span>
		</v-tooltip>
		<v-dialog
			v-model="bulkDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card class="dialog-card">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="bulkDialog = false"
				>
					X
				</div>
				<div class="purple-title2" style="margin-top: 0px">
					Add New Item
				</div>
				<v-form ref="form" v-model="valid" lazy-validation>
					<div v-if="bulkLoader == false">
						<v-select
							v-model="selectedBooklets"
							color="#1D085A"
							outlined
							multiple
							item-color="#1D085A"
							label="Select Booklets"
							:items="possibleBooklets"
							item-text="property_name"
							item-value="id"
						>
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@mousedown.prevent
									@click="toggle"
								>
									<v-list-item-action>
										<v-icon
											:color="
												selectedBooklets.length > 0
													? '#1D085A'
													: ''
											"
										>
											{{ icon }}
										</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											Select All
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
							<template v-slot:append-item>
								<v-divider class="mb-2"></v-divider>
							</template>
						</v-select>
						<v-select
							v-model="selectedCategory"
							outlined
							color="#1D085A"
							label="Select a Category"
							:items="categories"
						/>

						<div v-if="selectedCategory == 'FAQs'">
							<v-text-field
								outlined
								color="#1D085A"
								label="Question"
								v-model="bulkItem.question"
							></v-text-field>
							<v-text-field
								outlined
								color="#1D085A"
								label="Answer"
								v-model="bulkItem.answer"
							></v-text-field>
						</div>

						<div v-else>
							<v-text-field
								outlined
								color="#1D085A"
								label="Name"
								v-model="bulkItem.name"
							></v-text-field>
							<v-text-field
								outlined
								color="#1D085A"
								placeholder="Include https://"
								label="Website URL"
								:error-messages="urlErrors"
								v-model="bulkItem.link"
							></v-text-field>
							<div v-if="selectedCategory == 'Attractions'">
								<v-text-field
									outlined
									color="#1D085A"
									label="Distance"
									v-model="bulkItem.distance"
									type="number"
									style="width: 150px; display: inline"
									suffix="miles"
								></v-text-field>
								<p style="color: #1d085a">Icons Displayed</p>
								<div class="attraction-items-wrap">
									<div
										v-for="(icon, index) in icons"
										v-bind:key="index"
										class="attraction-items"
									>
										<v-checkbox
											color="#5E17EB"
											v-model="icon.selected"
											:label="icon.name"
										></v-checkbox>
									</div>
								</div>
							</div>
							<v-textarea
								outlined
								color="#1D085A"
								label="Description"
								v-model="bulkItem.description"
							></v-textarea>
						</div>

						<v-btn
							color="#5E17EB"
							dark
							style="width: 240px; margin-right: 32px"
							@click="AddBulkItem()"
							><v-icon color="white" style="margin-right: 16px"
								>mdi-plus</v-icon
							>Add</v-btn
						>
						<v-btn
							light
							style="width: fit"
							@click="bulkDialog = false"
							>Cancel</v-btn
						>
					</div>
					<div v-else style="max-width: 150px; margin: 0 auto">
						<v-progress-circular
							:size="75"
							color="#1D085A"
							indeterminate
						/>
						<p>
							Adding to Booklets: {{ bulkLoaderCount }}/{{
								selectedBooklets.length
							}}
						</p>
					</div>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase } from "../../firebase";
// import { mapActions } from "vuex";
export default {
	props: {
		booklet: Object,
		properties: Array,
	},
	data() {
		return {
			bulkItem: {},
			bulkDialog: false,
			bulkLoader: false,
			bulkLoaderCount: 0,
			valid: false,
			selectedBooklets: [],
			possibleBooklets: [],
			categories: [
				"Amenities",
				"Attractions",
				"Excursions",
				"FAQs",
				"Guide",
				"Restaurants",
				"Sites",
			],
			selectedCategory: "",
			urlErrors: [],
			icons: [],
		};
	},
	computed: {
		icon() {
			if (this.selectedBooklets.length == this.properties.length)
				return "mdi-close-box";
			else if (this.selectedBooklets.length > 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
	},
	created() {
		this.findBooklets();
		console.log("booklet", this.booklet);
		console.log("properties", this.properties);
	},
	methods: {
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		initializeIcons() {
			this.$emit("initializeIcons");
		},
		findProperty() {
			this.$emit("findProperty");
		},
		findBooklets() {
			for (let i = 0; i < this.properties.length; i++) {
				db.collection("booklets")
					.where("property_id", "==", this.properties[i].id)
					.limit(1)
					.get()
					.then((response) => {
						const item = response.docs[0];
						let tmp = item.data();
						tmp.id = item.id;
						this.possibleBooklets.push(tmp);
					})
					.catch(() => {
						console.log(
							"This property does not yet have a booklet ->",
							this.properties[i].name
						);
					});
			}
		},

		// BULK ADD FUNCTIONS
		bulkAddClicked() {
			this.bulkDialog = true;
			this.bulkItem = {};
			this.selectedBooklets = [];
			this.selectedCategory = "";
		},

		AddBulkItem: function () {
			console.log("Bulk item check", this.bulkItem);
			if (this.isBadUrl(this.bulkItem.link)) {
				console.log("bad url");
				return false;
			}

			this.bulkLoader = true;
			if (this.selectedCategory == "Attractions") {
				var iconsToAdd = [];
				for (var i = 0; i < this.icons.length; i++) {
					if (this.icons[i].selected) {
						iconsToAdd.push(this.icons[i].icon);
					}
				}
				this.bulkItem.icons = iconsToAdd;
				this.InitializeIcons();
			}
			this.RecursiveAdd();
		},

		RecursiveAdd: async function () {
			for (let i = 0; i < this.selectedBooklets.length; i++) {
				console.log(
					`Adding to ${this.selectedBooklets[i]}`,
					this.bulkItem
				);
				await db
					.collection("booklets")
					.doc(this.selectedBooklets[i])
					.update({
						[this.selectedCategory.toLowerCase()]:
							firebase.firestore.FieldValue.arrayUnion(
								this.bulkItem
							),
					})
					.then(() => {
						this.bulkLoaderCount += 1;
						if (i == this.selectedBooklets.length - 1) {
							this.findProperty();
							this.bulkDialog = false;
							this.bulkLoader = false;
						}
					})
					.catch((err) => {
						console.log("Error adding booklet", err);
						alert(
							`An error occurred adding item to booklet with id: ${this.selectedBooklets[i]}. Please make sure all of your properties have active booklets and try again.`
						);
					});
			}
		},
		toggle() {
			this.$nextTick(() => {
				if (
					this.selectedBooklets.length == this.possibleBooklets.length
				) {
					this.selectedBooklets = [];
				} else {
					let temp = this.possibleBooklets.slice();
					for (let i = 0; i < temp.length; i++) {
						this.selectedBooklets.push(temp[i].id);
					}
				}
			});
		},
	},
};
</script>

<style scoped>
.purple-title2 {
	color: #1d085a;
	font-size: 18px;
	margin-bottom: 16px;
}
.dialog-card {
	overflow-x: auto;
	padding: 32px;
	position: relative;
}
.bulkBtn {
	display: block;
	float: right;
	margin-bottom: 16px;
	margin-top: -16px;
}
</style>
