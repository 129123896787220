<template>
	<v-app>
		<div class="app-div">
			<Header></Header>
			<div class="main-container content-wrap">
				<h3 class="purple-title" id="message"></h3>
				<p>
					{{ message }}
				</p>
				<router-link :to="redirectLink">
					<v-btn class="purple-btn" rounded color="#5e17eb">{{
						btnText
					}}</v-btn>
				</router-link>
			</div>
		</div>
	</v-app>
</template>

<script>
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC);

import { mapState } from "vuex";
import Header from "../../layout/Admin/Header.vue";
// http://localhost:8080/successful-subscription?payment_intent=pi_3L8aHrBfK1iuLp1y1FV405nY&payment_intent_client_secret=pi_3L8aHrBfK1iuLp1y1FV405nY_secret_7ukyHj8XQ5lUS5ppSdXYv3dlK&redirect_status=succeeded
export default {
	name: "SuccessfulSubscription",
	metaInfo: {
		title: "Successful Subscription",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content: "Thank you for subscribing to use Dormi.io",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		// Footer,
		Header,
	},
	data() {
		return {
			redirectLink: "/properties",
			message: "",
			btnText: "",
		};
	},
	computed: {
		...mapState("adminUser", ["adminId"]),
	},
	created() {
		console.log("admin id", this.adminId);
		this.RetrievePaymentIntent();
	},
	methods: {
		RetrievePaymentIntent: function () {
			const clientSecret = new URLSearchParams(
				window.location.search
			).get("payment_intent_client_secret");
			stripe
				.retrievePaymentIntent(clientSecret)
				.then(({ paymentIntent }) => {
					switch (paymentIntent.status) {
						case "succeeded":
							this.message =
								"Congratulations! You have successfully subscribed to Dormi.io. Click below to go to the dashboard. (You may be asked to login again)";
							this.redirectLink = "/properties";
							this.btnText = "Go to dashboard";
							break;

						case "processing":
							this.message =
								"Payment processing. We'll update you when payment is received.";
							this.redirectLink = "/login";
							this.btnText = "Go to login";
							break;

						case "requires_payment_method":
							this.message =
								"Payment failed. Please try another payment method.";
							// Redirect your user back to your payment page to attempt collecting
							// payment again
							this.redirectLink = "/subscriptions";
							this.btnText = "Back to payment method";
							break;

						default:
							this.message = "Something went wrong.";
							this.redirectLink = "/subscriptions";
							this.btnText = "Back to subscriptions";
							break;
					}
				});
		},
	},
};
</script>

<style scoped>
.app-div {
	min-height: 100vh;
}
.main-container {
	max-width: 600px !important;
}
.content-wrap {
	text-align: center;
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	width: 100%;
}
.purple-title {
	color: #1d085a;
	font-size: 28px;
	font-family: "cabin_title";
	margin-bottom: 24px;
	text-decoration: none;
	display: inline-block;
}

.purple-btn {
	color: white !important;
	margin-top: 32px;
}
a {
	text-decoration: none !important;
}
</style>
