<template>
	<div class="app-div">
		<Header></Header>
		<div class="main-container">
			<h3 class="purple-title">Account</h3>
			<div class="pricing-container">
				<div class="pricing-box">
					<h4 class="price-title">My Subscription</h4>
					<!-- <p>$20.00 /mo</p> -->
					<p style="margin-top: 16px">
						Full access to the currently available features (Custom
						checklist, share with cleaners, upload property images,
						and more...)
					</p>
					<div
						class="pricing-btn"
						@click="CancelSubscriptionClient()"
					>
						Cancel
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "../../layout/Admin/Header.vue";
import { auth } from "../../firebase";
import { db } from "../../firebase";
import { functions } from "../../firebase";

function GetUser(adminId) {
	var docRef = db.collection("users_admin").doc(adminId);
	console.log("Searching user:", adminId);
	return new Promise((resolve, reject) => {
		docRef
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log("Document data:", doc.data());
					resolve(doc.data().subscription_id);
				} else {
					// doc.data() will be undefined in this case
					console.log("No such document!");
				}
			})
			.catch((error) => {
				console.log("Error getting document:", error);
				reject(error);
			});
	});
}

export default {
	name: "Account",
	metaInfo: {
		title: "Account",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Create an account with Dormi. The most simple software to help you manage your vacation rentals. ",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
	},
	data() {
		return {
			adminId: "",
			subscriptionId: "",
		};
	},
	created() {
		const user = auth.currentUser;
		console.log(user.uid);

		if (user) {
			this.adminId = user.uid;
		} else {
			// No user is signed in.
			console.log("No user signed in on Subscriptions page.");
		}
	},
	methods: {
		CancelSubscriptionClient: function () {
			this.GetUserInfo().then(() => {
				var cancelSubscription =
					functions.httpsCallable("cancelSubscription");
				cancelSubscription({ subscription_id: this.subscriptionId })
					.then((res) => {
						console.log("Subscription cancelled", res);
						this.$router.push({ name: "Login" });
					})
					.catch((error) => {
						console.log("Error cancelling:", error);
					});
			});
		},
		GetUserInfo: function () {
			return new Promise((resolve, reject) => {
				GetUser(this.adminId)
					.then((subscriptionId) => {
						this.subscriptionId = subscriptionId;
						resolve();
					})
					.catch((error) => {
						console.log("Couldn't find user", error);
						reject();
					});
			});
		},
	},
};
</script>

<style scoped>
/* Pricing */
.app-div {
	min-height: 100vh;
}
.pricing-box {
	height: auto;
	width: 100%;
	background-color: white;
	border-radius: 16px;
	padding: 16px;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.pricing-btn {
	background-color: rgba(108, 60, 210, 0.85);
	color: white;
	height: 32px;
	padding: 0px 16px;
	line-height: 32px;
	text-align: center;
	margin-top: 16px;
	border-radius: 40px;
	cursor: pointer;
}
.purple-title,
.purple-title2 {
	color: #1d085a;
	font-size: 36px;
	font-family: "cabin_title";
	margin-bottom: 8px;
	margin-top: 36px;
	text-decoration: none;
	display: inline-block;
}
</style>
