<template>
	<div class="app-div">
		<div class="main-container">
			<div class="back-btn" @click="$router.push('/properties')">
				<v-icon color="rgb(160, 160, 160)" size="18px"
					>mdi-arrow-left</v-icon
				>
				<p class="back-txt">Go Back</p>
			</div>
			<div class="sub-nav-wrap">
				<h3 class="purple-title">{{ currentProperty.name }}</h3>
			</div>

			<v-app-bar dense class="app-bar">
				<v-menu left bottom color="white" class="app-bar-menu">
					<v-list>
						<v-list-item
							v-for="nav in navItems"
							color="#5e17eb"
							:key="nav"
							@click="navClicked(nav)"
						>
							<v-list-item-title>{{ nav }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-tabs
					class="mr-1"
					color="#5e17eb"
					:value="tabIndex"
					optional
					left
				>
					<v-tab
						flat
						v-for="nav in navItems"
						:key="nav"
						@click="navClicked(nav)"
						>{{ nav }}</v-tab
					>
				</v-tabs>
			</v-app-bar>
		</div>
	</div>
</template>

<script>
import { db } from "../../firebase";

export default {
	name: "PropertyNav",
	components: {},
	data() {
		return {
			navItems: ["Owners Booklet", "Checklist"], // Took out Checklist, Property Info
			tabIndex: 0,

			currentPropertyId: "",
			currentProperty: "",
		};
	},
	created() {
		if (this.$route.name == "PropertyChecklist") {
			this.tabIndex = 1;
		} else if (this.$route.name == "Booklet") {
			this.tabIndex = 0;
		}
		//else if(this.$route.name == "Property"){
		//     this.tabIndex = 2;
		// }
		this.findProperty();
	},
	methods: {
		findProperty: function () {
			this.currentPropertyId = this.$route.params.property_id;
			console.log("Id", this.currentPropertyId);
			db.collection("properties")
				.doc(this.currentPropertyId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						this.currentProperty = doc.data();
						if (this.currentProperty.tasks != undefined) {
							this.masterChecklist = this.currentProperty.tasks;
							this.gallery = this.currentProperty.gallery;
						} else {
							this.masterChecklist = [];
						}
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});

			console.log("Checklist: ", this.masterChecklist);
		},
		navClicked: function (nav) {
			var tabCheck = "";
			if (nav == "Property Info") {
				nav = "property";
				tabCheck = "Property";
			} else if (nav == "Owners Booklet") {
				nav = "booklet";
				tabCheck = "Booklet";
			} else if (nav == "Checklist") {
				nav = "propertychecklist";
				tabCheck = "PropertyChecklist";
			}
			console.log("navigation", tabCheck, this.$route.name);
			if (tabCheck == this.$route.name) {
				return;
			}
			this.$router.push("/" + nav + "/" + this.currentPropertyId);
		},
	},
};
</script>

<style scoped>
.app-bar {
	position: relative !important;
	padding: 0px !important;
	background-color: transparent !important;
	box-shadow: none !important;
	border-bottom: thin solid dimgray !important;
}
.main-container >>> .v-toolbar__content {
	padding: 0px !important;
}
.purple-title {
	color: #1d085a;
	font-size: 36px;
	font-family: "cabin_title";
	margin-bottom: 8px;
	margin-top: 0px;
	text-decoration: none;
	display: inline-block;
}
.back-btn {
	width: 90px;
	vertical-align: middle;
	padding: 6px;
	cursor: pointer;
	margin: 8px 0px;
	margin-left: -10px;
	position: relative;
	font-size: 14px;
	color: rgb(160, 160, 160);
}
.back-btn:hover {
	background-color: rgba(160, 160, 160, 0.281);
}
.back-txt {
	display: inline;
	vertical-align: middle;
	line-height: 32px;
	font-size: 14px;
	margin-left: 6px;
}
.left-arrow {
	transform: rotate(90deg);
	float: left;
}
</style>
