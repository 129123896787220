<template>
	<div class="app-div" style="padding-bottom: 24px">
		<Header></Header>
		<PropertyNav></PropertyNav>
		<div class="main-container" style="margin-top: 32px">
			<v-app>
				<v-btn
					v-if="Object.keys(this.masterChecklist).length === 0"
					@click="StartNewChecklist()"
					style="margin: 0 auto; margin-bottom: 32px"
					><v-icon>mdi-plus</v-icon>Create Checklist From
					Template</v-btn
				>
				<h3
					v-else
					class="purple-checklist-title"
					style="margin-top: 32px"
				>
					Cleaning Checklist
					<div style="float: right; padding-top: 15px">
						<v-btn
							icon
							title="Grid View"
							@click.stop="ToggleViewLayout('card')"
						>
							<v-icon>
								mdi-view-module{{
									viewLayout === "card" ? "" : "-outline"
								}}
							</v-icon>
						</v-btn>
						<v-btn
							icon
							title="List View"
							@click.stop="ToggleViewLayout('list')"
						>
							<v-icon>
								mdi-view-list{{
									viewLayout === "list" ? "" : "-outline"
								}}
							</v-icon>
						</v-btn>
					</div>
				</h3>
				<div class="property-list-wrap" v-if="viewLayout === 'list'">
					<draggable
						class="property-list-wrap"
						v-model="masterChecklist"
						group="categories"
						draggable=".list-drag"
						handle=".checklist-box-title"
						@change="SaveCategorySort"
					>
						<checklist-view-list
							class="task-card task-card-list list-drag"
							v-for="category in masterChecklist"
							:key="category.id"
							:category="category"
							:sorting="sorting"
							@AddTaskClicked="
								AddTaskClicked(category.id, category.name)
							"
							@DeleteTasksClicked="DeleteTasksClicked(category)"
							@AppendSelectedTask="AppendSelectedTask"
							@TaskSelected="TaskSelected"
							@SortCategoryItems="SortCategoryItems"
							@setSorting="setSorting"
						></checklist-view-list>
					</draggable>
					<div
						style="background-color: #f4f4f4"
						@click="AddTaskClicked(null)"
					>
						<v-btn block>
							<v-icon color="gray">mdi-plus</v-icon> New Task
						</v-btn>
					</div>
				</div>
				<div v-if="viewLayout === 'card'">
					<draggable
						tag="v-layout"
						class="property-cards-wrap"
						v-model="masterChecklist"
						group="categories"
						draggable=".card-drag"
						handle=".checklist-box-title"
						@change="SaveCategorySort"
					>
						<checklist-view-card
							class="task-card card-drag"
							v-for="category in masterChecklist"
							:key="category.id"
							:category="category"
							@AddTaskClicked="
								AddTaskClicked(category.id, category.name)
							"
							@DeleteTasksClicked="DeleteTasksClicked(category)"
							@AppendSelectedTask="AppendSelectedTask"
							@TaskSelected="TaskSelected"
							@SortCategoryItems="SortCategoryItems"
						></checklist-view-card>
						<div
							class="task-card add-task-card"
							style="background-color: #f4f4f4"
							@click="AddTaskClicked(null, null)"
						>
							<div class="add-task-text">
								<v-icon color="gray">mdi-plus</v-icon> New Task
							</div>
						</div>
					</draggable>
				</div>

				<v-dialog
					v-model="dialogChecklistView"
					scrollable
					width="80%"
					transition="dialog-bottom-transition"
				>
					<v-card v-if="dialogType == 'add'">
						<v-card-title>Add a Task</v-card-title>
						<v-container>
							<v-form ref="form" v-model="valid" lazy-validation>
								<v-row style="padding: 12px !important">
									<v-col
										cols="12"
										sm="6"
										style="
											padding: 0px !important;
											padding-top: 10px !important;
											padding-right: 8px;
										"
									>
										<v-text-field
											required
											:rules="nameRules"
											label="Task Name"
											placeholder="Ex) Vacuum Entire House"
											class="property-name-field"
											v-model="newTaskName"
										></v-text-field>
									</v-col>
									<v-col
										cols="12"
										sm="6"
										style="
											padding: 0px !important;
											padding-top: 0px !important;
										"
									>
										<v-combobox
											auto-select-first
											chips
											clearable
											:items="categories"
											v-model="newCategory"
											deletable-chips
											required
											:rules="catRules"
											label="Category"
										/>
									</v-col>

									<v-textarea
										outlined
										name="input-7-4"
										background-color="grey lighten-2"
										color="lightgray"
										label="Extra Notes (Optional)"
										style="margin-top: 24px"
										v-model="newNotes"
									>
									</v-textarea>
								</v-row>
							</v-form>
						</v-container>

						<v-divider></v-divider>
						<v-card-actions>
							<v-btn
								color="#5e17eb"
								text
								@click="CloseTaskDetails()"
								>Close</v-btn
							>
							<v-btn
								color="#5e17eb"
								:class="{ grayBtn: !valid }"
								text
								@click="Validate()"
								>Save</v-btn
							>
						</v-card-actions>
					</v-card>

					<v-card v-if="dialogType == 'task'">
						<v-card-title>Task Details</v-card-title>
						<v-container>
							<v-form ref="form2" v-model="valid" lazy-validation>
								<v-row style="padding: 12px !important">
									<v-col
										cols="12"
										sm="6"
										style="
											padding: 0px !important;
											padding-top: 10px !important;
											padding-right: 8px;
										"
									>
										<v-text-field
											required
											:rules="nameRules"
											label="Task Name"
											placeholder="Ex) Vacuum Entire House"
											class="property-name-field"
											v-model="currentTaskName"
										></v-text-field>
									</v-col>
									<v-col
										cols="12"
										sm="6"
										style="
											padding: 0px !important;
											padding-top: 0px !important;
										"
									>
										<v-combobox
											auto-select-first
											chips
											clearable
											:items="categories"
											required
											:rules="catRules"
											v-model="currentCategory"
											deletable-chips
											label="Category"
										/>
									</v-col>
									<v-textarea
										outlined
										name="input-7-4"
										background-color="grey lighten-2"
										color="lightgray"
										label="Extra Notes (Optional)"
										style="margin-top: 24px"
										v-model="currentNotes"
									>
									</v-textarea>
								</v-row>
							</v-form>

							<v-divider></v-divider>
							<v-card-actions>
								<v-btn
									color="#5e17eb"
									text
									@click="CloseTaskDetails()"
									>Close</v-btn
								>
								<v-btn
									color="#5e17eb"
									:class="{ grayBtn: !valid }"
									text
									@click="ValidateEdit()"
									>Save</v-btn
								>
								<v-btn
									color="red"
									style="color: white !important"
									class="float-right"
									@click="DeleteTask()"
									>Delete</v-btn
								>
							</v-card-actions>
						</v-container>
					</v-card>
				</v-dialog>

				<v-dialog
					v-model="deleteDialog"
					scrollable
					width="80%"
					transition="dialog-bottom-transition"
				>
					<v-card>
						<v-card-title
							>Are you sure you want to delete<span
								style="
									color: #5e17eb;
									font-size: 1.25rem;
									font-weight: 500;
									letter-spacing: 0.0125em;
								"
								>&nbsp;{{ deleteText }}</span
							>?</v-card-title
						>
						<v-container>
							<v-divider></v-divider>
							<v-card-actions>
								<v-btn
									color="#5e17eb"
									text
									@click="deleteDialog = false"
									>Cancel</v-btn
								>
								<v-btn
									color="red"
									style="color: white !important"
									class="float-right"
									@click="DeleteTasks()"
									>Delete</v-btn
								>
							</v-card-actions>
						</v-container>
					</v-card>
				</v-dialog>
				<!-- <h3 class="purple-title2">Scheduled</h3> -->
			</v-app>
		</div>
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
import PropertyNav from "../../layout/Admin/PropertyNav.vue";
import Header from "../../layout/Admin/Header.vue";
import ChecklistViewCard from "../../components/propertychecklist/ChecklistViewCard.vue";
import ChecklistViewList from "../../components/propertychecklist/ChecklistViewList.vue";
import { db } from "../../firebase";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { documentId, writeBatch } from "firebase/firestore";

// this should go in a separate file
const STARTER_CHECKLIST = {
	// General Cleaning
	"General Cleaning": {
		sort: 0,
		tasks: [
			{
				cleaned: false,
				notes: "",
				name: "Dust and clean all light fixtures in the house",
			},
			{
				cleaned: false,
				notes: "",
				name: "Wipe the blinds and dust the curtains",
			},
			{
				cleaned: false,
				notes: "",
				name: "Vacuum and sanitize all floors, carpets, and furniture",
			},
			{
				cleaned: false,
				notes: "",
				name: "Empty and sanitize trash bins",
			},
			{ cleaned: false, notes: "", name: "Clean mirrors and windows" },
			{
				cleaned: false,
				notes: "",
				name: "Clean shelves and wipe the dust off decorative items",
			},
			{
				cleaned: false,
				notes: "",
				name: "Vacuum and wipe the areas around and underneath furniture",
			},
			{ cleaned: false, notes: "", name: "Clean all the drawers" },
			{
				cleaned: false,
				notes: "",
				name: "Fold and restock towels and blankets",
			},
			{ cleaned: false, notes: "", name: "Dust the pillows" },
		],
	},

	// Home Exterior (Patio, Garage, etc.)
	"Home Exterior": {
		sort: 1,
		tasks: [
			{ cleaned: false, notes: "", name: "Wipe and clean all furniture" },
			{ cleaned: false, notes: "", name: "Empty the trash bins" },
			{ cleaned: false, notes: "", name: "Sweep the patio" },
			{
				cleaned: false,
				notes: "",
				name: "Clean lawn debris (sticks, leaves, branches, etc.)",
			},
			{ cleaned: false, notes: "", name: "Dust and vacuum the garage" },
			{ cleaned: false, notes: "", name: "Wash exterior windows" },
			{ cleaned: false, notes: "", name: "Clean the gutter" },
			{ cleaned: false, notes: "", name: "Wash and degrease the grill" },
		],
	},

	// Living Room
	"Living Room": {
		sort: 2,
		tasks: [
			{
				cleaned: false,
				notes: "",
				name: "Dust the pillows and other decorative items",
			},
			{ cleaned: false, notes: "", name: "Vacuum and mop the floors" },
			{
				cleaned: false,
				notes: "",
				name: "Clean all windows and mirrors",
			},
			{
				cleaned: false,
				notes: "",
				name: "Wipe the walls, light switches, and doors",
			},
			{
				cleaned: false,
				notes: "",
				name: "Clean the windows, curtains, and blinds",
			},
			{ cleaned: false, notes: "", name: "Wipe the chairs and sofas" },
			{ cleaned: false, notes: "", name: "Dust the shelves" },
			{
				cleaned: false,
				notes: "",
				name: "Vacuum and clean areas around and underneath the furniture",
			},
			{
				cleaned: false,
				notes: "",
				name: "Organize magazines and other miscellaneous items",
			},
		],
	},

	// Dining Room
	"Dining Room": {
		sort: 3,
		tasks: [
			{ cleaned: false, notes: "", name: "Clean the tabletops" },
			{ cleaned: false, notes: "", name: "Wipe the chairs" },
			{
				cleaned: false,
				notes: "",
				name: "Dust shelves and organize cabinets",
			},
			{ cleaned: false, notes: "", name: "Mop the floors" },
			{ cleaned: false, notes: "", name: "Clean all rugs and carpets" },
			{ cleaned: false, notes: "", name: "Polish the silverware" },
			{
				cleaned: false,
				notes: "",
				name: "Clean the windows, curtains, and blinds",
			},
			{ cleaned: false, notes: "", name: "Remove cobwebs" },
			{ cleaned: false, notes: "", name: "Clean the light fixtures" },
			{
				cleaned: false,
				notes: "",
				name: "Declutter and remove unnecessary items",
			},
		],
	},

	// Kitchen
	Kitchen: {
		sort: 4,
		tasks: [
			{
				cleaned: false,
				notes: "",
				name: "Clean and degrease the microwave oven",
			},
			{
				cleaned: false,
				notes: "",
				name: "Clean the oven, including wire racks",
			},
			{ cleaned: false, notes: "", name: "Sanitize all countertops" },
			{
				cleaned: false,
				notes: "",
				name: "Make sure that there’s no food in the fridge",
			},
			{
				cleaned: false,
				notes: "",
				name: "Wipe all drawers and cupboards",
			},
			{ cleaned: false, notes: "", name: "Restock the pantry" },
			{ cleaned: false, notes: "", name: "Clean the refrigerator" },
			{ cleaned: false, notes: "", name: "Clean the dishwasher" },
			{ cleaned: false, notes: "", name: "Sweep the floors" },
			{
				cleaned: false,
				notes: "",
				name: "Empty and clean the trash cans",
			},
		],
	},

	// Bathroom
	Bathroom: {
		sort: 5,
		tasks: [
			{
				cleaned: false,
				notes: "",
				name: "Clean and sanitize the whole toilet (seat, bowl, lid, tank, etc.)",
			},
			{
				cleaned: false,
				notes: "",
				name: "Wash and sanitize the toilet brush",
			},
			{ cleaned: false, notes: "", name: "Clean the sinks" },
			{ cleaned: false, notes: "", name: "Replace and fold towels" },
			{
				cleaned: false,
				notes: "",
				name: "Wipe the faucets and the showerhead",
			},
			{
				cleaned: false,
				notes: "",
				name: "Thoroughly clean the mirrors and other reflective surfaces",
			},
			{ cleaned: false, notes: "", name: "Dust all shelves and décor" },
			{
				cleaned: false,
				notes: "",
				name: "Clean and restock all the cabinets and drawers",
			},
			{
				cleaned: false,
				notes: "",
				name: "Make sure that there is no bathroom mold",
			},
			{
				cleaned: false,
				notes: "",
				name: "Clean and vacuum the carpets and other fabric inside the bathroom",
			},
		],
	},

	// Bedroom
	Bedroom: {
		sort: 6,
		tasks: [
			{
				cleaned: false,
				notes: "",
				name: "Make the bed and replace all pillowcases",
			},
			{
				cleaned: false,
				notes: "",
				name: "Change the sheets and blankets",
			},
			{
				cleaned: false,
				notes: "",
				name: "Remove cobwebs from the ceiling, corners, and light fixtures",
			},
			{ cleaned: false, notes: "", name: "Wash the curtains" },
			{
				cleaned: false,
				notes: "",
				name: "Dust the blinds and clean the windows",
			},
			{ cleaned: false, notes: "", name: "Vacuum the mattress" },
			{ cleaned: false, notes: "", name: "Clean the light switches" },
			{
				cleaned: false,
				notes: "",
				name: "Dust the drawers, cupboards, shelves, and décor",
			},
			{ cleaned: false, notes: "", name: "Mop the floor" },
			{
				cleaned: false,
				notes: "",
				name: "Vacuum under the bed and other furniture",
			},
		],
	},
};

export default {
	name: "PropertyChecklist",
	metaInfo: {
		title: "Property Checklist",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Create a customized checklist for each property. With Dormi, each cleaner will know exactly what tasks need to be done and update you in realtime when the cleaning is started and finished. The checklist allows you to upload images to display a finished product and allows your cleaners to send back damage reports.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
		PropertyNav,
		ChecklistViewCard,
		ChecklistViewList,
		draggable,
	},
	data() {
		return {
			currentProperty: "",
			currentPropertyId: "",
			currentCategoryIndex: "",
			currentCategoryId: "",
			checklistId: "",
			newCategory: "",
			currentNotes: "",
			propertyTab: "view",
			dialogChecklistView: false,
			dialogType: false,
			masterChecklist: [],
			checklistRefresh: 0,
			newTaskName: "",

			userID: "bVhL9kXcEWAumfnWJs8M",
			gallery: [],
			carousel: "",
			galleryView: false,
			newNotes: "",
			categories: [],
			currentCategory: "",
			deleteDialog: false,
			deleteText: "",
			selectedTasks: [],
			tasksToBeDeleted: [],
			sorting: false,

			valid: false,
			catRules: [
				(v) => !!v || "Category is required",
				(v) =>
					(v && v.length <= 30) ||
					"Name must be less than 30 characters",
			],
			nameRules: [
				(v) => !!v || "Task name is required",
				(v) =>
					(v && v.length <= 30) ||
					"Name must be less than 30 characters",
			],
		};
	},
	computed: {
		viewLayout() {
			return this.getPropertyChecklistView();
		},
	},
	created() {
		this.findProperty();
		this.bindUser();
	},
	methods: {
		...mapGetters("adminUser", ["getPropertyChecklistView"]),
		...mapActions("adminUser", [
			"savePropertyChecklistListView",
			"bindUser",
		]),
		...mapActions("adminPropertyChecklist", [
			"savePropertyChecklistCategorySort",
			"savePropertyChecklistTaskSort",
			"startNewPropertyChecklist",
		]),
		findProperty: function () {
			this.currentPropertyId = this.$route.params.property_id;
			console.log("Id", this.currentPropertyId);
			db.collection("checklists")
				.where("property_id", "==", this.currentPropertyId)
				.get()
				.then((query) => {
					if (query.empty) {
						console.log("Checklist doesn't exist yet");
						return;
					}
					const checklistRef = query.docs[0];
					this.checklistId = checklistRef.id;

					// this.masterChecklist = checklistRef.data().categories;
					// delete this.masterChecklist.property_id;

					/*
					for (const [key, value] of Object.entries(
						this.masterChecklist
					)) {
						console.log(`${key}: ${value}`);
					}
					*/

					console.log("Document data:", this.masterChecklist);
				})
				.then(() => {
					db.collection("checklists")
						.doc(this.checklistId)
						.collection("checklist_categories")
						.orderBy("sort")
						.get()
						.then((catQuery) => {
							catQuery.forEach((doc) => {
								let checklistCat = doc.data();
								checklistCat.id = doc.id;
								checklistCat.tasks = [];
								this.masterChecklist.push(checklistCat);
							});
						});
				})
				.then(() => {
					const collectionRef = db.collection("checklists");
					const checklistRef = collectionRef.doc(this.checklistId);
					db.collectionGroup("checklist_tasks")
						.orderBy(documentId())
						.startAt(checklistRef.path)
						.endAt(checklistRef.path + "\uf8ff") // unicode last char (end guard)
						.get()
						.then((tasksQuery) => {
							tasksQuery.forEach((doc) => {
								let categoryId = doc.ref.parent.parent.id;
								let masterIndex = this.getIndexById(
									categoryId,
									this.masterChecklist
								);

								if (masterIndex !== -1) {
									let taskItem = doc.data();
									taskItem.id = doc.id;
									this.masterChecklist[
										masterIndex
									].tasks.push(taskItem);
								}
							});
						})
						.then(() => {
							for (
								var i = 0;
								i < this.masterChecklist.length;
								i++
							) {
								this.masterChecklist[i].tasks.sort(
									(a, b) => a.sort - b.sort
								);
							}
						});
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});
		},
		StartNewChecklist: async function () {
			try {
				let payload = {
					propertyId: this.currentPropertyId,
					starterChecklist: STARTER_CHECKLIST,
				};

				if (this.checklistId) {
					payload.checklistId = this.checklistId;
				}

				await this.startNewPropertyChecklist(payload);

				this.findProperty();
			} catch (e) {
				console.error("Error creating initial checklist: ", e);
			}
		},
		AddTaskClicked: function (categoryId, categoryName) {
			this.dialogChecklistView = true;
			this.dialogType = "add";
			console.log("Cat", categoryId, " ", categoryName);
			if (categoryId != null) {
				this.currentCategoryId = categoryId;
				this.newCategory = categoryName;
			} else {
				this.newCategory = "";
			}
		},
		AppendSelectedTask: function (task, category) {
			var exists = false;
			for (var i = 0; i < this.selectedTasks.length; i++) {
				if (task.id == this.selectedTasks[i].id) {
					exists = true;
					this.selectedTasks.splice(i, 1);
					break;
				}
			}
			if (!exists) {
				let taskCopy = Object.assign({}, task);
				taskCopy.categoryId = category.id;
				this.selectedTasks.push(taskCopy);
			}
			console.log("Selected tasks", this.selectedTasks);
		},
		TaskSelected(task, category) {
			this.dialogChecklistView = true;
			this.dialogType = "task";
			this.currentTask = task;
			this.currentTaskName = task.name;
			this.currentNotes = task.notes;
			this.currentCategory = category.name;
			this.currentCategoryId = category.id;
		},
		ResetTaskDetails() {
			this.$refs.form.reset();
			this.currentTask = "";
			this.currentTaskName = "";
			this.currentNotes = "";
			this.newTaskName = "";
			this.newNotes = "";
		},
		CloseTaskDetails() {
			this.dialogChecklistView = false;
			this.ResetTaskDetails();
		},
		Validate() {
			if (this.$refs.form.validate()) {
				this.SaveTaskDetails();
			}
		},
		ValidateEdit: function () {
			if (this.$refs.form2.validate()) {
				this.SaveEditedTaskDetails();
			}
		},
		ToggleViewLayout(view) {
			if (view != "list") {
				view = "card";
			}
			this.savePropertyChecklistListView(view);
		},
		async SaveTaskDetails() {
			try {
				this.dialogChecklistView = false;
				let newTask = {
					name: this.newTaskName,
					notes: this.newNotes,
					cleaned: false,
				};

				let categoryIndex = false;
				if (!this.currentCategoryId) {
					// add new category to DB
					let newCategory = {
						name: this.newCategory,
						sort: this.masterChecklist.length,
					};

					let newCatRef = db
						.collection("checklists")
						.doc(this.checklistId)
						.collection("checklist_categories")
						.doc();
					await newCatRef.set(newCategory);

					newCategory.id = newCatRef.id;
					this.currentCategoryId = newCategory.id;
					newCategory.tasks = [];

					// add new category to masterChecklist
					this.masterChecklist.push(newCategory);

					// set categoryIndex (for use adding task)
					categoryIndex = this.masterChecklist.length - 1;

					newTask.sort = 0; // assume 0 sort for first task in new category
				} else {
					categoryIndex = this.getIndexById(
						this.currentCategoryId,
						this.masterChecklist
					);

					if (categoryIndex >= 0) {
						// Add 'sort' to task data
						newTask.sort =
							this.masterChecklist[categoryIndex].tasks.length;
					} else {
						console.error(
							"Error adding task, could not find current category index"
						);
					}
				}

				// create task in DB
				let newTaskRef = db
					.collection("checklists")
					.doc(this.checklistId)
					.collection("checklist_categories")
					.doc(this.currentCategoryId)
					.collection("checklist_tasks")
					.doc();
				await newTaskRef.set(newTask);

				// add ID to task data
				newTask.id = newTaskRef.id;

				// update existing category
				this.masterChecklist[categoryIndex].tasks.push(newTask);

				this.currentCategoryId = "";

				this.ResetTaskDetails();
			} catch (e) {
				console.error("Error adding new task: ", e);
			}
		},
		SaveEditedTaskDetails() {
			this.dialogChecklistView = false;
			let masterIndex = this.getIndexById(
				this.currentCategoryId,
				this.masterChecklist
			);
			let itemIndex = this.getIndexById(
				this.currentTask.id,
				this.masterChecklist[masterIndex].tasks
			);

			let task = this.masterChecklist[masterIndex].tasks[itemIndex];
			console.log("current task", this.currentTask.name, task);

			if (task) {
				this.masterChecklist[masterIndex].tasks[itemIndex].name =
					this.currentTaskName;
				this.masterChecklist[masterIndex].tasks[itemIndex].notes =
					this.currentNotes;
			}

			var checklistRef = db
				.collection("checklists")
				.doc(this.checklistId)
				.collection("checklist_categories")
				.doc(this.currentCategoryId)
				.collection("checklist_tasks")
				.doc(this.currentTask.id);
			return checklistRef
				.update({
					name: this.currentTaskName,
					notes: this.currentNotes,
				})
				.then(() => {
					console.log("Document successfully updated!");
				})
				.catch((error) => {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error);
				});
		},
		DeleteTasksClicked: function (category) {
			this.currentCategory = category.name;
			this.currentCategoryId = category.id;
			this.tasksToBeDeleted = [];

			if (this.selectedTasks.length) {
				for (var i = 0; i < this.selectedTasks.length; i++) {
					console.log(this.selectedTasks[i].category, category);
					if (this.selectedTasks[i].categoryId == category.id) {
						this.tasksToBeDeleted.push(this.selectedTasks[i]);
					}
				}
			}

			if (this.tasksToBeDeleted.length) {
				this.deleteDialog = true;
				this.deleteText =
					this.tasksToBeDeleted.length +
					" tasks from " +
					category.name;
			} else {
				this.deleteText = category.name;
				this.tasksToBeDeleted = category.id;
				this.deleteDialog = true;
			}
			console.log("master checklist", this.masterChecklist);
		},
		DeleteTasks: async function () {
			var checklistRef = db
				.collection("checklists")
				.doc(this.checklistId);
			if (typeof this.tasksToBeDeleted === "string") {
				// deleting an entire category
				try {
					let masterIndex = this.getIndexById(
						this.tasksToBeDeleted,
						this.masterChecklist
					);
					this.masterChecklist.splice(masterIndex, 1);

					let categoriesRef = checklistRef
						.collection("checklist_categories")
						.doc(this.tasksToBeDeleted);

					let tasksCollectionRef =
						categoriesRef.collection("checklist_tasks");

					let tasksQuery = await tasksCollectionRef.get();

					let deleteBatch = writeBatch(db);
					tasksQuery.forEach((doc) => {
						console.log("delete: " + doc.id);
						let docRef = tasksCollectionRef.doc(doc.id);
						deleteBatch.delete(docRef);
					});

					deleteBatch.delete(categoriesRef);

					await deleteBatch.commit();

					this.deleteDialog = false;
					this.selectedTasks = [];
					this.currentCategoryId = "";
				} catch (error) {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error);
				}
			} else {
				// delete selected tasks
				try {
					let masterIndex = this.getIndexById(
						this.currentCategoryId,
						this.masterChecklist
					);
					let deleteBatch = writeBatch(db);
					let tasksRef = db
						.collection("checklists")
						.doc(this.checklistId)
						.collection("checklist_categories")
						.doc(this.currentCategoryId)
						.collection("checklist_tasks");
					for (
						var i =
							this.masterChecklist[masterIndex].tasks.length - 1;
						i >= 0;
						i--
					) {
						for (var j = 0; j < this.tasksToBeDeleted.length; j++) {
							if (
								this.masterChecklist[masterIndex].tasks[i].id ==
								this.tasksToBeDeleted[j].id
							) {
								console.log(
									"splice",
									this.masterChecklist[masterIndex].tasks[i]
								);
								this.masterChecklist[masterIndex].tasks.splice(
									i,
									1
								);
								let docRef = tasksRef.doc(
									this.tasksToBeDeleted[j].id
								);
								deleteBatch.delete(docRef);
								break; // break inner loop since we just deleted the current index
							}
						}
					}

					await deleteBatch.commit();

					this.deleteDialog = false;
					// this.selectedTasks = [];
					/* eslint-disable-next-line no-redeclare */
					for (var i = this.selectedTasks.length - 1; i >= 0; i--) {
						let index = this.getIndexById(
							this.selectedTasks[i].id,
							this.tasksToBeDeleted
						);
						if (index >= 0) {
							this.selectedTasks.splice(i, 1);
						}
					}

					this.currentCategoryId = "";
				} catch (error) {
					console.error("Error updating document: ", error);
				}
			}
		},
		SaveCategorySort: async function () {
			try {
				let updateCategories = [];
				for (let i = 0; i < this.masterChecklist.length; i++) {
					if (this.masterChecklist[i].sort != i) {
						this.masterChecklist[i].sort = i;
						updateCategories.push({
							id: this.masterChecklist[i].id,
							sort: i,
						});
					}
				}

				if (!updateCategories.length) {
					return;
				}

				await this.savePropertyChecklistCategorySort({
					updateCategories: updateCategories,
					id: this.checklistId,
				});
			} catch (e) {
				console.error("Error updating category sort: ", e);
			}
		},
		SortCategoryItems: async function (event, category) {
			try {
				let payload = {
					checklistId: this.checklistId,
					category: category,
				};

				if (event.added) {
					console.log("event is: added");
					payload.add = event.added.element;
					payload.add.sort = event.added.newIndex;
				}

				if (event.removed) {
					console.log("event is: removed");
					payload.remove = event.removed.element;
				}

				let masterIndex = this.getIndexById(
					category.id,
					this.masterChecklist
				);

				let updateData = [];
				for (
					let i = 0;
					i < this.masterChecklist[masterIndex].tasks.length;
					i++
				) {
					if (this.masterChecklist[masterIndex].tasks[i].sort == i) {
						continue;
					}
					updateData.push({
						id: this.masterChecklist[masterIndex].tasks[i].id,
						sort: i,
					});
					this.masterChecklist[masterIndex].tasks[i].sort = i;
				}

				if (updateData.length) {
					payload.update = updateData;
				}

				let addedData = await this.savePropertyChecklistTaskSort(
					payload
				);

				if (addedData.newTask) {
					let itemIndex = this.getIndexById(
						event.added.element.id,
						this.masterChecklist[masterIndex].tasks
					);
					if (itemIndex >= 0) {
						this.masterChecklist[masterIndex].tasks[itemIndex].id =
							addedData.newTask.id;
					}
				}
			} catch (e) {
				console.error("Error updating task sort: ", e);
			}
		},
		setSorting: function (sorting) {
			console.log("set sorting: " + sorting);
			this.sorting = sorting;
		},
		getIndexById: function (itemId, checklistArray) {
			let index = checklistArray.findIndex((element) => {
				if (element.id == itemId) {
					return true;
				}
			});

			return index;
		},
	},
};
</script>

<style scoped>
.v-icon {
	cursor: pointer;
}
.task-card {
	border-radius: 15px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
	min-height: 200px;
	max-height: 400px;
	overflow-y: scroll;
	padding: 12px;
	background-color: white;
}
.task-card-list {
	min-height: auto;
	max-height: unset;
	margin-bottom: 15px;
}
.add-task-card:hover {
	transform: scale(1.03);
	cursor: pointer;
}
.add-task-text {
	color: gray;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.property-wrapper {
	width: 100%;
	height: 180px;
	background: rgb(196, 196, 196, 0.15);
	position: relative;
	border-bottom: 0.5px solid gray;
	overflow-x: scroll;
	overflow-y: hidden;
}
.property-cards-wrap {
	display: grid;
	grid-template-columns: 23% 23% 23% 23%;
	row-gap: 24px;
	justify-content: space-between;
	padding-left: 0px;
}
* {
	font-family: "cabin_regular";
	font-size: 16px;
	font-weight: normal;
}

.visible {
	visibility: visible;
}
.invisible {
	visibility: hidden;
}
.red {
	color: rgb(243, 115, 115) !important;
	margin-top: 2px !important;
}

::-webkit-scrollbar {
	display: none;
}
.mb2 {
	background-image: none;
	background-color: #f9f9f9;
}
.header-logged-in {
	background-color: rgb(49, 43, 65);
}
.grayBtn {
	color: #6e6d6d;
	background-color: none;
}
.main-container {
	max-width: 1200px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0px 24px;
	min-height: 100vh;
}
.back-home {
	width: 200px;
	background-color: #f9f9f9;
	position: absolute;
	top: 110px;
	left: 16px;
	padding: 8px;
	text-align: center;
	line-height: 32px;
	vertical-align: middle;
	cursor: pointer;
	transition: background-color 0.2s;
	border-radius: 2px;
}
.back-home:hover {
	background-color: rgba(160, 160, 160, 0.603);
}
.arrow {
	width: 24px;
	height: auto;
	transform: rotate(90deg);
	filter: invert(20%) sepia(99%) saturate(2056%) hue-rotate(241deg)
		brightness(82%) contrast(91%);
	vertical-align: middle;
	margin-left: -24px;
	margin-right: 16px;
	margin-bottom: 3px;
}
.submit-btn {
	width: 200px;
	height: 42px;
	border-radius: 30px;
	background-color: rgba(108, 60, 210, 0.85);
	color: #f9f9f9;
	line-height: 42px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
	margin-top: 16px;
	transition: all 0.2s;
	cursor: pointer;
	outline: none;
}
.submit-btn:hover {
	background-color: #5e17eb;
}
.submit-btn:focus {
	outline: rgba(108, 60, 210, 0.85);
}
.header-space {
	height: 110px;
	width: 100%;
}
.v-icon {
	margin-right: 6px;
}
.purple-checklist-title {
	color: #1d085a;
	font-size: 24px;
	font-family: "cabin_title";
	margin-bottom: 12px;
	padding-top: 2px;
	margin-top: 0px !important;
	text-decoration: none;
	display: inline-block;
}
.properties-wrapper {
	border: 1px solid gray;
	margin-top: 8px;
}
.property-wrapper {
	width: 100%;
	height: 180px;
	background: rgb(196, 196, 196, 0.15);
	position: relative;
	border-bottom: 0.5px solid gray;
	overflow-x: scroll;
	overflow-y: hidden;
	cursor: pointer;
}
.checklist-container {
	box-shadow: none;
	width: 100%;
}
.checklist-add {
	color: #5e17eb;
	background-color: white;
}
td img {
	vertical-align: middle;
	margin-right: 16px;
	padding-bottom: 2px;
}
.checklist-table tr:hover {
	border: 1px solid #5e17eb;
}
.no-hover {
	border: none !important;
}
.tableTD,
.tableTH {
	border: none;
	box-shadow: none;
	text-align: left;
	padding: 16px;
	height: 30px;
	vertical-align: middle;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
}
.sub-task {
	display: inline;
	list-style-type: disc;
	padding: 0px 4px;
	color: rgb(105, 133, 143);
}
.property-wrapper:hover {
	border: 3px solid rgba(108, 60, 210, 0.85);
}
.whiteitem {
	background: white;
}
.property-pic {
	width: 140px;
	height: 140px;
	margin: 20px 40px;
}
.empty-checkbox {
	height: 40px;
	width: 40px;
	border: 4px solid gray;
	float: left;
	margin-top: 65px;
	margin-left: 40px;
}
.flex-checklist {
	display: inline-flex;
	height: 100%;
	vertical-align: top;
}
.property-title {
	font-size: 24px;
	color: #5e17eb;
	margin-top: 16px;
	text-decoration: underline;
}

.clear-btn {
	color: #5e17eb !important;
	float: right;
	margin-top: 46px;
	background-color: transparent !important;
	box-shadow: none !important;
}
.checklist-text {
	line-height: 58px;
}
.checklist-text,
.checklist-img {
	display: inline;
	vertical-align: middle;
	margin: 0px 8px;
}
.add-property {
	width: 100%;
	margin: auto;
	background: rgb(196, 196, 196, 0.15);
	border-radius: 4px;
	border: 1px solid gray;
	height: 180px;
	margin-top: 60px;
	text-align: center;
	cursor: pointer;
}
.add-property:hover {
	background-color: rgb(196, 196, 196, 0.55);
	border: 3px solid rgba(108, 60, 210, 0.85);
}
.add-property p,
.drop-area div p {
	font-size: 26px;
	color: gray;
	margin-top: 8px;
}
.property-name-field {
	width: 100%;
}
.property-name-field:focus {
	outline: none !important;
	border: 1px solid #5e17eb;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.v-dialog > .v-card > .v-card__title {
	padding: 16px 16px 10px !important;
}
.plus-border {
	border-radius: 4px;
	border: 1px solid rgba(128, 128, 128, 0.788);
	padding: 8px;
	width: 80px;
	margin: auto;
	margin-top: 24px;
	background-color: rgba(128, 128, 128, 0.788);
}
.plus-icon {
	width: 60px;
	height: auto;
	filter: invert(80%);
	-webkit-filter: invert(80%);
	margin-top: 4px;
}
.add-property-img {
	width: 80px;
	height: 80px;
}
.drop-area {
	border: 2px solid #5e17eb;
	width: 50%;
	height: auto;
	margin: auto;
	margin-top: 72px;
	margin-bottom: 32px;
	cursor: pointer;
	background-color: rgba(168, 168, 168, 0.15);
	padding-bottom: 16px;
}
.drop-area:hover {
	background-color: rgba(168, 168, 168, 0.35);
}
.info-wrapper {
	display: inline-block;
	vertical-align: top;
	height: 100%;
}
.property-icon {
	margin-right: 8px;
	display: inline;
}
.info-wrapper div {
	margin-top: 24px;
}
.info-wrapper p,
.property-icon {
	display: inline-block;
	vertical-align: middle;
}
.nav-item {
	float: right;
	margin-top: 0px;
	cursor: pointer;
	vertical-align: middle;
}
.v-application--wrap {
	min-height: auto !important;
}
.absolute-back {
	position: absolute;
	left: 42px;
	top: 110px;
	color: #5e17eb;
}
.absolute-back:hover {
	border-radius: 40px;
	background-color: rgba(172, 163, 190, 0.537);
}
.back-arrow {
	position: absolute;
}
#overlay {
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	background-color: black;
	background-color: rgba(0, 0, 0, 0.75);
}

#inspire {
	width: 300px;
	position: absolute;
	left: 50%;
	top: 100px;
	margin-left: -150px;
}
.v-application--wrap {
	display: inline !important;
}
.delete-icon {
	width: 16px;
	height: auto;
	position: absolute;
	right: 16px;
	cursor: pointer;
}
.delete-icon:hover {
	background: rgba(228, 47, 47, 0.5);
}
#image-gallery {
	display: inline;
	background-color: #f9f9f9 !important;
	float: right;
}
.checklist-table tr {
	background-color: white;
	border: 1px #c4c4c4;
}
.checklist-table tr:nth-child(odd) {
	background-color: rgba(172, 163, 190, 0.537);
}
.checklist-table tr:hover {
	border: 1px solid #5e17eb;
}
.checklist-wrap {
	border: none;
	box-shadow: none;
	text-align: left;
	padding: 16px;
	height: 30px;
	vertical-align: middle;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
}
.task-title {
	display: inline;
	font-size: 16px;
}
.task-notes {
	display: inline;
	color: rgb(97, 97, 97);
}
.right-arrow,
.left-arrow {
	float: right;
	width: 16px;
	height: auto;
	margin-top: 0px;
	transform: rotate(-90deg);
}
.back-btn {
	width: 150px;
	border-radius: 40px;
	vertical-align: middle;
	padding: 6px;
	cursor: pointer;
	margin: 8px 0px;
	margin-left: -10px;
	position: relative;
	font-size: 14px;
	color: rgb(160, 160, 160);
}
.back-btn:hover {
	background-color: rgba(160, 160, 160, 0.281);
}
.back-txt {
	display: inline;
	vertical-align: middle;
	line-height: 32px;
	font-size: 14px;
	margin-left: 6px;
}
.left-arrow {
	transform: rotate(90deg);
	float: left;
}
@media screen and (max-width: 1200px) {
	.property-cards-wrap {
		grid-template-columns: 32% 32% 32%;
	}
}
@media screen and (max-width: 850px) {
	.drop-area {
		width: 100%;
	}
	.property-cards-wrap {
		grid-template-columns: 100%;
	}
}
</style>
