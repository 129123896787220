import { db } from "@/firebase";

const adminUserApi = {
	// POST
	createNewProperty: async function (newProperty) {
		let newPropertyDoc = db.collection("properties").doc();
		newProperty.id = newPropertyDoc.id;
		await newPropertyDoc.set(newProperty);
	},

	// PUT
	saveEditedProperty: function (editedProperty) {
		let res = {
			status: 0,
			message: "",
		};
		let editPropertyDoc = db
			.collection("properties")
			.doc(editedProperty.id);
		return editPropertyDoc
			.update(editedProperty)
			.then(() => {
				res.status = 201;
				res.message = "Property successfully updated";
				return res;
			})
			.catch((error) => {
				console.error("Error editing property: ", error);
				res.status = 400;
				res.message = "Could not edit property. Does it exist?";
				return res;
			});
	},

	// DELETE
	deleteProperty: function (deletePropertyId) {
		let res = {
			status: 0,
			message: "",
		};
		let editPropertyDoc = db.collection("properties").doc(deletePropertyId);
		return editPropertyDoc
			.delete()
			.then(() => {
				res.status = 202;
				res.message = "Property Successfully Deleted";
				return res;
			})
			.catch((error) => {
				console.error("Error deleting property: ", error);
				res.status = 400;
				res.message = "Could not delete property. Does it exist?";
				return res;
			});
	},
};

export default adminUserApi;
