<template>
	<v-dialog v-model="addDialog" persistent max-width="700px" class="big">
		<v-card style="padding-bottom: 24px">
			<v-card-title>
				<span class="headline" style="font-weight: bold"
					>Create an event</span
				>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col>
							<v-row>
								<v-col sm="12">
									<v-date-picker
										class="picker"
										v-model="picker"
										color="#1D085A"
										range
									></v-date-picker>
								</v-col>
							</v-row>
						</v-col>
						<v-col>
							<v-row>
								<v-col>
									<v-row>
										<v-col>
											<v-text-field
												label="Start Time"
												color="#1D085A"
												class="timeBox"
												dense
												outlined
												v-model="convertedStart"
												@click="
													startTimeDialog =
														!startTimeDialog
												"
											></v-text-field>
											<v-dialog
												v-model="startTimeDialog"
												width="unset"
											>
												<v-card
													style="
														width: 290px;
														margin: 0 auto;
													"
												>
													<v-time-picker
														format="ampm"
														scrollable
														color="#5E17EB"
														v-model="startTime"
													></v-time-picker>
													<v-card-actions>
														<v-spacer />
														<v-btn
															color="#5E17EB"
															text
															@click="
																startTimeDialog =
																	!startTimeDialog;
																startTime = '';
															"
														>
															Clear
														</v-btn>
														<v-btn
															color="#5E17EB"
															text
															@click="
																saveStartTime()
															"
														>
															Okay
														</v-btn>
													</v-card-actions>
												</v-card>
											</v-dialog>
										</v-col>
										<v-col>
											<v-text-field
												label="End Time"
												color="#1D085A"
												class="timeBox"
												dense
												outlined
												v-model="convertedEnd"
												@click="
													endTimeDialog =
														!endTimeDialog
												"
											></v-text-field>
											<v-dialog
												v-model="endTimeDialog"
												width="unset"
											>
												<v-card
													style="
														width: 290px;
														margin: 0 auto;
													"
												>
													<v-time-picker
														format="ampm"
														scrollable
														color="#5E17EB"
														v-model="endTime"
													></v-time-picker>
													<v-card-actions>
														<v-spacer />
														<v-btn
															color="#5E17EB"
															text
															@click="
																endTimeDialog =
																	!endTimeDialog;
																endTime = '';
															"
														>
															Clear
														</v-btn>
														<v-btn
															color="#5E17EB"
															text
															@click="
																saveEndTime()
															"
														>
															Okay
														</v-btn>
													</v-card-actions>
												</v-card>
											</v-dialog>
										</v-col>
									</v-row>
									<v-select
										:items="properties"
										v-model="selectedProperties"
										item-text="name"
										return-object
										outlined
										multiple
										dense
										color="#1D085A"
										label="Property"
										placeholder="Select All That Apply"
										:menu-props="{
											bottom: true,
											offsetY: true,
										}"
										style="margin-top: 24px"
									></v-select>

									<v-select
										:items="cleaners"
										v-model="selectedCleaner"
										item-text="name"
										outlined
										return-object
										dense
										color="#1D085A"
										label="Cleaner"
										placeholder="Select One"
										:menu-props="{
											bottom: true,
											offsetY: true,
										}"
										style="margin-top: 0px"
									></v-select>

									<!-- Select a color for the event
                  <v-color-picker
                    :swatches="swatches"
                    v-model="eventColor"
                    show-swatches
                    hide-canvas
                    hide-inputs
                    hide-sliders
                  >
                  </v-color-picker> -->

									Select a service:
									<v-radio-group
										v-model="serviceName"
										mandatory
									>
										<v-radio
											label="Booking"
											value="Booking"
											color="#1D085A"
										></v-radio>
										<!-- <v-radio label="Maintenance" value="Maintenance" color="#1D085A"></v-radio> -->
										<v-radio
											label="Cleaning"
											value="Cleaning"
											color="#1D085A"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="#5E17EB"
					width="200px"
					dark
					@click="createEvent()"
				>
					<v-icon color="white">mdi-plus</v-icon>Add
				</v-btn>
				<v-btn color="#5E17EB" text @click="closeDialog">
					Cancel
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// import VueTimePicker from "vue2-timepicker/src/vue-timepicker.vue";
import { db } from "../firebase";
import firebase from "firebase/compat/app";
import moment from "moment";
// import { v1 as uuidv4 } from "uuid";
import { mapState, mapActions } from "vuex";

export default {
	components: {
		// VueTimePicker
	},
	props: {
		addDialog: Boolean,
		propStart: String,
		propTime: String,
	},
	data: () => ({
		selectedProperties: [],
		serviceName: "",
		startTime: "",
		endTime: "",
		startTimeDialog: false,
		endTimeDialog: false,
		eventColor: "",
		cleaners: [],
		properties: [],
		events: [],
		selectedCleaner: "",
		picker: [new Date().toISOString().substr(0, 10)],
		swatches: [
			["#FA4A62"],
			["#E0634C"],
			["#624CE0"],
			["#E0AD28"],
			["#12E6C2"],
		],
		convertedStart: "",
		convertedEnd: "",
	}),
	created() {
		this.loadProps();
		this.getEvents();
		this.getCleaners();
		this.bindProperties().then((res) => {
			this.properties = res;
		});
	},
	computed: {
		...mapState("adminUser", ["adminId"]),
	},
	methods: {
		...mapActions("adminUser", ["bindProperties"]),
		closeDialog() {
			this.$emit("addEventDialogClosed");
		},
		loadProps() {
			if (this.propStart) {
				this.picker[0] = this.propStart;
			}
			this.startTime = this.propTime;
		},
		getEvents() {
			db.collection("events")
				.where("admin_id", "==", this.adminId)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						var data = doc.data();
						data.id = doc.id;
						this.events.push(data);
					});
				});
		},
		async createEvent() {
			var propIds = [];
			for (let i = 0; i < this.selectedProperties.length; i++) {
				propIds.push(this.selectedProperties[i].id);
			}
			var picker0 = firebase.firestore.Timestamp.fromDate(
				new Date(this.picker[0] + "T" + this.startTime)
			);
			var picker1;
			console.log("Picker 1", this.picker1);
			if (this.picker[1]) {
				picker1 = firebase.firestore.Timestamp.fromDate(
					new Date(this.picker[1] + "T" + this.endTime)
				);
			} else {
				picker1 = null;
			}

			await db
				.collection("events")
				.add({
					admin_id: this.adminId,
					name:
						this.serviceName +
						" at " +
						this.selectedProperties[0].name,
					start: picker0 ? picker0 : "",
					end: picker1 ? picker1 : "",
					status: "pending",
					cleaners: this.selectedCleaner
						? this.selectedCleaner.id
						: "",
					property: this.selectedProperties.length
						? {
								name: this.selectedProperties[0].name,
								id: this.selectedProperties[0].id,
								image: this.selectedProperties[0].main_image,
						  }
						: {},
					properties: propIds ? propIds : [],
					service_name: this.serviceName,
				})
				.then(() => {
					console.log("added event to property");
					return true;
				})
				.catch((err) => {
					console.log("error adding event to property", err);
				});
			this.closeDialog();
		},
		getCleaners() {
			db.collection("users_admin")
				.doc(this.adminId)
				.get()
				.then((doc) => {
					var cleaners = doc.data().cleaners;
					for (let i = 0; i < cleaners.length; i++) {
						db.collection("users_vendor")
							.doc(cleaners[i])
							.get()
							.then((vendorDoc) => {
								var vendor = vendorDoc.data();
								vendor.id = vendorDoc.id;
								this.cleaners.push(vendor);
							})
							.catch((err) => {
								console.log("Error appending cleaner", err);
							});
					}
				})
				.catch((err) => {
					console.log("Error with admin id", err);
				});
		},
		saveStartTime() {
			this.startTimeDialog = !this.startTimeDialog;
			this.convertedStart = moment(this.startTime, "HH:mm").format("LT");
		},
		saveEndTime() {
			this.endTimeDialog = !this.endTimeDialog;
			this.convertedEnd = moment(this.endTime, "HH:mm").format("LT");
		},
	},
};

// To-do:
//   - Edit and Delete Events
//   - Can't pass in dates that are backwards
//   - time dialogs being cut off
//   - Text color of events
//   - Assign a property a color
//   - Add filter by Service and Property to the Calendar
//  - fix the single date problem on the calendar
//  - add required fields on calendar
// - clear add event dialog after creation
</script>

<style scoped>
.picker {
	border: solid 1px #1d085a;
}

.TimeInput {
	border: solid red;
}

.timePicker {
	border: solid 1px #1d085a;
}

.big {
	height: 1000px !important;
}
h4 {
	font-size: 16px;
	color: #1d085a;
}
.timeBox {
	height: 40px;
	cursor: pointer;
}
/* .vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #1D085A;
overflow: visible;
}

.vue__time-picker-dropdown {
  z-index: 5000;
} */
</style>
