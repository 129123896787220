<template>
	<v-app>
		<div class="app-div">
			<!-- <Header></Header> -->
			<div class="login-wrapper">
				<h2>Dormi</h2>
				<div class="login-box" v-if="loginType == ''">
					<h3>Sign in to your account</h3>
					<div class="loginTypeWrap">
						<div class="login-card" @click="loginType = 'owner'">
							<img
								class="login-logo"
								src="../assets/icons/Dormi-crop.png"
							/>
							<p class="login-txt">Sign in as Admin</p>
						</div>
						<div class="login-card" @click="loginType = 'vendor'">
							<img
								class="login-logo"
								src="../assets/icons/cleaning_icon.svg"
							/>
							<p class="login-txt">Sign in as Cleaner</p>
						</div>
					</div>
					<p class="login-box-text center-text">
						Not a Dormi member yet?<router-link to="/register">
							Sign Up Here</router-link
						>
					</p>
				</div>

				<div class="login-box" v-else>
					<h3>Sign in to your account</h3>

					<v-form ref="form" v-model="valid" lazy-validation>
						<v-text-field
							id="login-email"
							ref="email"
							@keydown.enter="FormKeyDown"
							color="#11023e"
							outlined
							:rules="emailRules"
							v-model="email"
							type="email"
							label="Email"
						></v-text-field>
						<v-text-field
							id="login-password"
							ref="password"
							@keydown.enter="FormKeyDown"
							color="#11023e"
							outlined
							:rules="passwordRules"
							type="password"
							min="8"
							v-model="password"
							label="Password"
						></v-text-field>

						<p
							class="forgot-password center-text"
							@click="ForgotPassword()"
						>
							Forgot password?
						</p>
						<v-btn
							:disabled="!valid"
							v-if="loginType == 'owner'"
							@click="GeneralLogin"
							color="#5e17eb"
							dark
							width="100%"
							>Continue</v-btn
						>
						<v-btn
							:disabled="!valid"
							v-else
							@click="GeneralLogin"
							color="#5e17eb"
							dark
							width="100%"
							>Continue</v-btn
						>
					</v-form>

					<ul v-for="(err, index) in formErrors" v-bind:key="index">
						<li
							style="
								color: red;
								list-style-type: disc;
								margin-top: 16px;
							"
						>
							{{ err }}
						</li>
					</ul>

					<div style="display: flex; margin-top: 32px">
						<v-divider style="margin-top: 10px" />
						<p
							style="
								width: 48px;
								text-align: center;
								margin-bottom: 0px;
							"
						>
							OR
						</p>
						<v-divider style="margin-top: 10px" />
					</div>
					<div style="text-align: center; height: 48px">
						<img
							class="login-btn google-btn"
							src="../assets/icons/googleLogin.png"
							@click="DormiGoogleLogin()"
						/>
					</div>
					<p class="login-box-text center-text">
						Not a Dormi member yet?<router-link to="/register">
							Sign Up Here</router-link
						>
					</p>
				</div>
			</div>
		</div>
	</v-app>
</template>

<script>
import { auth } from "../firebase";
import { provider } from "../firebase";
import { db } from "../firebase";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "Login",
	metaInfo: {
		title: "Login",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Login with Dormi. Get ready to experience simplicity at its finest in your vacation rental management.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		// Header
	},
	data() {
		return {
			loginType: "owner",
			password: null,
			formErrors: [],

			firstName: "",
			lastName: "",
			email: null,
			valid: true,

			emailRules: [
				(v) => !!v || "E-mail is required",
				(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
			],
			passwordRules: [(v) => !!v || "Password is required"],

			firstNameError: false,
			lastNameError: false,
			emailError: false,
			userError: false,
		};
	},
	computed: {},
	methods: {
		...mapGetters("adminUser", ["getError"]),
		...mapActions("adminUser", ["loginAdminUser", "loginGoogleAdminUser"]),
		...mapGetters("vendorUser", ["getError"]),
		...mapActions("vendorUser", [
			"loginVendorUser",
			"loginGoogleVendorUser",
		]),
		DormiLogin() {
			this.formErrors = [];
			this.loginAdminUser({
				email: this.email,
				password: this.password,
			}).catch((error) => {
				this.formErrors.push(error.message);
			});
		},
		GeneralLogin() {
			if (this.loginType == "owner") {
				this.DormiLogin();
			} else {
				this.VendorLogin();
			}
		},
		VendorLogin() {
			this.formErrors = [];
			this.loginVendorUser({
				email: this.email,
				password: this.password,
			});
			// .catch((error) => {
			//   var errorCode = error.code;
			//   var errorMessage = error.message;
			//   console.log("Error Signing In:", errorCode, errorMessage);
			//   this.formErrors.push(errorMessage);
			// });
		},
		DormiGoogleLogin() {
			this.formErrors = [];
			auth.signInWithPopup(provider)
				.then((result) => {
					/** @type {firebase.auth.OAuthCredential} */
					// var credential = result.credential;
					var user = result.user;
					var isNewUser = result.additionalUserInfo.isNewUser;
					if (isNewUser) {
						//delete the created user
						result.user.delete();
						this.userError = true;
						this.formErrors.push("This user does not exist yet.");
						return;
					} else {
						// your sign in flow
						console.log("user", user, user.uid);
						console.log("user " + user.email + " does exist!");
					}
					if (this.loginType == "owner") {
						this.loginGoogleAdminUser(user);
						this.$router.push({ name: "Properties" });
					} else {
						this.loginGoogleVendorUser(user);
						this.$router.push({ name: "Vendor_Properties" });
					}
				})
				.catch((error) => {
					console.log(error.code, error.message);
				});
		},
		newUser: function (user, userType) {
			const usersRef = db.collection(userType).doc(user.uid);
			usersRef.get().then((docSnapshot) => {
				if (docSnapshot.exists) {
					usersRef.onSnapshot((doc) => {
						console.log("doc exists", doc);
					});
				} else {
					usersRef.set({
						email: user.email,
						name: user.displayName,
					}); // create the document
				}
			});
		},
		SubmitClicked() {
			if (this.ValidateDemoRegistraion()) {
				console.log("Valid demo registration! Hooray!");
			}
		},
		FormKeyDown(e) {
			if (e.target.id == "login-email") {
				this.$refs.password.focus();
			} else if (e.target.id == "login-password") {
				if (this.valid) {
					this.GeneralLogin();
				}
			}
		},
		ForgotPassword() {
			if (
				/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					this.email
				)
			) {
				this.emailError = false;
			} else {
				this.emailError = true;
				this.formErrors.push("Please enter your user email first.");
				return;
			}
			auth.sendPasswordResetEmail(this.email)
				.then(() => {
					alert("A password reset email has been sent.");
				})
				.catch((error) => {
					var errorCode = error.code;
					//   var errorMessage = error.message;
					//   console.log(errorCode, errorMessage);
					this.emailError = true;
					this.formErrors.push(
						errorCode + " - Please make sure email is valid"
					);
				});
		},
		BackHome() {
			this.$router.push({ name: "Landing" });
		},
		ValidateDemoRegistraion: function () {
			console.log("Validating login info...");
			this.firstNameError = this.lastNameError = this.emailError = false;
			if (this.firstName == "") {
				this.firstNameError = true;
			}
			if (this.lastName == "") {
				this.lastNameError = true;
			}
			if (
				/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					this.email
				)
			) {
				this.emailError = false;
			} else {
				this.emailError = true;
			}
			if (this.firstNameError || this.lastNameError || this.emailError) {
				return false;
			}
			return true;
		},
	},
};
</script>

<style scoped>
.app-div {
	min-height: 100vh;
	padding: 0px 16px;
}
.visible {
	visibility: visible;
}
.invisible {
	visibility: hidden;
}
.red {
	color: rgb(243, 115, 115) !important;
	margin-top: 2px !important;
}
h2,
h3 {
	text-align: left;
	font-size: 24px;
	margin: 32px 0px;
	font-family: "cabin_bold";
	color: #11023e;
}
h3 {
	font-family: "cabin_regular";
	margin: 16px 0px 32px 0px;
}
::-webkit-scrollbar {
	display: none;
}
.login-background {
	background-color: whitesmoke;
	height: 100%;
	width: 100%;
	position: relative;
}
.login-wrapper {
	max-width: 500px;
	margin: 16px auto;
	margin-bottom: 32px;
}
.dormi-logo {
	width: 160px;
	height: auto;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
}
.small-text-login {
	font-family: "cabin_regular";
	font-size: 14px;
	margin: 4px 0px;
	color: #11023e;
}
.login-card {
	width: 40%;
	height: auto;
	background-color: #5e17eb21;
	border: 1px solid #11023e67;
	border-radius: 4px;
	text-align: center;
	padding: 16px;
}
.login-card:hover {
	background-color: #5e17eb4d;
	transform: scale(1.05);
	cursor: pointer;
}
.login-btn {
	height: 48px;
	margin-top: 16px;
}
.dormi-login {
	width: 254px;
	background-color: #f9f9f9;
	padding: 8px;
	padding-top: 4px !important;
	margin-top: 16px;
	margin-bottom: 8px;
}
.login-btn:hover {
	cursor: pointer;
	transform: scale(1.02);
}
.login-logo {
	height: 32px;
	vertical-align: middle;
	width: auto;
	padding-left: 12px;
	padding-right: 16px;
}
.login-txt {
	display: block;
	font-size: 16px;
	color: #11023e;
	font-family: "cabin_bold";
	margin-top: 16px;
	margin-bottom: 8px;
}
.login-box {
	padding: 20px 40px;
	background-color: white;
	border-radius: 4px;
	text-align: left;
	position: relative;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	width: 100%;
}
.log-input {
	color: black;
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	background: white;
}
.login-box-text {
	font-size: 16px;
	color: #11023e;
	margin-top: 32px;
}
.login-box-label {
	font-size: 16px;
	color: #11023e;
	margin-top: 16px;
	margin-bottom: 0px;
}
.center-text {
	text-align: center;
}
.login-box-text a {
	color: #5e17eb;
	cursor: pointer;
	font-size: 16px;
	text-decoration: none;
}
.login-box-text a:hover {
	color: #380e8d;
}
.back-home {
	width: 160px;
	background-color: #f9f9f9;
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 8px;
	text-align: center;
	line-height: 32px;
	vertical-align: middle;
	cursor: pointer;
	transition: background-color 0.2s;
	border-radius: 2px;
}
.back-home:hover {
	background-color: rgba(160, 160, 160, 0.603);
}
.forgot-password {
	color: #5e17eb;
	cursor: pointer;
}
.forgot-password:hover {
	color: #380e8d;
}
.arrow {
	width: 24px;
	height: auto;
	transform: rotate(90deg);
	filter: invert(20%) sepia(99%) saturate(2056%) hue-rotate(241deg)
		brightness(82%) contrast(91%);
	vertical-align: middle;
	margin-left: -24px;
	margin-right: 16px;
	margin-bottom: 3px;
}
.loginTypeWrap {
	display: flex;
	justify-content: space-around;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
	background-color: rgb(206, 206, 206) !important;
}
@media screen and (max-width: 850px) {
	.dormi-logo {
		width: 120px;
	}
	.login-box {
		padding: 16px;
	}
}
@media screen and (max-width: 400px) {
	.loginTypeWrap {
		display: block;
	}
	.login-card {
		width: 100%;
		margin-bottom: 16px;
	}
}
</style>
