import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/analytics";

// firebase init - add your own config here
require("dotenv").config({ path: `.env.${process.env.NODE_ENV}` });
console.log(
	"current env:",
	process.env.NODE_ENV,
	process.env.VUE_APP_PROJECT_ID
);
const firebaseConfig = {
	apiKey: process.env.VUE_APP_API_KEY,
	authDomain: process.env.VUE_APP_AUTH_DOMAIN,
	projectId: process.env.VUE_APP_PROJECT_ID,
	storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_APP_ID,
	measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);

if (window.location.hostname === "localhost") {
	firebase.functions().useEmulator("localhost", 5001);
}
const analytics = firebase.analytics();
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();
var provider = new firebase.auth.GoogleAuthProvider();

// const onAuthStateChangedPromise = new Promise((resolve, reject) => {
//   auth.onAuthStateChanged(user => {
//     store.commit(user !== null ? 'setUser' : 'logoutUser', user)
//     resolve(user)
//   }, err => {
//     reject(err)
//   });
// });

// const onAuthStateInit = () => onAuthStateChangedPromise;

export {
	// onAuthStateInit,
	db,
	auth,
	functions,
	storage,
	provider,
	firebase,
	analytics,
};
