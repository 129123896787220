<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "App",
	components: {},
	computed: {
		user() {
			return this.$store.getters["adminUser/getUserData"];
		},
	},
	watch: {
		user: {
			handler: function () {
				this.$store.commit("adminUser/setUserLocalStorage");
			},
		},
	},
	// computed: {
	//   ...mapState(['userProfile']),
	//   showNav() {
	//     return Object.keys(this.userProfile).length > 1
	//   }
	// }
};
</script>

<style>
@font-face {
	font-family: "cabin_bold";
	src: url("./assets/fonts/Cabin/static/Cabin-SemiBold.ttf");
}
@font-face {
	font-family: "cabin_medium";
	src: url("./assets/fonts/Cabin/static/Cabin-Medium.ttf");
}
@font-face {
	font-family: "cabin_regular";
	src: url("./assets/fonts/Cabin/static/Cabin-Regular.ttf");
}
@font-face {
	font-family: "cabin_title";
	src: url("./assets/fonts/Cabin/static/Cabin-Bold.ttf");
}
* {
	font-family: "cabin_regular";
	font-size: 16px;
	font-weight: normal;
}
.white-text {
	color: white;
}
.nav-details a {
	text-decoration: none;
}
.app-div {
	overflow-x: hidden;
}
.main-container {
	padding: 0px 16px;
	margin: 0 auto;
	margin-bottom: 32px !important;
	max-width: 1400px;
}
.v-overlay__scrim {
	height: 100vh !important;
}
html,
body,
#app,
.app-div {
	background-color: #f9f9f9;
	margin: 0;
	height: auto;
}
.v-application--wrap {
	min-height: 0px !important;
}
.v-application--wrap {
	min-height: 0 !important;
}
@media screen and (max-width: 850px) {
	.v-slide-group__prev {
		display: none !important;
	}
}
</style>
