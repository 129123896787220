/* eslint-disable no-undef */
import { db } from "@/firebase";
import "firebase/compat/firestore";
import { doc, writeBatch } from "firebase/firestore";

function initialState() {
	return {};
}

const propertyChecklist = {
	namespaced: true,
	state: initialState,
	getters: {},
	mutations: {},
	actions: {
		savePropertyChecklistCategorySort: async function (context, payload) {
			const batch = writeBatch(db);

			for (let i = 0; i < payload.updateCategories.length; i++) {
				let categoryRef = doc(
					db,
					"checklists",
					payload.id,
					"checklist_categories",
					payload.updateCategories[i].id
				);
				batch.update(categoryRef, {
					sort: payload.updateCategories[i].sort,
				});
			}

			return await batch.commit();
		},
		savePropertyChecklistTaskSort: async function (context, payload) {
			let returnData = {};

			const batch = writeBatch(db);

			if (payload.add) {
				console.log("add exists");

				let taskData = Object.assign({}, payload.add);
				delete taskData.id;

				let newRef = db
					.collection("checklists")
					.doc(payload.checklistId)
					.collection("checklist_categories")
					.doc(payload.category.id)
					.collection("checklist_tasks")
					.doc();

				returnData.newTask = Object.assign({}, taskData);
				returnData.newTask.id = newRef.id;

				batch.set(newRef, taskData);
			}

			if (payload.update) {
				for (let i = 0; i < payload.update.length; i++) {
					let taskRef = doc(
						db,
						"checklists",
						payload.checklistId,
						"checklist_categories",
						payload.category.id,
						"checklist_tasks",
						payload.update[i].id
					);

					batch.update(taskRef, { sort: payload.update[i].sort });
				}
			}

			if (payload.remove) {
				console.log("remove exists");
				let removeRef = doc(
					db,
					"checklists",
					payload.checklistId,
					"checklist_categories",
					payload.category.id,
					"checklist_tasks",
					payload.remove.id
				);
				batch.delete(removeRef);
			}

			await batch.commit();

			return returnData;
		},
		startNewPropertyChecklist: async function (context, payload) {
			const batch = writeBatch(db);

			let checklistRef = null;
			if (payload.checklistId) {
				checklistRef = db
					.collection("checklists")
					.doc(payload.checklistId);
			} else {
				checklistRef = db.collection("checklists").doc();
				let docData = {
					property_id: payload.propertyId,
				};
				batch.set(checklistRef, docData);
			}

			for (const [key, value] of Object.entries(
				payload.starterChecklist
			)) {
				let categoryRef = db
					.collection("checklists")
					.doc(checklistRef.id)
					.collection("checklist_categories")
					.doc();
				let categoryData = {
					name: key,
					sort: value.sort,
				};
				batch.set(categoryRef, categoryData);

				for (var i = 0; i < value.tasks.length; i++) {
					let taskRef = db
						.collection("checklists")
						.doc(checklistRef.id)
						.collection("checklist_categories")
						.doc(categoryRef.id)
						.collection("checklist_tasks")
						.doc();
					let taskData = value.tasks[i];
					taskData["sort"] = i;
					batch.set(taskRef, taskData);
				}
			}

			return await batch.commit();
		},
	},
};

export default propertyChecklist;
