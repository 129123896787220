<template>
	<div>
		<div>
			<div class="property-cards-wrap">
				<v-card
					v-for="(site, i) in booklet.sites"
					v-bind:key="i"
					class="property-card"
					@click="EditSite(site)"
					style="
						min-height: 100px !important;
						display: flex;
						gap: 16px;
					"
				>
					<img
						v-if="site.image"
						:src="site.image"
						height="100px"
						width="100px"
						class="card-image"
					/>
					<div>
						<v-list-item-title style="display: inline">{{
							site.name
						}}</v-list-item-title>
						<div
							style="
								font-size: 14px;
								color: #a7a7a7;
								white-space: pre-wrap;
							"
						>
							{{ site.description }}
						</div>
					</div>
				</v-card>
				<v-card
					class="property-card"
					style="
						background-color: #f4f4f4;
						min-height: 100px !important;
					"
					@click="NewSite()"
				>
					<div class="add-property-card">
						<v-icon color="gray">mdi-plus</v-icon>New Site
					</div>
				</v-card>
			</div>
		</div>

		<!-- History Dialog -->
		<v-dialog
			v-model="siteDialog"
			width="80%"
			transition="dialog-bottom-transition"
			class="dialog"
			scrollable
		>
			<v-card style="overflow-x: auto; padding: 32px; position: relative">
				<div
					style="
						position: absolute;
						right: 16px;
						top: 16px;
						color: #a7a7a7;
						cursor: pointer;
					"
					@click="siteDialog = false"
				>
					X
				</div>
				<div
					v-if="editingSite"
					class="purple-title2"
					style="margin-top: 0px"
				>
					{{ currentSite.name }}
				</div>
				<div v-else class="purple-title2" style="margin-top: 0px">
					New Site
				</div>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						outlined
						color="#1D085A"
						label="Name"
						v-model="currentSite.name"
					></v-text-field>
					<v-text-field
						outlined
						color="#1D085A"
						placeholder="Include https://"
						label="Website URL"
						:error-messages="urlErrors"
						v-model="currentSite.link"
					></v-text-field>
					<v-file-input
						color="#5e17eb"
						label="Upload site image"
						prepend-inner-icon="mdi-image"
						:prepend-icon="null"
						class="property-name-field"
						outlined
						v-model="siteImage"
						@change="NewHistoryImage()"
					></v-file-input>
					<v-textarea
						outlined
						color="#1D085A"
						label="Description"
						v-model="currentSite.description"
					></v-textarea>
				</v-form>

				<div v-if="loadingImage" class="loader">
					<v-progress-circular
						indeterminate
						color="#5E17EB"
					></v-progress-circular>
					<span
						>If this takes longer than a few seconds, you may want
						to use a smaller image</span
					>
				</div>

				<v-row align="center" style="padding: 0px 16px">
					<v-btn
						v-if="!editingSite"
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="AddSite()"
						><v-icon color="white" style="margin-right: 16px"
							>mdi-plus</v-icon
						>Add</v-btn
					>
					<v-btn
						v-else
						color="#5E17EB"
						dark
						style="width: 240px; margin-right: 32px"
						@click="DoneEditingSite()"
						>Done</v-btn
					>

					<v-btn light style="width: fit" @click="CancelSite()"
						>Cancel</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						v-if="editingSite"
						color="error"
						dark
						@click="DeleteSite()"
						>Delete</v-btn
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { db, firebase, storage } from "../../firebase";
let uuid = require("uuid");

export default {
	props: {
		booklet: Object,
		bookletId: String,
	},
	data() {
		return {
			siteDialog: false,
			editingSite: false,
			currentSite: "",
			currentSiteIndex: "",
			siteImage: null,
			valid: false,
			loadingImage: false,
			urlErrors: [],
		};
	},
	methods: {
		findProperty() {
			this.$emit("findProperty");
		},
		isBadUrl(property) {
			this.$emit("isBadUrl", property);
		},
		StoreImage: async function (image) {
			var imageName = uuid.v1();
			var metadata = {
				contentType: "image/png",
			};
			var storageRef = storage.ref();
			var imageRef = storageRef.child(`dormi-public/${imageName}.png`);
			await imageRef.put(image, metadata);
			var downloadURL = await imageRef.getDownloadURL();
			return downloadURL;
		},

		// HISTORY FUNCTIONS
		NewSite: function () {
			this.siteDialog = true;
			this.editingSite = false;
			this.currentSite = { name: "", description: "" };
		},
		NewHistoryImage: function () {
			var obj = URL.createObjectURL(this.siteImage);
			console.log(obj, this.siteImage);
		},
		AddSite: async function () {
			if (this.isBadUrl(this.currentSite.link)) {
				console.log("bad url");
				return false;
			}
			if (this.siteImage) {
				this.loadingImage = true;
				this.currentSite.image = await this.StoreImage(this.siteImage);
				this.loadingImage = false;
			}
			console.log("uploading image", this.currentSite.image);
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					sites: firebase.firestore.FieldValue.arrayUnion(
						this.currentSite
					),
				})
				.then(() => {
					console.log("Document updated");
					this.siteDialog = false;
					this.siteIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		EditSite: function (site, index) {
			this.siteDialog = true;
			this.editingSite = true;
			this.currentSite = site;
			this.originalBooklet = this.booklet;
			this.siteIndex = index;
			this.siteImage = null;
		},
		CancelSite: function () {
			this.siteDialog = false;
		},
		DoneEditingSite: async function () {
			if (this.isBadUrl(this.currentSite.link)) {
				console.log("bad url");
				return false;
			}
			if (this.siteImage) {
				this.loadingImage = true;
				this.currentSite.image = await this.StoreImage(this.siteImage);
				this.loadingImage = false;
			}
			this.booklet.sites[this.siteIndex] = this.currentSite;
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					sites: this.booklet.sites,
				})
				.then(() => {
					console.log("Document updated");
					this.siteDialog = false;
					this.siteIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		DeleteSite: function () {
			db.collection("booklets")
				.doc(this.bookletId)
				.update({
					sites: firebase.firestore.FieldValue.arrayRemove(
						this.currentSite
					),
				})
				.then(() => {
					console.log("Document deleted");
					this.siteDialog = false;
					this.siteIndex = null;
					this.findProperty();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
	},
};
</script>

<style scoped>
.loader {
	margin: 32px;
	margin-top: 0px;
}
.loader > span {
	margin-left: 32px;
}
</style>
