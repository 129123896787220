import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
// import { auth } from './firebase'

import AOS from "aos";
import "aos/dist/aos.css";
import vuetify from "@/plugins/vuetify";
import VueMeta from "vue-meta";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);
Vue.use(VueMeta);

AOS.init();

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	VueTelInput,
	VueMeta,
	beforeCreate() {
		this.$store.commit("adminUser/initializeStore");
		this.$store.commit("vendorUser/initializeStore");
	},
	render: (h) => h(App),
}).$mount("#app");
