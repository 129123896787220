<template>
	<div class="app-div">
		<Header></Header>
		<PropertyNav></PropertyNav>
		<div class="main-container">
			<h3 class="purple-title">About</h3>
			<div
				style="
					display: flex;
					min-height: 500px;
					justify-content: space-between;
				"
			>
				<div class="split">
					<p class="about-text">
						We allow you to do less, so your business can do more.
						See how our communication is the easiest and most
						effective in the industry... Show More
					</p>
					<v-divider style="margin: 16px 0px"></v-divider>
					<div style="color: #a7a7a7">
						<v-icon color="#5E17EB" style="margin-right: 16px"
							>mdi-map-marker</v-icon
						>{{ currentProperty.address }}
					</div>
					<v-divider style="margin: 16px 0px"></v-divider>
					<div style="color: #a7a7a7">
						<v-icon color="#5E17EB" style="margin-right: 16px"
							>mdi-home-outline</v-icon
						>{{ currentProperty.bedrooms }} beds |
						{{ currentProperty.bathrooms }} baths
					</div>
					<v-divider style="margin: 16px 0px"></v-divider>
				</div>
				<div
					class="split main-img"
					:style="{
						backgroundImage:
							'url(' + currentProperty.main_image.src + ')',
					}"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "../../layout/Admin/Header.vue";
import PropertyNav from "../../layout/Admin/PropertyNav.vue";
import { db } from "../../firebase";

export default {
	name: "Property",
	metaInfo: {
		title: "Property",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Detailed view of your vacation rental property. Use Dormi to manage your property checklist and digital guest booklet for each property.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
		PropertyNav,
	},
	data() {
		return {
			currentPropertyId: "",
			currentProperty: "",
		};
	},
	created() {
		this.findProperty();
	},
	methods: {
		findProperty: function () {
			this.currentPropertyId = this.$route.params.property_id;
			console.log("Id", this.currentPropertyId);
			db.collection("properties")
				.doc(this.currentPropertyId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						this.currentProperty = doc.data();
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				})
				.then(() => {
					this.SortChecklist();
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});

			console.log("Checklist: ", this.masterChecklist);
		},
	},
};
</script>

<style scoped>
.purple-title,
.purple-title2 {
	color: #1d085a;
	font-size: 36px;
	font-family: "cabin_title";
	margin-bottom: 8px;
	margin-top: 36px;
	text-decoration: none;
	display: inline-block;
}
.about-text {
	color: #a7a7a7;
}
.split {
	width: 47%;
	height: auto;
	left: 0;
}
.main-img {
	background-size: contain;
	border-radius: 15px;
	right: 0;
}
</style>
