<template>
	<div class="app-div" style="padding-bottom: 24px">
		<Header></Header>
		<div class="main-container" style="margin-top: 32px">
			<v-app>
				<h3 class="purple-checklist-title" style="margin-top: 32px">
					Cleaning Checklist
				</h3>
				<v-divider style="width: 100%; margin-bottom: 32px" />

				<p v-if="this.masterChecklist.length === 0">
					The owner has not created a checklist for this property yet.
				</p>

				<!-- <div v-if="clean_status=='assigned'">
                    <v-btn color="#5E17EB">Start Clean</v-btn>
                </div> -->

				<div v-else>
					<v-row align="center">
						<v-col class="d-flex" cols="12" sm="6">
							<v-select
								v-model="currentCategory"
								:items="masterChecklist"
								item-text="name"
								item-value="id"
								label="Category"
								@change="CategoryChanged()"
							></v-select>
						</v-col>
					</v-row>

					<v-checkbox
						v-show="this.currentCategory"
						v-model="allSelected"
						class="checkbox"
						style="margin-left: 8px"
						:label="allSelectedLabel"
						color="#5e17eb"
						@change="CheckAll()"
						hide-details
					/>
					<v-divider
						style="width: 100%; max-width: 400px; margin: 8px"
					/>
					<div
						v-for="task in tasks"
						:key="task.id"
						class="checklist-box-task"
					>
						<v-checkbox
							v-model="task.cleaned"
							class="checkbox checkbox-label-inline"
							:label="task.name"
							color="#5e17eb"
							@change="SaveTask(task)"
							hide-details
						/>
					</div>

					<v-divider style="width: 100%; margin-bottom: 32px" />
					<v-row align="center">
						<v-col class="d-flex" cols="6" sm="6">
							<v-btn
								v-if="showPrevious"
								class="checklist-btn"
								style="
									border: 2px solid #5e17eb;
									color: #5e17eb;
								"
								@click="Previous()"
								>Previous</v-btn
							>
						</v-col>
						<v-col class="d-flex" cols="6" sm="6">
							<v-btn
								v-if="showContinue"
								class="checklist-btn"
								style="
									margin-left: auto;
									background-color: #5e17eb;
									color: white;
								"
								@click="Continue()"
								>Continue</v-btn
							>
							<v-btn
								v-else
								class="checklist-btn"
								style="
									margin-left: auto;
									background-color: #5e17eb;
									color: white;
								"
								@click="finishDialog = true"
								>Finish</v-btn
							>
						</v-col>
					</v-row>
				</div>

				<v-dialog
					color="white"
					class="finishDialog"
					v-model="finishDialog"
					transition="dialog-bottom-transition"
					max-width="600"
				>
					<v-card>
						<v-toolbar color="#5e17eb" dark
							>Are you sure you are finished?</v-toolbar
						>
						<v-card-text>
							<div class="text-h6" style="margin-top: 16px">
								By continuing, you will submit a cleaning report
								to the owner.
							</div>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn
								text
								color="#5e17eb"
								@click="finishDialog = false"
								>Close</v-btn
							>
							<v-btn
								text
								color="#5e17eb"
								@click="
									finishDialog = false;
									$router.push('/vendor_properties');
								"
								>Save</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-app>
		</div>
	</div>
</template>

<script>
import Header from "../../layout/Vendor/Header.vue";
import { db } from "../../firebase";
import { documentId, writeBatch } from "firebase/firestore";

export default {
	name: "Checklist",
	metaInfo: {
		title: "Cleaning Checklist",
		titleTemplate: "%s | Dormi",
		htmlAttrs: {
			lang: "en-US",
		},
		meta: [
			{ charset: "utf-8" },
			{
				name: "description",
				content:
					"Use the Dormi checklist to see your step by step cleaning process and directly communicate with the owners you work with.",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
		],
	},
	components: {
		Header,
	},
	data() {
		return {
			currentProperty: "",
			currentPropertyId: "",
			checklistId: "",
			currentCategory: "",
			masterChecklist: [],
			categories: [],
			tasks: [],
			allSelected: false,
			allSelectedLabel: "Check All",
			showPrevious: false,
			showContinue: true,
			finishDialog: false,
		};
	},
	computed: {},
	created() {
		this.findProperty();
	},
	methods: {
		findProperty: function () {
			this.currentPropertyId = this.$route.params.property_id;
			console.log("Id", this.currentPropertyId);
			db.collection("checklists")
				.where("property_id", "==", this.currentPropertyId)
				.get()
				.then((query) => {
					if (query.empty) {
						console.log("Checklist doesn't exist yet");
						return;
					}
					const checklistRef = query.docs[0];
					this.checklistId = checklistRef.id;
				})
				.then(() => {
					db.collection("checklists")
						.doc(this.checklistId)
						.collection("checklist_categories")
						.orderBy("sort")
						.get()
						.then((catQuery) => {
							catQuery.forEach((doc) => {
								let checklistCat = doc.data();
								checklistCat.id = doc.id;
								checklistCat.tasks = [];
								this.masterChecklist.push(checklistCat);
							});
						});
				})
				.then(() => {
					const collectionRef = db.collection("checklists");
					const checklistRef = collectionRef.doc(this.checklistId);
					db.collectionGroup("checklist_tasks")
						.orderBy(documentId())
						.startAt(checklistRef.path)
						.endAt(checklistRef.path + "\uf8ff") // unicode last char (end guard)
						.get()
						.then((tasksQuery) => {
							tasksQuery.forEach((doc) => {
								let categoryId = doc.ref.parent.parent.id;
								let masterIndex = this.getIndexById(
									categoryId,
									this.masterChecklist
								);

								if (masterIndex !== -1) {
									let taskItem = doc.data();
									taskItem.id = doc.id;
									this.masterChecklist[
										masterIndex
									].tasks.push(taskItem);
								}
							});
						})
						.then(() => {
							for (
								var i = 0;
								i < this.masterChecklist.length;
								i++
							) {
								this.masterChecklist[i].tasks.sort(
									(a, b) => a.sort - b.sort
								);
							}
						});
				})
				.then(() => {
					this.CheckChecks();
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});
		},
		CategoryChanged: function () {
			let masterIndex = this.getIndexById(
				this.currentCategory,
				this.masterChecklist
			);
			this.tasks = this.masterChecklist[masterIndex].tasks;
			this.showPrevious = masterIndex > 0;
			this.showContinue = masterIndex < this.masterChecklist.length - 1;
			this.CheckChecks();
		},
		SaveTasks: function () {
			console.log("saving tasks", this.currentCategory, this.tasks);
			let tasksRef = db
				.collection("checklists")
				.doc(this.checklistId)
				.collection("checklist_categories")
				.doc(this.currentCategory)
				.collection("checklist_tasks");
			const batch = writeBatch(db);
			for (let i = 0; i < this.tasks.length; i++) {
				let docRef = tasksRef.doc(this.tasks[i].id);
				batch.update(docRef, { cleaned: this.tasks[i].cleaned });
			}
			return batch
				.commit()
				.then(() => {
					console.log("Document successfully updated!");
				})
				.catch((error) => {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error);
				});
		},
		SaveTask: function (task) {
			console.log("saving task", this.currentCategory, task);
			let taskData = Object.assign({}, task);
			delete taskData.id;
			return db
				.collection("checklists")
				.doc(this.checklistId)
				.collection("checklist_categories")
				.doc(this.currentCategory)
				.collection("checklist_tasks")
				.doc(task.id)
				.update(taskData)
				.then(() => {
					this.CheckChecks();
				})
				.then(() => {
					console.log("Document successfully updated!");
				})
				.catch((error) => {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error);
				});
		},
		CloseTaskDetails() {
			this.dialogChecklistView = false;
			this.ResetTaskDetails();
		},
		Previous: function () {
			let masterIndex = this.getIndexById(
				this.currentCategory,
				this.masterChecklist
			);
			if (masterIndex == 0) {
				return;
			}
			let prevIndex = masterIndex - 1;
			if (prevIndex == 0) {
				this.showPrevious = false;
			}
			this.showContinue = true;
			this.currentCategory = this.masterChecklist[prevIndex].id;
			this.tasks = this.masterChecklist[prevIndex].tasks;
			this.allSelected = false;
			this.CheckChecks();
		},
		Continue: function () {
			let masterIndex = this.getIndexById(
				this.currentCategory,
				this.masterChecklist
			);
			if (masterIndex >= this.masterChecklist.length - 1) {
				return;
			}
			let nextIndex = masterIndex + 1;
			if (nextIndex >= this.masterChecklist.length - 1) {
				this.showContinue = false;
			} else {
				this.showPrevious = nextIndex != 0;
			}
			this.currentCategory = this.masterChecklist[nextIndex].id;
			this.tasks = this.masterChecklist[nextIndex].tasks;
			this.CheckChecks();
		},
		CheckAll: function () {
			if (this.allSelected == false) {
				for (let i = 0; i < this.tasks.length; i++) {
					this.tasks[i].cleaned = false;
				}
				this.allSelected = false;
				this.allSelectedLabel = "Check All";
			} else {
				for (let i = 0; i < this.tasks.length; i++) {
					this.tasks[i].cleaned = true;
				}
				this.allSelected = true;
				this.allSelectedLabel = "Uncheck All";
			}
			this.SaveTasks();
		},
		CheckChecks: function () {
			console.log("Checks", this.tasks);
			var all = true;
			for (let i = 0; i < this.tasks.length; i++) {
				if (this.tasks[i].cleaned == false) {
					all = false;
					break;
				}
			}
			if (all) {
				this.allSelected = true;
				this.allSelectedLabel = "Uncheck All";
			} else {
				this.allSelected = false;
				this.allSelectedLabel = "Check All";
			}
		},
		getIndexById: function (itemId, checklistArray) {
			let index = checklistArray.findIndex((element) => {
				if (element.id == itemId) {
					return true;
				}
			});
			return index;
		},
	},
};
</script>

<style scoped>
.app-div {
	min-height: 100vh;
}
.purple-checklist-title {
	color: #1d085a;
	font-size: 24px;
	font-family: "cabin_title";
	margin-bottom: 12px;
	padding-top: 2px;
	margin-top: 0px !important;
	text-decoration: none;
	display: inline-block;
}
.checkbox {
	margin-right: 6px;
	align-self: flex-start;
	margin-top: 0px;
}
.property-wrapper {
	width: 100%;
	height: 180px;
	background: rgb(196, 196, 196, 0.15);
	position: relative;
	border-bottom: 0.5px solid gray;
	overflow-x: scroll;
	overflow-y: hidden;
}
.finishDialog {
	background-color: white !important;
}
.property-cards-wrap {
	display: grid;
	grid-template-columns: 23% 23% 23% 23%;
	row-gap: 24px;
	justify-content: space-between;
	padding-left: 0px;
}
* {
	font-family: "cabin_regular";
	font-size: 16px;
	font-weight: normal;
}
.checklist-box-task {
	padding: 8px;
}
.checklist-btn {
	height: 48px !important;
	display: inline;
}
.v-application--wrap {
	display: inline !important;
}
</style>

<!-- prevent checkboxes from being clickable across 100% width -->
<style>
.checkbox-label-inline .v-label {
	flex: 0 1 auto !important;
}
.checkbox-label-inline .v-input__slot,
.checkbox-label-inline .v-input__control {
	width: auto !important;
	flex: 0 1 auto !important;
}
</style>
